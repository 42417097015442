import {action} from '@iva/store-utils';
import {BgpDataActions, BgpDataActionTypes,} from './types';

import {RestServiceUtils} from '@iva/rest-service-utils';
import {Dispatch} from 'redux';
import mapboxgl from "mapbox-gl";
import {AsnInfoData} from "../asnInfo/types";
import {BGPDataCollection} from "@pages/BGPDashboard/models/BGPDataCollection";
import {FilterStatus} from "@pages/BGPDashboard/models/FilterStatus";

export function setPrefixDateFrom(dateFrom: number): BgpDataActions{
  return action(BgpDataActionTypes.SET_PREFIX_DATE_FROM, dateFrom);
}

export function setPrefixDateTo(dateTo: number): BgpDataActions{
  return action(BgpDataActionTypes.SET_PREFIX_DATE_TO, dateTo);
}

export function setPrefixCollectors(collectors: string[]): BgpDataActions{
  return action(BgpDataActionTypes.SET_PREFIX_COLLECTORS, collectors);
}

export function  setFilterData(data: BGPDataCollection): BgpDataActions{
  return action(BgpDataActionTypes.SET_FILTER_DATA, data);
}

export function  setHighlights(data: number[]): BgpDataActions{
  return action(BgpDataActionTypes.SET_HIGHLIGHTS, data);
}

export function  delHighlights(data: number[]): BgpDataActions{
  return action(BgpDataActionTypes.DEL_HIGHLIGHTS, data);
}

export function setFilter(data: FilterStatus): BgpDataActions{
  return action(BgpDataActionTypes.SET_FILTER, data);
}
export function delFilter(data: FilterStatus): BgpDataActions{
  return action(BgpDataActionTypes.DEL_FILTER, data);
}


// Example for fetching a resource via RestServiceUtils
export function fetchAsnDataCentersRaw(asn : number, date : string) {
    return (dispatch: Dispatch) => {
        // Dispatch action that fetching data has begun
        dispatch(action(BgpDataActionTypes.FETCH_RAW_REQUEST, undefined));

        // Call endpoint, dispatch success- or error-message, depending on server response
        // Submit the GET-request, expecting plain text as response type
        RestServiceUtils.jsonGET('/api/asnRaw/'+asn+"/"+date,
            result => dispatch(action(BgpDataActionTypes.FETCH_RAW_SUCCESS, result)),
            error => dispatch(action(BgpDataActionTypes.FETCH_ERROR, error.message))
        );
    }
}
export function fetchAsnDataCenters(asn : number, date : string) {
  return (dispatch: Dispatch) => {
    // Dispatch action that fetching data has begun
    dispatch(action(BgpDataActionTypes.FETCH_CENTERS_REQUEST, undefined));

    // Call endpoint, dispatch success- or error-message, depending on server response
    // Submit the GET-request, expecting plain text as response type
    RestServiceUtils.jsonGET('/api/asnCenter/'+asn+"/"+date,
      result => dispatch(action(BgpDataActionTypes.FETCH_CENTERS_SUCCESS, result)),
      error => dispatch(action(BgpDataActionTypes.FETCH_ERROR, error.message))
    );
  }
}

export function fetchAsnDataCentersConnections(asn : number, date : string) {
  return (dispatch: Dispatch) => {
    // Dispatch action that fetching data has begun
    dispatch(action(BgpDataActionTypes.FETCH_CENTERCONNECTIONS_REQUEST, undefined));

    // Call endpoint, dispatch success- or error-message, depending on server response
    // Submit the GET-request, expecting plain text as response type
    RestServiceUtils.jsonGET('/api/asnCenterConnections/'+asn+"/"+date,
      result => dispatch(action(BgpDataActionTypes.FETCH_CENTERCONNECTIONS_SUCCESS, result)),
      error => dispatch(action(BgpDataActionTypes.FETCH_ERROR, error.message))
    );
  }
}

export function fetchPrefixData(prefix : string, sessionId : string, dateFrom : number, dateTo : number, collectors?: string[]) {
  return (dispatch: Dispatch) => {
    // Dispatch action that fetching data has begun
    dispatch(action(BgpDataActionTypes.FETCH_PREFIX_DATA_REQUEST, undefined));
    dispatch(action(BgpDataActionTypes.FETCH_RAW_DATA_REQUEST, undefined));

    // Call endpoint, dispatch success- or error-message, depending on server response
    // Submit the GET-request, expecting plain text as response type
    RestServiceUtils.jsonPOST('/api/prefix', {prefix: prefix, sessionId: sessionId, dateFrom: dateFrom, dateTo: dateTo, collectors: collectors},
      result => {},
      error => dispatch(action(BgpDataActionTypes.FETCH_ERROR, error.message))
    );
  }
}

export function setPopUpData(popUpData: mapboxgl.MapboxGeoJSONFeature[]): BgpDataActions {
  return action(BgpDataActionTypes.SET_POPUP_DATA, popUpData);
}

export function setAsnInfoMap(asnInfoMap: Map<number, AsnInfoData>): BgpDataActions {
  return action(BgpDataActionTypes.SET_ASN_INFO_MAP, asnInfoMap);
}





