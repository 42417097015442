import { Action } from '@iva/store-utils';
import {Feature, FeatureCollection} from "geojson";
import mapboxgl from "mapbox-gl";
import {BGPDataCollection} from "@pages/BGPDashboard/models/BGPDataCollection";
import {CollectorCollection} from "@pages/BGPDashboard/models/CollectorCollection";
import {AsnInfoData} from "../asnInfo/types";
import {BGPData} from "@pages/BGPDashboard/models/BGPData";
import {CollectorData} from "@pages/BGPDashboard/models/CollectorData";
import {FilterStatus} from "@pages/BGPDashboard/models/FilterStatus";
import {ColorState} from "@pages/BGPDashboard/models/ColorState";
import {BGPProgress} from "@pages/BGPDashboard/models/BGPProgress";

// State definition
export interface BgpDataState {
  // Used for fetching a resource via RestServiceUtils
  readonly prefixDateFrom: number;
  readonly prefixDateTo: number;
  readonly prefixCollectors: string[];

  readonly prefixData?: FeatureCollection;
  readonly rawData?: BGPDataCollection;
  readonly filterData?: BGPDataCollection;
  readonly centerConnectionsData?: string;
  readonly fetching_raw: boolean;
  readonly fetching_centers: boolean;
  readonly fetching_connections: boolean;
  readonly fetching_prefixPaths: boolean;
  readonly fetching_rawData: boolean;
  readonly data_raw?: string;
  readonly data_clusters?: string;
  readonly fetchError?: string;
  readonly popUpData?: mapboxgl.MapboxGeoJSONFeature[];
  readonly asnInfoMap?:  Map<number, AsnInfoData>;
  readonly asPathData?:string;
  readonly collectorData?: CollectorCollection;
  readonly filter?:FilterStatus[];
  readonly highlightData?: number[];
  readonly colorState?:ColorState;
  readonly progress: Map<string, Map<number, BGPProgress>>;

}


// Action types definition
export enum BgpDataActionTypes {
  SET_PREFIX_DATE_FROM = '@@Bgpdata/SET_PREFIX_DATE_FROM',
  SET_PREFIX_DATE_TO = '@@Bgpdata/SET_PREFIX_DATE_TO',
  SET_PREFIX_COLLECTORS = '@@Bgpdata/SET_PREFIX_COLLECTORS',
  FETCH_PREFIX_DATA_REQUEST = '@@Bgpdata/FETCH_PREFIX_DATA_REQUEST',
  FETCH_PREFIX_DATA_SUCCESS = '@@Bgpdata/FETCH_PREFIX_DATA_SUCCESS',
  FETCH_PREFIX_DATA_BATCH_SUCCESS = '@@Bgpdata/FETCH_PREFIX_DATA_BATCH_SUCCESS',
  FETCH_PREFIX_DATA_FINISH = '@@Bgpdata/FETCH_PREFIX_DATA_FINISH',
  FETCH_RAW_DATA_REQUEST='@@rawData/FETCH_RAW_DATA_REQUEST',
  FETCH_RAW_DATA_SUCCESS ='@@rawData/FETCH_RAW_DATA_SUCCESS',
  FETCH_RAW_DATA_BATCH_SUCCESS = '@@rawData/FETCH_RAW_DATA_BATCH_SUCCESS',
  FETCH_RAW_DATA_FINISH ='@@rawData/FETCH_RAW_DATA_FINISH',
  FETCH_PREFIX_DATA_PROGRESS = '@@Bgpdata/FETCH_PREFIX_DATA_PROGRESS',
  FETCH_COLLECTOR_DATA_SUCCESS='@@rawData/FETCH_COLLECTOR_DATA_SUCCESS',
  FETCH_CENTERCONNECTIONS_REQUEST ='@@Bgpdata/FETCH_CENTERCONNECTIONS_REQUEST',
  FETCH_CENTERCONNECTIONS_SUCCESS ='@@Bgpdata/FETCH_CENTERCONNECTIONS_SUCCESS',
  FETCH_RAW_REQUEST = '@@Bgpdata/FETCH_RAW_REQUEST',
  FETCH_RAW_SUCCESS = '@@Bgpdata/FETCH_RAW_SUCCESS',
  FETCH_CENTERS_REQUEST = '@@Bgpdata/FETCH_CENTERS_REQUEST',
  FETCH_CENTERS_SUCCESS = '@@Bgpdata/FETCH_CENTERS_SUCCESS',
  FETCH_ERROR = '@@Bgpdata/FETCH_ERROR',
  SET_POPUP_DATA = '@@Popup/SET_POPUP_DATA',
  SET_ASN_INFO_MAP = '@@AsnInfo/SET_ASN_INFO_MAP',
  SET_AS_PATH_DATA = '@@Bgpdata/SET_AS_PATH_DATA',
  SET_FILTER_DATA='@@rawData/SET_FILTER_DATA',
  SET_HIGHLIGHTS='@@rawData/SET_ID_DATA',
  DEL_HIGHLIGHTS='@@rawData/DEL_ID_DATA',
  SET_FILTER='@@rawData/SET_FILTER',
  DEL_FILTER='@@rawData/DEL_FILTER',




}

// Actions for manual fetching resources
export type FetchCenterConnectionsRequestAction = Action<BgpDataActionTypes.FETCH_CENTERCONNECTIONS_REQUEST>;
export type FetchCenterConnectionsSuccessAction = Action<BgpDataActionTypes.FETCH_CENTERCONNECTIONS_SUCCESS, string>;
export type FetchPrefixDataRequestAction = Action<BgpDataActionTypes.FETCH_PREFIX_DATA_REQUEST>;
export type FetchPrefixDataSuccessAction = Action<BgpDataActionTypes.FETCH_PREFIX_DATA_SUCCESS, Feature>;
export type FetchPrefixDataBatchSuccessAction = Action<BgpDataActionTypes.FETCH_PREFIX_DATA_BATCH_SUCCESS, Array<Feature>>;
export type FetchPrefixDataFinishAction = Action<BgpDataActionTypes.FETCH_PREFIX_DATA_FINISH, boolean>;
export type FetchAsnDataCenterRawRequestAction = Action<BgpDataActionTypes.FETCH_RAW_REQUEST>;
export type FetchAsnDataCenterRawSuccessAction = Action<BgpDataActionTypes.FETCH_RAW_SUCCESS, string>;
export type FetchAsnDataCenterRequestAction = Action<BgpDataActionTypes.FETCH_CENTERS_REQUEST>;
export type FetchAsnDataCenterSuccessAction = Action<BgpDataActionTypes.FETCH_CENTERS_SUCCESS, string>;
export type FetchTimeErrorAction = Action<BgpDataActionTypes.FETCH_ERROR, string>;

export type FetchRawDataRequestAction = Action<BgpDataActionTypes.FETCH_RAW_DATA_REQUEST>;
export type FetchRawDataSuccessAction = Action<BgpDataActionTypes.FETCH_RAW_DATA_SUCCESS, BGPData>;
export type FetchRawDataBatchSuccessAction = Action<BgpDataActionTypes.FETCH_RAW_DATA_BATCH_SUCCESS, Array<BGPData>>;
export type FetchRawDataFinishAction = Action<BgpDataActionTypes.FETCH_RAW_DATA_FINISH, boolean>;
export type FetchPrefixDataProgressAction = Action<BgpDataActionTypes.FETCH_PREFIX_DATA_PROGRESS, Array<BGPProgress>>;


export type SetPrefixDateFromAction = Action<BgpDataActionTypes.SET_PREFIX_DATE_FROM, number>;
export type SetPrefixDateToAction = Action<BgpDataActionTypes.SET_PREFIX_DATE_TO, number>;
export type SetPrefixCollectorsAction = Action<BgpDataActionTypes.SET_PREFIX_COLLECTORS, string[]>;

export type SetPopUpData = Action<BgpDataActionTypes.SET_POPUP_DATA, mapboxgl.MapboxGeoJSONFeature[]>;
export type SetAsnInfoMap = Action<BgpDataActionTypes.SET_ASN_INFO_MAP,Map<number, AsnInfoData>>;

export type SetFilterDataAction = Action<BgpDataActionTypes.SET_FILTER_DATA, BGPDataCollection>;
export type SetHighlights = Action<BgpDataActionTypes.SET_HIGHLIGHTS, number[]>;
export type DelHighlightsAction = Action<BgpDataActionTypes.DEL_HIGHLIGHTS, number[]>;
export type SetFilterAction = Action<BgpDataActionTypes.SET_FILTER, FilterStatus>;
export type DelFilterAction = Action<BgpDataActionTypes.DEL_FILTER, FilterStatus>;



export type FetchCollectorDataSuccessAction = Action<BgpDataActionTypes.FETCH_COLLECTOR_DATA_SUCCESS, Array<CollectorData>>;


export type BgpDataActions =  FetchPrefixDataRequestAction |
  FetchPrefixDataSuccessAction |
  FetchPrefixDataBatchSuccessAction |
  FetchCenterConnectionsRequestAction |
  FetchCenterConnectionsSuccessAction |
  FetchAsnDataCenterRawRequestAction |
  FetchAsnDataCenterRawSuccessAction |
  FetchAsnDataCenterRequestAction |
  FetchPrefixDataFinishAction |
  SetPrefixDateFromAction |
  SetPrefixDateToAction |
  SetPrefixCollectorsAction |
  FetchAsnDataCenterSuccessAction |
  FetchRawDataRequestAction |
  FetchRawDataSuccessAction |
  FetchRawDataFinishAction |
  FetchRawDataBatchSuccessAction |
  FetchPrefixDataProgressAction |
  FetchTimeErrorAction |
  SetPopUpData |
  SetAsnInfoMap |
  SetFilterDataAction |
  SetHighlights |
  DelHighlightsAction |
  SetFilterAction |
  DelFilterAction |
  FetchCollectorDataSuccessAction;
