import {FeatureCollection} from "geojson";
import {Action} from "@iva/store-utils";

export interface HarbourCenterState {
  readonly harbourCenterData: FeatureCollection | undefined,
  readonly harbourCenterDataAS: FeatureCollection | undefined,
  readonly cableData: FeatureCollection | undefined,
  readonly fetchError: string,
  readonly fetchingHarbour: boolean,
  readonly fetchingCable: boolean,
}

export enum HarbourCenterActionTypes {
  FETCH_HARBOURCENTER_REQUEST = '@@HarbourCenter/FETCH_HARBOURCENTER_REQUEST',
  FETCH_HARBOURCENTER_SUCCESS = '@@HarbourCenter/FETCH_HARBOURCENTER_SUCCESS',
  FETCH_HARBOURCENTER_AS_REQUEST = '@@HarbourCenter/FETCH_HARBOURCENTER_AS_REQUEST',
  FETCH_HARBOURCENTER_AS_SUCCESS = '@@HarbourCenter/FETCH_HARBOURCENTER_AS_SUCCESS',
  FETCH_CABLE_DATA_REQUEST = '@@HarbourCenter/FETCH_CABLE_DATA_REQUEST',
  FETCH_CABLE_DATA_SUCCESS = '@@HarbourCenter/FETCH_CABLE_DATA_SUCCESS',
  FETCH_HARBOURCENTER_ERROR = '@@HarbourCenter/FETCH_HARBOURCENTER_ERROR',
}

export type FetchHarbourCenterRequestAction = Action<HarbourCenterActionTypes.FETCH_HARBOURCENTER_REQUEST>;
export type FetchHarbourCenterSuccessAction = Action<HarbourCenterActionTypes.FETCH_HARBOURCENTER_SUCCESS, FeatureCollection>;
export type FetchHarbourCenterASRequestAction = Action<HarbourCenterActionTypes.FETCH_HARBOURCENTER_AS_REQUEST>;
export type FetchHarbourCenterASSuccessAction = Action<HarbourCenterActionTypes.FETCH_HARBOURCENTER_AS_SUCCESS, FeatureCollection>;
export type FetchCableDataRequestAction = Action<HarbourCenterActionTypes.FETCH_CABLE_DATA_REQUEST>;
export type FetchCableDataSuccessAction = Action<HarbourCenterActionTypes.FETCH_CABLE_DATA_SUCCESS, FeatureCollection>;
export type FetchHarbourCenterErrorAction = Action<HarbourCenterActionTypes.FETCH_HARBOURCENTER_ERROR, string>;

export type HarbourCenterAction =
  FetchHarbourCenterRequestAction |
  FetchHarbourCenterSuccessAction |
  FetchHarbourCenterASRequestAction |
  FetchHarbourCenterASSuccessAction |
  FetchCableDataRequestAction |
  FetchCableDataSuccessAction |
  FetchHarbourCenterErrorAction;
