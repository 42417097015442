import { ApplicationLayoutState, ApplicationLayoutActionTypes, ApplicationLayoutActions } from './types';

// Initial state
const initialState: ApplicationLayoutState = {
  type: 'dark',
  showTraceSummary: false,
  showTraceConfidenceColors: false,
  traceInputType: "Linux",
};

// The reducer function
export function applicationLayoutReducer(state = initialState, action: ApplicationLayoutActions): ApplicationLayoutState {
  switch (action.type) {
    case ApplicationLayoutActionTypes.SET_THEME: {
      return {
        ...state,
        type: action.payload
      };
    }
    case ApplicationLayoutActionTypes.SET_SHOW_TRACE_SUMMARY: {
      return {
        ...state,
        showTraceSummary: action.payload
      };
    }
    case ApplicationLayoutActionTypes.SET_SHOW_TRACE_CONFIDENCE_COLORS: {
      return {
        ...state,
        showTraceConfidenceColors: action.payload
      }
    }
    case ApplicationLayoutActionTypes.SET_TRACE_INPUT_TYPE: {
      return {
        ...state,
        traceInputType: action.payload
      }
    }
    default: {
      return state;
    }
  }
};
