import React, {FC, useEffect, useRef, useState} from 'react';
import ReactDOM from "react-dom";
import 'date-fns';
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl, {GeoJSONSource} from 'mapbox-gl'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField, Theme,
  Typography, useTheme,
  Radio, RadioGroup,
  Tooltip as MaterialTooltip
} from "@material-ui/core";
import {
  clearCenterConnections,
  clearTraceData, clearTraceSummary,
  fetchAllDataCenters,
  fetchAsnDataCentersConnections, fetchAsnDataCentersMinBlockSize,
  fetchAsnDataCentersRaw,
  fetchAsnDataCentersV2, fetchBGPTracePathData,
  fetchGeoIpData,
  fetchTracePathData,
} from "../../../store/geoData/actions";
import {format} from "date-fns";
import {minMax} from "../../../util/helperFunctions";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@pages/Map/TooltipComponent";
import {FeatureCollection, GeoJsonProperties, Geometry} from "geojson";

import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import BugReportIcon from '@material-ui/icons/BugReport';
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';

import {fetchPeeringDBData, fetchPeeringDBDataAS} from "../../../store/peeringDBData/actions";
import {
  fetchCableData,
  fetchHarbourCenterData,
  fetchHarbourCenterDataAS
} from "../../../store/harbourCenterData/actions";
import {TraceRouteSummary} from "@pages/Map/TraceRouteSummary";
import {RestServiceUtils} from "@iva/rest-service-utils";
import {fetchForbiddenZones} from "../../../store/forbiddenZones/actions";
import {
  setShowTraceConfidenceColors,
  setShowTraceSummary,
  setTraceInputType
} from "../../../store/applicationLayout/actions";
import {fetchDataCenterMapData, fetchDataCenterMapDataAS} from "../../../store/dataCenterMapStore/actions";
import TuneIcon from "@material-ui/icons/Tune";
import LinearScaleIcon from "@material-ui/icons/LinearScale";
import {fetchDnsCenterData, fetchDnsCenterDataAS} from "../../../store/dnsCenterData/actions";

const useStyles = makeStyles((theme) => ({
  popup: {
    padding: 0,
    backgroundColor: "none",
  },
  buttonLabel: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 5,
    fontSize: theme.typography.caption.fontSize,
    textTransform: "none"
  },
}));

const MapComponent: FC = () => {

  const debug = false;
  const cableDebug = false;
  const [cableDebugColor, setCableDebugColor] = useState<number>(0xffffff);

  const [update, forceUpdate] = useState(0);
  const [init, setInit] = useState(true);

  const type = useSelector((state: ApplicationState) => state.applicationLayout.type);
  let mapStyle = type === "dark" ? 'mapbox://styles/crispy/cils06qnw00c2f7m0hip6ixms' : 'mapbox://styles/crispy/cimeo4mbb00o5adlz3c18g26e';
  const dispatch = useDispatch();
  const [map, setMap] = useState(null);
  const [popupContent, setPopupContent] = useState(null);
  const mapContainer = useRef(null);
  const [asn, setAsn] = useState(-1);
  const [asnInput, setAsnInput] = useState('');
  const [trace, setTrace] = useState('');
  const [winTrace, setWinTrace] = useState('');
  const [asPath, setAsPath] = useState('');
  const [startIp, setStartIp] = useState('');
  const [endIp, setEndIp] = useState('');
  const [traceStringWidth, setTraceStringWidth] = useState(450);
  const [multiRows, setMultiRows] = useState(10);
  const [hoveredAsn, setHoveredAsn] = useState(-1);
  const [date, setDate] = useState(new Date("2022-10-18"));
  const [showClusterLayer, setShowClusterLayer] = useState(true);
  const [showRawDataLayer, setShowRawDataLayer] = useState(true);
  const [showCenterConnectionsLayer, setShowCenterConnectionsLayer] = useState(false);
  const [showPDBLayer, setShowPDBLayer] = useState(true);
  const [showTraceLayer, setShowTraceLayer] = useState(true);
  const [showHarbourCenterLayer, setShowHarbourCenterLayer] = useState(true);
  const [showCableLayer, setShowCableLayer] = useState(true);
  const [showDebugCableLayer, setShowDebugCableLayer] = useState(false);
  const [showForbiddenZonesLayer, setShowForbiddenZonesLayer] = useState(true);
  const [showDataCenterMapLayer, setShowDataCenterMapLayer] = useState(true);
  const [showDnsCenterLayer, setShowDnsCenterLayer] = useState(true);
  const [selectedHop, setSelectedHop] = useState(-1);

  const [traceEvalMode, setTraceEvalMode] = useState(false);

  const [debugCableData, setDebugCableData] = useState<FeatureCollection<Geometry, GeoJsonProperties> | undefined>(undefined);

  const centerConnectionsData = useSelector((state: ApplicationState) => state.geoData.centerConnectionsData);
  const data_clusters = useSelector((state: ApplicationState) => state.geoData.data_clusters);

  const data_raw = useSelector((state: ApplicationState) => state.geoData.data_raw);
  const fetching_raw = useSelector((state: ApplicationState) => state.geoData.fetching_raw);
  const fetching_centers = useSelector((state: ApplicationState) => state.geoData.fetching_centers);
  const fetching_connections = useSelector((state: ApplicationState) => state.geoData.fetching_connections);
  const fetching_geoip = useSelector((state: ApplicationState) => state.geoData.fetching_geoip);
  const fetchingTrace = useSelector((state: ApplicationState) => state.geoData.fetchingTrace);
  const showTraceSummary = useSelector((state: ApplicationState) => state.applicationLayout.showTraceSummary);
  const showTraceConfidenceColors = useSelector((state: ApplicationState) => state.applicationLayout.showTraceConfidenceColors);
  const trInputType = useSelector((state: ApplicationState) => state.applicationLayout.traceInputType);

  const traceData = useSelector((state: ApplicationState) => state.geoData.traceData);
  const traceSummary = useSelector((state: ApplicationState) => state.geoData.traceSummary);

  const geoIpData = useSelector((state: ApplicationState) => state.geoData.geoIpData);
  const allDatacenters = useSelector((state: ApplicationState) => state.geoData.allDataCenters);
  const fetchingAllDatacenters = useSelector((state: ApplicationState) => state.geoData.fetching_allDataCenters);

  const fetchError = useSelector((state: ApplicationState) => state.geoData.fetchError);

  const peeringDBData = useSelector((state: ApplicationState) => state.peeringDBData.peeringDBData);
  const fetching_peeringDBData = useSelector((state: ApplicationState) => state.peeringDBData.fetching);
  const peeringDBDataAS = useSelector((state: ApplicationState) => state.peeringDBData.peeringDBASData);
  const harbourCenterData = useSelector((state: ApplicationState) => state.harbourCenterData.harbourCenterData);
  const harbourCenterDataAS = useSelector((state: ApplicationState) => state.harbourCenterData.harbourCenterDataAS);
  const fetchingHarbourCenterData = useSelector((state: ApplicationState) => state.harbourCenterData.fetchingHarbour);
  const cableData = useSelector((state: ApplicationState) => state.harbourCenterData.cableData);
  const fetchingCableData = useSelector((state: ApplicationState) => state.harbourCenterData.fetchingCable);
  const forbiddenZones = useSelector((state: ApplicationState) => state.forbiddenZonesData.forbiddenZonesData);
  const fetchingForbiddenZones = useSelector((state: ApplicationState) => state.forbiddenZonesData.fetching);
  const dataCenterMapData = useSelector((state: ApplicationState) => state.dataCenterMapData.dataCenterMapData);
  const dataCenterMapDataAS = useSelector((state: ApplicationState) => state.dataCenterMapData.dataCenterMapDataAS);
  const fetchingDataCenterMap = useSelector((state: ApplicationState) => state.dataCenterMapData.fetching);
  const dnsCenterData = useSelector((state: ApplicationState) => state.dnsCenterData.dnsCenterData);
  const dnsCenterDataAS = useSelector((state: ApplicationState) => state.dnsCenterData.dnsCenterDataAS);
  const fetchingDnsCenterData = useSelector((state: ApplicationState) => state.dnsCenterData.fetchingDns);

  const anyInitFetchRunning = fetching_geoip || fetchingAllDatacenters || fetching_peeringDBData || fetchingForbiddenZones || fetchingHarbourCenterData || fetchingDataCenterMap

  const rawLayerName = "raw";
  const clustersLayerName = "clusters";
  const centerConnectionsLayerName = "centerConnections";
  const peeringDBLayerName = "pdb";
  const traceLayerName = "trace";
  const harbourCenterLayerName = "harbourCenter";
  const cableLayerName = "cables";
  const forbiddenZonesLayerName = "forbiddenZones";
  const dataCenterMapLayerName = "dataCenterMap";
  const debugCableLayerName = "debugCable";
  const dnsCenterLayerName = "dnsCenter";

  const tooltipContainer = document.createElement('div');
  const popupContainer = document.createElement('div');

  const colorMap = [
    [1, "#fffcaa"],
    [100, "#ffeda0"],
    [1000, "#fed976"],
    [10000, "#feb24c"],
    [100000, "#fd8d3c"],
    [500000, "#fc4e2a"],
    [1000000, "#e31a1c"],
    [5000000, "#bd0026"],
    [10000000, "#800026"]];

  const startColor = "#1fa939";
  const endColor = "#e200e2";
  const dataCenterMapColor = "#55b0ff";

  const classes = useStyles();
  const theme = useTheme<Theme>();

  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiY3Jpc3B5IiwiYSI6ImNpczdncncxYzAwMXgydHA3aXR4NmUwcjYifQ.5CdsMFTwPvC3T_6iqHKQng';
    const initializeMap = ({setMap, mapContainer, setPopupContent}: any) => {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: mapStyle, //stylesheet location
        center: [0, 30], // starting position
        zoom: 2, // starting zoom
        minZoom: 0,
        maxZoom: 11,
        renderWorldCopies: true
      });

      map.on("load", () => {
        setMap(map);
        map.resize();
        dispatch(fetchGeoIpData(format(date, "yyyy-MM-dd")));
        dispatch(fetchAllDataCenters(format(date, "yyyy-MM-dd")));
        dispatch(fetchPeeringDBData());
        dispatch(fetchHarbourCenterData());
        dispatch(fetchCableData());
        dispatch(fetchForbiddenZones());
        dispatch(fetchDataCenterMapData());
        dispatch(fetchDnsCenterData(format(date, "yyyy-MM-dd")));
        const tooltip = new mapboxgl.Marker(tooltipContainer, {
          offset: [0, -50]
        }).setLngLat([0,0])
          .addTo(map);

        const popup = new mapboxgl.Popup({closeButton: false, maxWidth: "1000px", className: classes.popup}).setLngLat([0,0]).addTo(map);

        map.on("mousemove", traceLayerName+"p", (e) => {
          // @ts-ignore
          let description = e.features[0].properties.as;
          tooltip.setLngLat(e.lngLat);
          map.getCanvas().style.cursor = description ? "pointer" : "";
          ReactDOM.render(<Tooltip theme={theme}
                                   description={"AS: " + description}/>, tooltipContainer);
        });
        map.on('mouseleave', traceLayerName+"p", (e) => {
          ReactDOM.unmountComponentAtNode(tooltipContainer)
        });

        map.on("mousemove", traceLayerName+"hops", (e) => {
          // @ts-ignore
          let description = e.features[0].properties.as;
          tooltip.setLngLat(e.lngLat);
          map.getCanvas().style.cursor = description ? "pointer" : "";
          ReactDOM.render(<Tooltip theme={theme}
                                   description={"AS: " + description}/>, tooltipContainer);
        });
        map.on('mouseleave', traceLayerName+"hops", (e) => {
          ReactDOM.unmountComponentAtNode(tooltipContainer)
        });

        map.on('mousemove', clustersLayerName, (e) => {
          // const features = map.queryRenderedFeatures(e.point);
          // let coordinates = e.features[0].geometry.coordinates.slice();
          // @ts-ignore
          let description = e.features[0].properties.size;
          tooltip.setLngLat(e.lngLat);
          map.getCanvas().style.cursor = description? 'pointer' : '';
          ReactDOM.render(<Tooltip theme={theme} description={"IP Address Cluster Size: "+new Intl.NumberFormat('en-US', { maximumSignificantDigits: 21 }).format(description)}/>,tooltipContainer);
        });
        map.on('mouseleave', clustersLayerName, (e) => {
          ReactDOM.unmountComponentAtNode(tooltipContainer)
        });

        map.on('mousemove', rawLayerName, (e) => {
          // const features = map.queryRenderedFeatures(e.point);
          // let coordinates = e.features[0].geometry.coordinates.slice();
          // @ts-ignore
          let description = e.features[0].properties.size;
          tooltip.setLngLat(e.lngLat);
          map.getCanvas().style.cursor = description? 'pointer' : '';
          ReactDOM.render(<Tooltip theme={theme} description={"IP Block Size: "+new Intl.NumberFormat('en-US', { maximumSignificantDigits: 21 }).format(description)}/>,tooltipContainer);
        });
        map.on('mouseleave', rawLayerName, (e) => {
          ReactDOM.unmountComponentAtNode(tooltipContainer)
        });

        map.on('mousemove', harbourCenterLayerName, (e) => {
          // @ts-ignore
          let description = JSON.stringify(e.features[0].properties.name);
          tooltip.setLngLat(e.lngLat);
          map.getCanvas().style.cursor = description? 'pointer' : '';
          ReactDOM.render(<Tooltip theme={theme} description={description}/>,tooltipContainer);
        });
        map.on('mouseleave', harbourCenterLayerName, (e) => {
          ReactDOM.unmountComponentAtNode(tooltipContainer)
        });

        map.on('mousemove', dataCenterMapLayerName, (e) => {
          // @ts-ignore
          let description = JSON.stringify(e.features[0].properties.dataCenter);
          tooltip.setLngLat(e.lngLat);
          map.getCanvas().style.cursor = description? 'pointer' : '';
          ReactDOM.render(<Tooltip theme={theme} description={description}/>,tooltipContainer);
        });
        map.on('mouseleave', dataCenterMapLayerName, (e) => {
          ReactDOM.unmountComponentAtNode(tooltipContainer)
        });

        let highlights = false;
        let fixPopup = false;
        map.on("click", (e) => {
          if(e.originalEvent.cancelBubble) return;
          if(fixPopup){
            setPopupContent(null);
            // popup.remove();
            // ReactDOM.unmountComponentAtNode(popupContainer)
          }
          fixPopup = false;
        });

        // map.on('mousemove', prefixLayerName+"p", (e) => {
        //   // const features = map.queryRenderedFeatures(e.point);
        //   // let coordinates = e.features[0].geometry.coordinates.slice();
        //   // @ts-ignore
        //   let description = e.features[0].properties.id;
        //   tooltip.setLngLat(e.lngLat);
        //   map.getCanvas().style.cursor = description? 'pointer' : '';
        //   ReactDOM.render(<Tooltip theme={theme} description={description}/>,tooltipContainer);
        // });
        // map.on('mouseleave', rawLayerName, (e) => {
        //   ReactDOM.unmountComponentAtNode(tooltipContainer)
        // });


      });
    };

    if (!map) initializeMap({setMap, mapContainer, setPopupContent});
  }, [map]);

  const removeSourceAndLayer = (map: mapboxgl.Map | null, id : string) => {
    if (map) {
      if (map.getSource(id)) {
        if(id == traceLayerName){
          map.removeLayer(id+"p");
          map.removeLayer(id+"hops");
          map.removeLayer(id+"labels");
        }
        map.removeLayer(id);
        map.removeSource(id);
      }
    }
  };

  const addRawLayer = (map: mapboxgl.Map | null, data: any | undefined) => {
    if (map) {
      if (data) {
        map.addSource(rawLayerName, {
          type: 'geojson',
          data: data,
        });
        map.addLayer({
          "id": rawLayerName,
          "type": "circle",
          "source": rawLayerName,
          "paint": {
            "circle-radius": {
              base: 1,
              stops: [[1, 1], [5, 5], [20, 20]]
            },
            "circle-color": "#FFF",
            "circle-opacity" : 0.25
          }
        }, addUnderHarbour(map) || addUnderPDB(map) || addUnderCluster(map) || addUnderDataCenterMap(map) || "place_label_other");
        setShowRawDataLayer(true);
      }
    }

  };

  const addUnderRaw = (map: mapboxgl.Map | null) => {
    if(map){
      if(map.getSource(rawLayerName))
        return rawLayerName;
    }
    return undefined;
  };

  const addUnderCluster = (map: mapboxgl.Map | null) => {
    if(map){
      if(map.getSource(clustersLayerName))
        return clustersLayerName;
    }
    return undefined;
  };

  const addUnderPDB = (map: mapboxgl.Map | null) => {
    if(map){
      if(map.getSource(peeringDBLayerName))
        return peeringDBLayerName;
    }
    return undefined;
  };

  const addUnderHarbour = (map: mapboxgl.Map | null) => {
    if(map){
      if(map.getSource(harbourCenterLayerName))
        return harbourCenterLayerName;
    }
    return undefined;
  };

  const addUnderDnsCenter = (map: mapboxgl.Map | null) => {
    if(map){
      if(map.getSource(dnsCenterLayerName))
        return dnsCenterLayerName;
    }
    return undefined;
  }

  const addUnderDataCenterMap = (map: mapboxgl.Map | null) => {
    if(map){
      if(map.getSource(dataCenterMapLayerName))
        return dataCenterMapLayerName;
    }
    return undefined;
  };

  const addClustersLayer = (map: mapboxgl.Map | null, data: any | undefined, minMaxValues : number[]) => {
    if (map) {
      if (data) {
        map.addSource(clustersLayerName, {
          type: 'geojson',
          data: data,
        });
        map.addLayer({
          "id": clustersLayerName,
          "type": "circle",
          "source": clustersLayerName,
          "paint": {
            "circle-radius": {
              base: 0.8,
              stops: [[1, 1], [2, 2], [3,3],[6,12], [10, 35],[15, 80]]
            },
            "circle-color": "#ff8535",
            "circle-opacity" : 0.90
          }
        }, "place_label_other");
        setShowClusterLayer(true);
      }
    }
  };

  const addCenterConnectionsLayer = (map: mapboxgl.Map | null, data: any | undefined) => {
    if (map) {
      if (data) {
        map.addSource(centerConnectionsLayerName, {
          type: 'geojson',
          data: data,
        });
        map.addLayer({
          'id': centerConnectionsLayerName,
          'type': 'line',
          'source': centerConnectionsLayerName,
          'layout': {
            'line-join': 'round',
            'line-cap': 'round'
          },
          'paint': {
            'line-color': '#ffffff',
            "line-width": [
              "interpolate",
              ["linear"],
              ["zoom"],
              1, 1,
              9, 4,
              13, 10
            ],
          }
        }, "place_label_other");
        setShowCenterConnectionsLayer(true);
      }
    }
  };

  const addPeeringDBLayer = (map: mapboxgl.Map | null, pdbData: FeatureCollection) => {
    if (map) {
      map.addSource(peeringDBLayerName, {
        type: 'geojson',
        data: pdbData,
      });
      map.addLayer({
        "id": peeringDBLayerName,
        "type": "circle",
        "source": peeringDBLayerName,
        "paint": {
          "circle-radius": {
            base: 0.8,
            stops: [[1, 1], [2, 2], [3,3],[6,12], [10, 35],[15, 80]]
          },
          "circle-color": "#1c34ff",
          "circle-opacity" : 0.65
        }
      }, addUnderCluster(map) || "place_label_other");
      setShowPDBLayer(true)
    }

  };

  const addHarbourLayer = (map: mapboxgl.Map | null, harbourLayerData: FeatureCollection<Geometry, GeoJsonProperties> | undefined) => {
    if (map && harbourLayerData) {
      map.addSource(harbourCenterLayerName, {
        type: 'geojson',
        data: harbourLayerData,
      });
      map.addLayer({
        "id": harbourCenterLayerName,
        "type": "circle",
        "source": harbourCenterLayerName,
        "paint": {
          "circle-radius": {
            base: 0.8,
            stops: [[1, 1], [2, 2], [3,3],[6,12], [10, 35],[15, 80]]
          },
          "circle-color": "#3bfcd8",
          "circle-opacity" : 0.65
        }
      }, addUnderPDB(map) || addUnderDnsCenter(map) || addUnderCluster(map) || "place_label_other");
      // initially hide harbours
      if(!init) {
        setShowHarbourCenterLayer(true)
      } else {
        setInit(false)
        switchHarbourCenterLayer(map)
      }
    }

  };

  const addDnsLayer = (map: mapboxgl.Map | null, dnsLayerData: FeatureCollection<Geometry, GeoJsonProperties> | undefined) => {
    if (map && dnsLayerData) {
      map.addSource(dnsCenterLayerName, {
        type: 'geojson',
        data: dnsLayerData,
      });
      map.addLayer({
        "id": dnsCenterLayerName,
        "type": "circle",
        "source": dnsCenterLayerName,
        "paint": {
          "circle-radius": {
            base: 0.8,
            stops: [[1, 1], [2, 2], [3,3],[6,12], [10, 35],[15, 80]]
          },
          "circle-color": "#f7ff00",
          "circle-opacity" : 0.65
        }
      }, addUnderPDB(map) || addUnderCluster(map) || "place_label_other");
      setShowDnsCenterLayer(true);
    }

  };

  const addCableLayer = (map: mapboxgl.Map | null, cableLayerData: FeatureCollection<Geometry, GeoJsonProperties> | undefined) => {
    if (map && cableData) {
      map.addSource(cableLayerName, {
        type: 'geojson',
        data: cableLayerData,
      });
      map.addLayer({
        "id": cableLayerName,
        "type": "line",
        "source": cableLayerName,
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': '#ffffff',
          "line-width": [
            "interpolate",
            ["linear"],
            ["zoom"],
            1, 1,
            9, 4,
            13, 10
          ],
        }
      }, addUnderPDB(map) || addUnderCluster(map) || "place_label_other");
      switchCableLayer(map, false)
    }
  };

  const addDebugCableLayer = (map: mapboxgl.Map | null, data: FeatureCollection, debugColor?: boolean) => {
    if (map) {
      if (map.getSource(debugCableLayerName)) {
        let source = map.getSource(debugCableLayerName) as GeoJSONSource;
        source.setData(data);
        return;
      }
      map.addSource(debugCableLayerName, {
        type: 'geojson',
        lineMetrics: true,
        data: data,
        'promoteId': "id",
      });
      if(debugColor) {
        map.addLayer({
            "id": debugCableLayerName,
            "type": "line",
            "source": debugCableLayerName,
            'layout': {
              'line-join': 'round',
              'line-cap': 'round'
            },
            "paint": {
              "line-width": [
                "interpolate",
                ["linear"],
                ["zoom"],
                1, 3,
                9, 7,
                13, 15
              ],
              "line-color": ["get", "hex"],
              "line-opacity": [
                "case", [
                  "in",
                  ["get", "hex"],
                  ["literal", "#" + cableDebugColor.toString(16).padStart(6, '0')]
                ],
                1,
                0
              ]
            },
          }
        );
      } else {
        map.addLayer({
            "id": debugCableLayerName,
            "type": "line",
            "source": debugCableLayerName,
            'layout': {
              'line-join': 'round',
              'line-cap': 'round'
            },
            "paint": {
              "line-width": [
                "interpolate",
                ["linear"],
                ["zoom"],
                1, 3,
                9, 7,
                13, 15
              ],
              "line-color": ["get", "hex"],
              "line-opacity": 1
            },
          }
        );
      }
      setShowDebugCableLayer(true);
      map.setLayoutProperty(debugCableLayerName, 'visibility', 'visible');
    }
  };

  const addForbiddenZonesLayer = (map: mapboxgl.Map | null, data: FeatureCollection) => {
    if (map) {
      if(map.getSource(forbiddenZonesLayerName)){
        let source = map.getSource(forbiddenZonesLayerName) as GeoJSONSource;
        source.setData(data);
        return;
      }
      map.addSource(forbiddenZonesLayerName, {
        type: 'geojson',
        lineMetrics: true,
        data: data,
        'promoteId': "id",
      });
      map.addLayer({
          "id": forbiddenZonesLayerName,
          "type": "line",
          "source": forbiddenZonesLayerName,
          'layout': {
            'line-join': 'round',
            'line-cap': 'round'
          },
          "paint": {
            "line-width": [
              "interpolate",
              ["linear"],
              ["zoom"],
              1, 3,
              9, 7,
              13, 15
            ],
            "line-color": [
              'case',
              ['boolean', ['feature-state', 'hover'], false], theme.palette.primary.main,
              '#ff0000'
            ],
            "line-opacity": [
              'case',
              ['boolean', ['feature-state', 'hover'], false],1,
              ['boolean', ['feature-state', 'hoverNotYou'], false],0
              ,0.8
            ]
          },
        }
      );
      switchForbiddenZonesLayer(map, false)
    }
  };

  const addDataCenterMapLayer = (map: mapboxgl.Map | null, dataCenterMapLayerData: FeatureCollection<Geometry, GeoJsonProperties> | undefined) => {
    if (map && dataCenterMapLayerData) {
      map.addSource(dataCenterMapLayerName, {
        type: 'geojson',
        data: dataCenterMapLayerData,
      });
      map.addLayer({
        "id": dataCenterMapLayerName,
        "type": "circle",
        "source": dataCenterMapLayerName,
        "paint": {
          "circle-radius": {
            base: 0.8,
            stops: [[1, 2], [2, 3], [3,4],[6,12], [10, 35],[15, 80]]
          },
          "circle-color": dataCenterMapColor,
          "circle-opacity" : 0.65
        }
      }, addUnderPDB(map) || addUnderCluster(map) || addUnderHarbour(map) || "place_label_other");
      setShowDataCenterMapLayer(true)
    }

  };

  const addTraceLayer = (map: mapboxgl.Map | null, data: FeatureCollection) => {
    const colorProperty = showTraceConfidenceColors ? 'confHex' : 'hex';
    if (map) {
      if(map.getSource(traceLayerName)){
        let source = map.getSource(traceLayerName) as GeoJSONSource;
        source.setData(data);
        return;
      }
      map.addSource(traceLayerName, {
        type: 'geojson',
        lineMetrics: true,
        data: data,
        'promoteId': "id",
      });
      map.addLayer({
          "id": traceLayerName,
          "type": "line",
          "source": traceLayerName,
          'layout': {
            'line-join': 'round',
            'line-cap': 'round'
          },
          "paint": {
            "line-width": [
              "interpolate",
              ["linear"],
              ["zoom"],
              1, 3,
              9, 7,
              13, 15
            ],
            "line-color": [
              'case',
              ['boolean', ['feature-state', 'hover'], false], theme.palette.primary.main,
              ["case", ["in",
                ['get', colorProperty], ["literal", [null]],],
                '#ffffff',
                ["get", colorProperty],
              ],
            ],
            // 'line-gradient': [
            //   'interpolate',
            //   ['linear'],
            //   ['line-progress'],
            //   0,
            //   'white',
            //   1,
            //   'red'
            // ],
            "line-opacity": [
              'case',
              ['boolean', ['feature-state', 'hover'], false],1,
              ['boolean', ['feature-state', 'hoverNotYou'], false],0,
              ["case", ["in",
                colorProperty, ["literal", ["confHex"]],],1,1],
            ]
          },
          // 'filter': ['==', '$type', 'LineString']
        },
      );
      map.addLayer({
          "id": traceLayerName+"p",
          "type": "circle",
          "source": traceLayerName,
          "paint": {
            "circle-radius": [
              "interpolate", ["linear"], ["zoom"],
              1, ["case", ['in', ['get', 'prop'], ['literal', ["start", "end"]]],5,["case", ['in', ['get', 'prop'], ['literal', ["hop"]]], 2.8, 1.5]],
              20, ["case", ['in', ['get', 'prop'], ['literal', ["start", "end"]]],45,["case", ['in', ['get', 'prop'], ['literal', ["hop"]]], 28,18]],
            ],
            "circle-color":
              ["case", ["in",
                ['get', colorProperty], ["literal", [null]],],
                ['match',
                  ['get', 'prop'],
                  'start',
                  startColor,
                  'end',
                  endColor,
                  "#FFF",],
                ["get", colorProperty],
              ],
            "circle-opacity" : [
              'case',
              // ['boolean', ['feature-state', 'hoverNotYou'], false], 0, 1
              ['all', ['boolean', ['get', 'cableHop'], false], ['!', ['in', ['get', 'prop'], ['literal', ["hop"]]]]], 0, 1,
            ],
            "circle-stroke-width" :
              [
                "interpolate", ["linear"], ["zoom"],
                1, ["case", ['in', ['get', 'prop'], ['literal', ["hop"]]], 0.5, 0],
                20, ["case", ['in', ['get', 'prop'], ['literal', ["hop"]]], 10, 0],
              ],
            "circle-stroke-color":
              "#FFFFFF"
          },
          // 'filter': ['==', '$type', 'Point']
        }
      );
      map.addLayer({
          "id": traceLayerName+"hops",
          "type": "circle",
          "source": traceLayerName,
          "paint": {
            "circle-radius": [
              "interpolate", ["linear"], ["zoom"],
              1, ["case", ['in', ['get', 'prop'], ['literal', ["start", "end"]]],5,["case", ['in', ['get', 'prop'], ['literal', ["hop"]]], 2.8, 1.5]],
              20, ["case", ['in', ['get', 'prop'], ['literal', ["start", "end"]]],45,["case", ['in', ['get', 'prop'], ['literal', ["hop"]]], 28,18]],
            ],
            "circle-color":
              ["case", ["in",
                ['get', colorProperty], ["literal", [null]],],
                ['match',
                  ['get', 'prop'],
                  'start',
                  startColor,
                  'end',
                  endColor,
                  "#FFF",],
                ["get", colorProperty],
              ],
            "circle-opacity" : [
              'case',
              ['in', ['get', 'prop'], ['literal', ["hop"]]], 1, 0
            ],
            "circle-stroke-width" :
              [
                "interpolate", ["linear"], ["zoom"],
                1, ["case", ['in', ['get', 'prop'], ['literal', ["hop"]]], 0.5, 0],
                20, ["case", ['in', ['get', 'prop'], ['literal', ["hop"]]], 10, 0],
              ],
            "circle-stroke-color":
              "#FFFFFF"
          },
          // 'filter': ['==', '$type', 'Point']
        }
      );
      map.addLayer({
          "id": traceLayerName+'labels',
          "type": 'symbol',
          "source": traceLayerName,
          "layout": {
            'text-field': ['get', 'origHopNumber'],
            'text-variable-anchor': ['top', 'bottom', 'left', 'right', 'top-left', 'top-right', 'bottom-left', 'bottom-right'],
            'text-radial-offset': [
              "interpolate", ["linear"], ["zoom"],
              // zoom is 5 (or less) -> offset 1
              5, 1,
              // zoom is 10 (or greater) -> offset 3
              10, 3
            ],
            'text-justify': 'auto',
            'text-font': ['Arial Unicode MS Bold'],
          },
          "paint": {
            'text-color': ['get', colorProperty],
            'text-halo-color': '#000000',
            'text-halo-width': 1,
            'text-opacity': ['case', ['in', ['get', 'prop'], ['literal', ['hop']]], 1, 0],
          },
        }
      );
      setShowTraceLayer(true);
    }
  };

  useEffect(() => {
    addRawLayer(map, geoIpData)
  }, [geoIpData]);

  useEffect(() => {
    addRawLayer(map, data_raw)
  }, [data_raw]);

  useEffect(() => {
    if(allDatacenters){
      addClustersLayer(map, allDatacenters, [0,1000])
    }
  }, [allDatacenters]);

  useEffect(() => {
    if(data_clusters){
      // @ts-ignore
      let sizes = data_clusters.features.map(x => {return x.properties.size});
      let minMaxValues = minMax(sizes);
      addClustersLayer(map, data_clusters, minMaxValues)
    }
  }, [data_clusters]);

  useEffect(() => {
    if(centerConnectionsData){
      addCenterConnectionsLayer(map, centerConnectionsData)
    }
  }, [centerConnectionsData]);

  useEffect(() => {
    if(peeringDBData == undefined) return;
    addPeeringDBLayer(map, peeringDBData)
  }, [peeringDBData]);

  useEffect(() => {
    if(traceData == undefined) return;
    if(showTraceLayer) {
      removeSourceAndLayer(map, traceLayerName);
    }
    addTraceLayer(map, traceData);
  }, [traceData, showTraceConfidenceColors]);

  useEffect(() => {
    if(peeringDBDataAS == undefined) return;
    addPeeringDBLayer(map, peeringDBDataAS)
  }, [peeringDBDataAS]);

  useEffect(() => {
    if(harbourCenterData == undefined) return;
    addHarbourLayer(map, harbourCenterData)
  }, [harbourCenterData]);

  useEffect(() => {
    if(dnsCenterData == undefined) return;
    addDnsLayer(map, dnsCenterData)
  }, [dnsCenterData]);

  useEffect(() => {
    if(cableData == undefined) return;
    addCableLayer(map, cableData)
  }, [cableData]);

  useEffect(() => {
    if(debugCableData == undefined) return;
    addDebugCableLayer(map, debugCableData)
  }, [debugCableData]);

  function nextDebugColor(){
    const newColorCode = cableDebugColor - 0x004088
    setCableDebugColor(newColorCode);
    removeSourceAndLayer(map, debugCableLayerName);
    if(debugCableData == undefined) return;
    addDebugCableLayer(map, debugCableData, true);
  }

  useEffect(() => {
    if(dataCenterMapData == undefined) return;
    addDataCenterMapLayer(map, dataCenterMapData)
  }, [dataCenterMapData]);

  useEffect(() => {
    if(dataCenterMapDataAS == undefined) return;
    addDataCenterMapLayer(map, dataCenterMapDataAS)
  }, [dataCenterMapDataAS]);

  useEffect(() => {
    if(forbiddenZones == undefined) return;
    addForbiddenZonesLayer(map, forbiddenZones)
  }, [forbiddenZones]);

  useEffect(() => {
    if(harbourCenterDataAS == undefined) return;
    addHarbourLayer(map, harbourCenterDataAS)
  }, [harbourCenterDataAS]);

  useEffect(() => {
    if(dnsCenterDataAS == undefined) return;
    addDnsLayer(map, dnsCenterDataAS)
  }, [dnsCenterDataAS]);

  useEffect(() => {
    dispatch(clearTraceData());
    dispatch(clearTraceSummary());
  }, [])
  const handleDateChange = (date : any) => {
    if(date){
      setDate(date);
    }
  };

  useEffect(() => {
    if(selectedHop == null) return;
    let lngLat = getCoordsToFlyTo(selectedHop);
    if(lngLat == null) {
      return;
    } else {
      flyTo(lngLat);
    }
  }, [selectedHop]);

  const switchClusterLayer = (map: mapboxgl.Map | null, force? : boolean) => {
    if(force){
      setShowClusterLayer(force);
    }else{
      setShowClusterLayer(!showClusterLayer);
    }

    if(map != null){
      if(!showClusterLayer){
        map.setLayoutProperty(clustersLayerName, 'visibility', 'visible');
      }
      else{
        map.setLayoutProperty(clustersLayerName, 'visibility', 'none');
      }
    }

  };
  const switchRawDataLayer = (map: mapboxgl.Map | null, force? : boolean) => {
    if(force){
      setShowRawDataLayer(force)
    }else{
      setShowRawDataLayer(!showRawDataLayer);
    }

    if(map != null){
      if(!showRawDataLayer){
        map.setLayoutProperty(rawLayerName, 'visibility', 'visible');
      }
      else{
        map.setLayoutProperty(rawLayerName, 'visibility', 'none');
      }
    }

  };
  const switchCenterConnectionsLayer = (map: mapboxgl.Map | null, force? : boolean) => {
    if(force){
      setShowCenterConnectionsLayer(force);
    }else{
      setShowCenterConnectionsLayer(!showCenterConnectionsLayer);
    }

    if(map != null){
      if(!showCenterConnectionsLayer){
        map.setLayoutProperty(centerConnectionsLayerName, 'visibility', 'visible');
      }
      else{
        map.setLayoutProperty(centerConnectionsLayerName, 'visibility', 'none');
      }
    }

  };

  const switchTraceLayer = (map: mapboxgl.Map | null, force? : boolean) => {
    if(force){
      setShowTraceLayer(force);
    }else{
      setShowTraceLayer(!showTraceLayer);
    }

    if(map != null){
      if(!showTraceLayer){
        map.setLayoutProperty(traceLayerName, 'visibility', 'visible');
        map.setLayoutProperty(traceLayerName+"p", 'visibility', 'visible');
        map.setLayoutProperty(traceLayerName+"hops", 'visibility', 'visible');
        map.setLayoutProperty(traceLayerName+"labels", 'visibility', 'visible');
      }
      else{
        map.setLayoutProperty(traceLayerName, 'visibility', 'none');
        map.setLayoutProperty(traceLayerName+"p", 'visibility', 'none');
        map.setLayoutProperty(traceLayerName+"hops", 'visibility', 'none');
        map.setLayoutProperty(traceLayerName+"labels", 'visibility', 'none');
      }
    }

  };
  const switchPDBLayer = (map: mapboxgl.Map | null, force? : boolean) => {
    if(force){
      setShowPDBLayer(force);
    }else{
      setShowPDBLayer(!showPDBLayer);
    }

    if(map != null){
      if(!showPDBLayer){
        map.setLayoutProperty(peeringDBLayerName, 'visibility', 'visible');
      }
      else{
        map.setLayoutProperty(peeringDBLayerName, 'visibility', 'none');
      }
    }

  };

  const switchHarbourCenterLayer = (map: mapboxgl.Map | null, force? : boolean) => {
    if(force){
      setShowHarbourCenterLayer(force);
    }else{
      setShowHarbourCenterLayer(!showHarbourCenterLayer);
    }

    if(map != null){
      if(!showHarbourCenterLayer){
        map.setLayoutProperty(harbourCenterLayerName, 'visibility', 'visible');
      }
      else{
        map.setLayoutProperty(harbourCenterLayerName, 'visibility', 'none');
      }
    }

  };

  const switchDnsCenterLayer = (map: mapboxgl.Map | null, force? : boolean) => {
    if(force){
      setShowDnsCenterLayer(force);
    }else{
      setShowDnsCenterLayer(!showDnsCenterLayer);
    }

    if(map != null){
      if(!showDnsCenterLayer){
        map.setLayoutProperty(dnsCenterLayerName, 'visibility', 'visible');
      }
      else{
        map.setLayoutProperty(dnsCenterLayerName, 'visibility', 'none');
      }
    }

  }

  const switchCableLayer = (map: mapboxgl.Map | null, force? : boolean) => {
    if(force){
      setShowCableLayer(force);
    }else{
      setShowCableLayer(!showCableLayer);
    }

    if(map != null){
      if(!showCableLayer){
        map.setLayoutProperty(cableLayerName, 'visibility', 'visible');
      }
      else{
        map.setLayoutProperty(cableLayerName, 'visibility', 'none');
      }
    }

  }

  const switchDataCenterMapLayer = (map: mapboxgl.Map | null, force? : boolean) => {
    if(force){
      setShowDataCenterMapLayer(force);
    }else{
      setShowDataCenterMapLayer(!showDataCenterMapLayer);
    }

    if(map != null){
      if(!showDataCenterMapLayer){
        map.setLayoutProperty(dataCenterMapLayerName, 'visibility', 'visible');
      }
      else{
        map.setLayoutProperty(dataCenterMapLayerName, 'visibility', 'none');
      }
    }

  };

  const switchForbiddenZonesLayer = (map: mapboxgl.Map | null, force? : boolean) => {
    if(force){
      setShowForbiddenZonesLayer(force);
    }else{
      setShowForbiddenZonesLayer(!showForbiddenZonesLayer);
    }

    if(map != null){
      if(!showForbiddenZonesLayer){
        map.setLayoutProperty(forbiddenZonesLayerName, 'visibility', 'visible');
      }
      else{
        map.setLayoutProperty(forbiddenZonesLayerName, 'visibility', 'none');
      }
    }

  };

  const approxCenterMinBlockSize = (minBlockSize: number) => {
    if(asn != minBlockSize) {
      setAsn(minBlockSize);
      setAsnInput(minBlockSize.toString());
    }
    removeSourceAndLayer(map, clustersLayerName);
    dispatch(fetchAsnDataCentersMinBlockSize(minBlockSize, format(date, "yyyy-MM-dd")));
  }

  const setTrInputType = (type: string) => {
    if(type == "Linux" || type == "Windows" || type == "BGP"){
      dispatch(setTraceInputType(type));
      dispatch(setShowTraceConfidenceColors(false));
    }
  }

  const searchAsn = (asNumber: number, hideTrace?: boolean) => {
    if(hideTrace && showTraceLayer) switchTraceLayer(map,false);
    if(asn != asNumber) {
      setAsn(asNumber);
      setAsnInput(asNumber.toString());
    }
    removeSourceAndLayer(map, rawLayerName);
    removeSourceAndLayer(map, centerConnectionsLayerName);
    removeSourceAndLayer(map, clustersLayerName);
    removeSourceAndLayer(map, peeringDBLayerName);
    removeSourceAndLayer(map, harbourCenterLayerName);
    removeSourceAndLayer(map, dataCenterMapLayerName);
    removeSourceAndLayer(map, dnsCenterLayerName);
    if (showCableLayer) switchCableLayer(map, false);
    dispatch(fetchPeeringDBDataAS(asNumber));
    dispatch(fetchHarbourCenterDataAS(asNumber, format(date, "yyyy-MM-dd")));
    dispatch(fetchAsnDataCentersV2(asNumber, format(date, "yyyy-MM-dd")));
    dispatch(fetchAsnDataCentersRaw(asNumber, format(date, "yyyy-MM-dd")));
    dispatch(fetchAsnDataCentersConnections(asNumber, format(date, "yyyy-MM-dd")));
    dispatch(fetchDataCenterMapDataAS(asNumber));
    dispatch(fetchDnsCenterDataAS(asNumber, format(date, "yyyy-MM-dd")));
  }

  const clearAsn = () => {
    dispatch(clearCenterConnections());
    removeSourceAndLayer(map, centerConnectionsLayerName);
    removeSourceAndLayer(map, rawLayerName);
    removeSourceAndLayer(map, clustersLayerName);
    removeSourceAndLayer(map, peeringDBLayerName);
    removeSourceAndLayer(map, harbourCenterLayerName);
    removeSourceAndLayer(map, dataCenterMapLayerName);
    removeSourceAndLayer(map, dnsCenterLayerName);
    if (showCableLayer) switchCableLayer(map, false);
    setAsn(-1);
    setAsnInput('');
    if(!showTraceLayer) {
      dispatch(fetchGeoIpData(format(date, "yyyy-MM-dd")));
      dispatch(fetchAllDataCenters(format(date, "yyyy-MM-dd")));
      dispatch(fetchPeeringDBData());
      dispatch(fetchHarbourCenterData());
      dispatch(fetchDataCenterMapData());
      dispatch(fetchDnsCenterData(format(date, "yyyy-MM-dd")));
    }
  }

  let legend = [];
  for (let i = 0; i<colorMap.length; i++){
    let num : number | string = colorMap[i][0];
    legend.push(
      <div key={num.toString()} style={{display:"flex", justifyContent:"flex-end"}}>
        <Typography>{">"+new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(num as number)}</Typography>
        <div style={{
          backgroundColor: ""+colorMap[i][1],
          width: 20,
          height: 20,
          marginBottom: 2,
          marginLeft: 3
        }} />
      </div>
    );
  }
  legend.reverse();

  function getTextWidth(text : string) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    let width = 200;
    if(context){
      //context.font = getComputedStyle(document.body).font;
      context.font = "17px Roboto, Helvetica, Arial, sans-serif";
      width = context.measureText(text).width;
    }
    canvas.remove();
    if(width<450) width = 450;
    setTraceStringWidth(width);
  }

  function longestLine(text : string){
    let strAry : string[] = text.split("\n");

    let longest = strAry.reduce(
      function (a, b) {
        return a.length > b.length ? a : b;
      }
    );
    return longest;
  }

  // Find ip4 within string assuming it is in Linux formatting  (64.125.26.24) (<ip>)
  function findIP(line : string){
    let matches = line.match(/\(([^)]+)\)/);
    let ip = '';
    if (matches){
      ip = matches[1];
    }
    return ip;
  }

  // find domain in string assuming it is the substring before the (<ip>)
  function findDomain(line: string, ip: string) {
    const lineUntilIp = line.split('('+ip+')')[0];
    const matches = lineUntilIp.match(/(?:[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?\.)+[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9]/g);
    if (matches){
      // dont return ips
      const domain = matches[matches.length - 1];
      if (!/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(domain)){
        return domain
      } else {
        return '';
      }
    }
    return '';
  }

  // Find ip4 within string eg. "64.125.26.245" from "ip is 64.125.26.245"
  function findPureIP(line : string){
    //let matches = line.match(/[\(\[]?((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))[\)\]]?/);
    let matches = line.match(/[0-2]?\d{1,2}\.[0-2]?\d{1,2}\.[0-2]?\d{1,2}\.[0-2]?\d{1,2}/g);
    let ip = '';
    if (matches){
      ip = matches[matches.length - 1];
    }
    return ip;
  }

  // find domain in string assuming it is the substring before the [<ip>]
  function findPureDomain(line: string, ip: string) {
    const lineUntilIp = line.split('['+ip+']')[0];
    const matches = lineUntilIp.match(/(?:[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?\.)+[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9]/g);
    if (matches){
      // dont return ips
      const domain = matches[matches.length - 1];
      if (!/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(domain)){
        return domain
      } else {
        return '';
      }
    }
    return '';
  }

  // find rtt measurements in string
  function findRTTValues(line : string){
    //let matches = line.match(/[\(\[]?((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))[\)\]]?/);
    let matches = line.match(/[0-9]+?.?[0-9]* ms/g);
    let rttArray : string[] = [];
    if(matches) {
      matches.forEach((match) => rttArray.push(match.split(' ')[0]));
    }
    return rttArray;
  }

  function findIpWithPrefix(line : string) {
    //let matches = line.match(/[\(\[]?((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))[\)\]]?/);
    let matches = line.match(/[0-2]?\d{1,2}\.[0-2]?\d{1,2}\.[0-2]?\d{1,2}\.[0-2]?\d{1,2}(\/[1-3]?\d)?/);
    let ip = '';
    if (matches){
      ip = matches[0];
    }
    return ip;
  }
  function findPureAS(line : string){
    let matches = line.match(/\[AS(\d*?)(\/AS(\d*?))?\]/);
    let as = '';
    if (matches){
      as = matches[1];
    }
    return as;
  }
  function findAS(line : string){
    let matches = line.match(/\[(.*?)\]/);
    let as = '';
    if (matches){
      as = matches[1];
      if (matches[1] == '*') return '';
    }
    return as;
  }

  function parseAsPath(asPathStr: string){
    let asAry: string[] = [];

    let asPathAry = asPathStr.match(/\S+/g) || [];
    for (const asn of asPathAry) {
      asAry.push(asn);
    }
    //console.log(asAry);
    return asAry
  }

  function checkForTimeout(line: string) {
    let res = false;
    let matches = line.match(/\*/g);
    if (matches) {
      res = (matches.length == 3);
    }
    return res
  }

  function parseTrace(multiLine : string){
    let strAry = multiLine.split("\n");
    let asAry: string[] = [];
    let ipAry : string[] = [];
    let domainAry : string[] = [];
    let rttAry : string[][] = [];
    let traceObj = {
      as: asAry,
      ip: ipAry,
      domain: domainAry,
      rtt: rttAry,
    };
    for (const line of strAry) {
      let as = '';
      let ip = '';
      let domain = '';
      let rtt: string[] = [];
      switch(trInputType) {
        case "Windows": {
          as = findPureAS(line);
          ip = findPureIP(line);
          domain = findPureDomain(line, ip);
          rtt = findRTTValues(line);
          if(ip.length == 0) {
            if(checkForTimeout(line)) {
              asAry.push(as);
              ipAry.push(ip);
              domainAry.push(domain);
              rttAry.push(rtt);
            }
            break;
          }
          asAry.push(as);
          ipAry.push(ip);
          domainAry.push(domain);
          rttAry.push(rtt);
          break;
        }
        case "Linux": {
          as = findAS(line);
          ip = findIP(line);
          domain = findDomain(line, ip);
          rtt = findRTTValues(line);
          if(ip.length == 0) {
            if(checkForTimeout(line)) {
              asAry.push(as);
              ipAry.push(ip);
              domainAry.push(domain);
              rttAry.push(rtt);
            }
            break;
          }
          asAry.push(as.substring(2,as.length));
          ipAry.push(ip);
          domainAry.push(domain);
          rttAry.push(rtt);
          break;
        }
        default: {
          asAry.push(as);
          ipAry.push(ip);
          domainAry.push(domain);
          rttAry.push(rtt);
          break;
        }
      }
    }
    //console.log(traceObj)
    return traceObj;
  }

  function queryTraceroute(){
    if (trInputType == "Windows") {
      let traceObj = parseTrace(winTrace);
      dispatch(fetchTracePathData(traceObj, format(date, "yyyy-MM-dd"), traceEvalMode));
      return;
    }
    if (trInputType == "Linux") {
      let traceObj = parseTrace(trace);
      dispatch(fetchTracePathData(traceObj, format(date, "yyyy-MM-dd"), traceEvalMode));
      return;
    }
    // BPG
    let asAry = parseAsPath(asPath);
    let parsedStartIp = findIpWithPrefix(startIp);
    let parsedEndIp = findIpWithPrefix(endIp);
    let traceObj = {
      startIP: parsedStartIp,
      endIP: parsedEndIp,
      asPath: asAry,
    };
    dispatch(fetchBGPTracePathData(traceObj, format(date, "yyyy-MM-dd")));
    return;
  }

  function fetchDebugCableData() {
    // change id here to review different cables, change between split and original cable data in backend
    RestServiceUtils.jsonGET('/api/debugCable/south-american-crossing-sac',
      //@ts-ignore
      result => setDebugCableData(result),
      error => console.log(error)
    );
  }

  function renderInputField(){
    const style = {
      width: traceStringWidth,
      transition: "all 0.5s",
    }
    const onFocus = (e: any) => {
      getTextWidth(longestLine(trace));
      setMultiRows(22)
    }
    const onBlur = (e: any) => {
      setTraceStringWidth(450);
      setMultiRows(10)
    }
    switch (trInputType) {
      case "BGP":
        return <div style={{ display: 'flex',flexDirection: 'column'}}>
          <TextField
            style={style}
            label="Enter start IP eg. 84.116.191.221"
            id="startIp-textarea"
            value={startIp}
            onChange={event => {
              setStartIp(event.target.value);
            }}
          >
          </TextField>
          <TextField
            style={style}
            label="Enter end IP eg. 195.2.18.217"
            id="endIp-textarea"
            value={endIp}
            onChange={event => {
              setEndIp(event.target.value);
            }}
          >
          </TextField>
          <TextField
            style={style}
            label="Enter As Path eg. 6830 1273"
            id="startIp-textarea"
            value={asPath}
            onChange={event => {
              setAsPath(event.target.value);
            }}
          >
          </TextField>
        </div>
      case "Windows":
        return <div>
          <TextField
            style={style}
            fullWidth={true}
            maxRows={multiRows}
            multiline
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={event => {
              setWinTrace(event.target.value);
              getTextWidth(longestLine(event.target.value));
              setMultiRows(22)
            }}
            id="Windows-textarea"
            label= "Enter Windows traceroute log"
            value={winTrace}
          /></div>
      default:
        return <div>
          <TextField
            style={style}
            fullWidth={true}
            maxRows={multiRows}
            multiline
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={event => {
              setTrace(event.target.value);
              getTextWidth(longestLine(event.target.value));
              setMultiRows(22)
            }}
            id="Linux-textarea"
            label= "Enter Linux traceroute -A log"
            value={trace}
          /></div>
    }
  }

  // @ts-ignore
  function flyTo (location) {
    if(map != null) {
      // @ts-ignore
      map.flyTo({center: location, zoom: 9});
    }
  }

  function getCoordsToFlyTo (step : Number) {
    let counter = 0;
    if (traceData == undefined) return;
    // @ts-ignore
    for (const feature of traceData.features) {
      if (feature.geometry.type == "Point" && feature.properties?.prop == "hop") {
        if (counter == step) {
          //@ts-ignore
          return feature.geometry.coordinates;
        }
        counter++;
      }
    }
    return null;
  }

  return (
    <>
      <div style={{position: "absolute", zIndex: 1}}>
        <div style={{display:"flex"}}>
          <TextField style={{marginBottom: 20, width:220}} id="asn" label="Enter AS Number"
                     type={"number"}
                     onChange={event => {
                       if(event.target.value.length <= 0){
                         setAsn(-1)
                         setAsnInput('')
                       }
                       else{
                         setAsn(parseInt(event.target.value))
                         setAsnInput(event.target.value)
                       }

                     }}
                     value={asnInput}
                     onKeyPress={
                       event => {
                         if (event.key == "Enter") {
                           //updateSourcesAndLayer(map)
                           searchAsn(asn, true);
                         }
                       }}/>
          {/*        <MuiPickersUtilsProvider utils={DateFnsUtils} >
        <KeyboardDatePicker
          style={{marginLeft: 10, width:220}}
          disableToolbar
          variant="inline"
          label="Pick a date"
          value={date}
          onChange={handleDateChange}
          format="yyyy-MM-dd"
          shouldDisableDate={disableUnavailableDates}
        />
        </MuiPickersUtilsProvider>*/}
          <Button variant="contained" color="primary"
                  style={{marginLeft: 10, marginBottom: "auto", marginTop: "auto", height: 30}}
                  disabled={asn < 0 || anyInitFetchRunning}
                  onClick={(e) => {
                    searchAsn(asn, true);
                  }}><SearchIcon/>
          </Button>
          {
            debug &&
            <MaterialTooltip
              title={<Typography style={{fontSize: 12}}>
                Filter the approximated data centers by a minimum size entered in the ASN text field (for debugging)
              </Typography>}
              placement={"bottom"}
              arrow
            >
              <div style={{marginLeft: 10, marginBottom: "auto", marginTop: "auto", height: 30}}>
                <Button variant="contained" color="secondary"
                        style={{height: 30}}
                        disabled={asn < 0 || anyInitFetchRunning}
                        onClick={(e) => {
                          approxCenterMinBlockSize(asn);
                        }}><BugReportIcon/>
                </Button>
              </div>
            </MaterialTooltip>
          }
          {
            cableDebug &&
            <>
            <MaterialTooltip
              title={<Typography style={{fontSize: 12}}>
                Display one cable to analyse errors in splitting the cable
              </Typography>}
              placement={"bottom"}
              arrow
            >
              <div style={{marginLeft: 10, marginBottom: "auto", marginTop: "auto", height: 30}}>
                <Button variant="contained" color="secondary"
                        style={{height: 30}}
                        disabled={anyInitFetchRunning}
                        onClick={(e) => {
                          fetchDebugCableData();
                        }}><BugReportIcon/>
                </Button>
              </div>
            </MaterialTooltip>
            <div style={{marginLeft: 10, marginBottom: "auto", marginTop: "auto", height: 30}}>
              <Button variant="contained" color="secondary"
                      style={{height: 30}}
                      disabled={anyInitFetchRunning}
                      onClick={() => {
                        nextDebugColor();
                      }}><BugReportIcon/>
              </Button>
            </div>
            </>
          }
          {centerConnectionsData &&
          <Button variant="contained" color="primary"
                  style={{marginLeft: 10, marginBottom: "auto", marginTop: "auto", height: 30}}
                  onClick={() => clearAsn()}
          >
            <ClearIcon/>
          </Button>}
          {(fetching_raw || fetching_centers || fetching_connections || anyInitFetchRunning) && <CircularProgress color="primary" style={{
            marginLeft: 10, marginBottom: "auto", marginTop: "auto",
            height: 30,
            width: 30,
            zIndex: 1
          }}/>}
        </div>
        <div style={{display:"flex", paddingBottom: 5}}>
          <Typography >Query Traceroute or AS path:</Typography>
          <Button variant="contained" color="primary"
                  style={{marginLeft: 10, marginBottom: 0, marginTop: "auto", height: 30}}
                  disabled={trInputType == "Linux" && trace.length<=0
                  || trInputType == "Windows" && winTrace.length <= 0
                  || trInputType == "BGP" && (startIp.length <= 0 || endIp.length <= 0 || asPath.length <= 0)}
                  onClick={(e) => {
                    // clear old trace
                    dispatch(clearTraceData());
                    dispatch(clearTraceSummary());
                    removeSourceAndLayer(map, traceLayerName);
                    setSelectedHop(-1);
                    // get new trace
                    queryTraceroute();
                    dispatch(setShowTraceSummary(true));
                    if(!showTraceLayer) switchTraceLayer(map, true);
                    if(showRawDataLayer) switchRawDataLayer(map,false);
                    if(showCenterConnectionsLayer) switchCenterConnectionsLayer(map,false);
                    if(showClusterLayer) switchClusterLayer(map,false);
                    if(showPDBLayer) switchPDBLayer(map,false);
                    if(showHarbourCenterLayer) switchHarbourCenterLayer(map, false);
                    if(showForbiddenZonesLayer) switchForbiddenZonesLayer(map, false);
                    if(showDataCenterMapLayer) switchDataCenterMapLayer(map, false);
                    if(showCableLayer) switchCableLayer(map, false);
                    if(showDnsCenterLayer) switchDnsCenterLayer(map, false);
                  }}><SearchIcon/>
          </Button>
          {traceData &&
          <Button variant="contained" color="primary"
                  style={{marginLeft: 10, marginBottom: 0, marginTop: "auto", height: 30}}
                  onClick={(e) => {
                    dispatch(clearTraceData());
                    dispatch(clearTraceSummary());
                    removeSourceAndLayer(map, traceLayerName);
                    dispatch(setShowTraceSummary(false));
                    setShowTraceLayer(false);
                    // reset Textfields
                    setTrace('');
                    setWinTrace('');
                    setStartIp('');
                    setEndIp('');
                    setAsPath('');
                    // show raw data again (or ASN if one is currently loaded)
                    if(centerConnectionsData) {
                      if (!showRawDataLayer) switchRawDataLayer(map, true);
                      if (!showCenterConnectionsLayer) switchCenterConnectionsLayer(map, true);
                      if (!showClusterLayer) switchClusterLayer(map, true);
                      if (!showPDBLayer) switchPDBLayer(map, true);
                      if (!showHarbourCenterLayer) switchHarbourCenterLayer(map, true);
                      if (!showDataCenterMapLayer) switchDataCenterMapLayer(map, false);
                      if (showCableLayer) switchCableLayer(map, false);
                      if (!showDnsCenterLayer) switchDnsCenterLayer(map, false);
                    } else {
                      // if no data is loaded get initial data
                      removeSourceAndLayer(map, rawLayerName);
                      removeSourceAndLayer(map, clustersLayerName);
                      removeSourceAndLayer(map, peeringDBLayerName);
                      removeSourceAndLayer(map, harbourCenterLayerName);
                      removeSourceAndLayer(map, dataCenterMapLayerName);
                      removeSourceAndLayer(map, dnsCenterLayerName);
                      if (showCableLayer) switchCableLayer(map, false);
                      dispatch(fetchGeoIpData(format(date, "yyyy-MM-dd")));
                      dispatch(fetchAllDataCenters(format(date, "yyyy-MM-dd")));
                      dispatch(fetchPeeringDBData());
                      dispatch(fetchHarbourCenterData());
                      dispatch(fetchDataCenterMapData());
                      dispatch(fetchDnsCenterData(format(date, "yyyy-MM-dd")));
                    }
                  }}><ClearIcon/>
          </Button>}
          <Button variant="contained" color="primary"
                  style={{marginLeft: 10, marginBottom: "auto", marginTop: "auto", height: 30}}
                  onClick={() => setTraceEvalMode(!traceEvalMode)}
                  disabled={trInputType === 'BGP'}
          >
            <FlipCameraAndroidIcon/>
            <div className={classes.buttonLabel}>
              Use {!traceEvalMode ? ' only MaxMind' : ' Approximations'}
            </div>
          </Button>
        </div>
        <div style={{display:"flex", paddingBottom: 5}}>
          <FormControl component="fieldset">
            <FormLabel component="legend" focused={false}>Choose Input type</FormLabel>
            <RadioGroup
              row
              aria-label="Choose Input type"
              value={trInputType}
              onChange={event => {
                setTrInputType(event.target.value);
                setTraceEvalMode(false);
                dispatch(clearTraceData());
                dispatch(clearTraceSummary());
                removeSourceAndLayer(map, traceLayerName);
              }}
            >
              <FormControlLabel value="Linux" control={<Radio color="primary" />} label="Linux" />
              <FormControlLabel value="Windows" control={<Radio color="primary" />} label="Windows" />
              <FormControlLabel value="BGP" control={<Radio color="primary" />} label="BGP" />
            </RadioGroup>
          </FormControl>
        </div>
        <div style={{display:"flex"}}>
          {renderInputField()}
          {(fetchingTrace) && <CircularProgress color="primary" style={{
            marginLeft: 10, marginBottom: "auto", marginTop: "auto",
            height: 30,
            width: 30,
            zIndex: 1
          }}/>}
        </div>

        {(traceSummary && traceData) &&
        <div style={{alignSelf: 'flex-end'}} >
          <TraceRouteSummary
            isOpen={showTraceSummary}
            summary={traceSummary}
            searchAsn={(asn: number) => searchAsn(asn)}
            clearAsn={() => clearAsn()}
            currentAsn={asn}
            setSelectedHop={setSelectedHop}
          />
        </div>
        }
      </div>
      { traceSummary &&
        <div style={{position: "absolute", top: 70, right: showTraceSummary ? 320 : 20, zIndex: 1}}>
          <Button variant="contained" color="primary"
                  style={{marginLeft: 10, marginBottom: "auto", marginTop: "auto", height: 30}}
                  onClick={() => {dispatch(setShowTraceConfidenceColors(!showTraceConfidenceColors))}}
                  disabled={trInputType === 'BGP'}
          >
            <TuneIcon/>
            <div className={classes.buttonLabel}>
              {showTraceConfidenceColors ? 'AS ' : 'Confidence '}
              View
            </div>
          </Button>
          <Button variant="contained" color="primary"
                  style={{marginLeft: 10, marginBottom: "auto", marginTop: "auto", height: 30}}
                  onClick={() => {dispatch(setShowTraceSummary(!showTraceSummary))}}
          >
            <LinearScaleIcon/>
            <div className={classes.buttonLabel}>
              {showTraceSummary ? 'Hide ' : 'Show '}
              Trace Summary
            </div>
          </Button>
        </div>
      }
      <div ref={(el: any) => (mapContainer.current = el)} style={{
        left: 0,
        top: 48,
        width: "100vw",
        height: "calc(100vh - 80px)",
        position: "fixed"
      }}/>
      {<div style={{bottom: 80, position: "fixed", zIndex: 1, width: 200}}>
        {traceData &&
        <FormControl component="fieldset" style={{height: 30}}>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="end"
              control={<Checkbox color={"primary"} checked={showTraceLayer} onChange={(event) => switchTraceLayer(map)}/>}
              label={
                <MaterialTooltip
                  title={<Typography style={{fontSize: 12}}>Calculated routing path</Typography>}
                  placement={"right"}
                  arrow
                >
                  <Typography style={{alignSelf:"center"}}>Routing Path</Typography>
                </MaterialTooltip>
              }
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
        }
        {(dnsCenterData || dnsCenterDataAS) &&
          <FormControl component="fieldset" style={{height: 30}}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="end"
                control={<Checkbox style={{color: "#f7ff00"}} checked={showDnsCenterLayer} onChange={(event) => switchDnsCenterLayer(map)}/>}
                label={
                  <MaterialTooltip
                    title={<Typography style={{fontSize: 12}}>Data center locations aggregated by analyzing CAIDA IP to hostname datasets</Typography>}
                    placement={"right"}
                    arrow
                  >
                    <Typography style={{alignSelf:"center"}}>DNS Center</Typography>
                  </MaterialTooltip>
                }
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>}
        {(peeringDBData || peeringDBDataAS) &&
        <FormControl component="fieldset" style={{height: 30}}>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="end"
              control={<Checkbox style={{color: "#1c34ff"}} checked={showPDBLayer} onChange={(event) => switchPDBLayer(map)}/>}
              label={
                <MaterialTooltip
                  title={<Typography style={{fontSize: 12}}>Data center locations provided to PeeringDB by AS organizations</Typography>}
                  placement={"right"}
                  arrow
                >
                  <Typography style={{alignSelf:"center"}}>PeeringDB Locations</Typography>
                </MaterialTooltip>
              }
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>}
        {(dataCenterMapData || dataCenterMapDataAS) &&
          <FormControl component="fieldset" style={{height: 30}}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="end"
                control={<Checkbox style={{color: dataCenterMapColor}} checked={showDataCenterMapLayer}
                                   onChange={(event) => switchDataCenterMapLayer(map)}/>}
                label={
                  <MaterialTooltip
                    title={<Typography style={{fontSize: 12}}>Data centers sourced from datacentermap.com</Typography>}
                    placement={"right"}
                    arrow
                  >
                    <Typography style={{alignSelf:"center"}}>DataCenterMap</Typography>
                  </MaterialTooltip>
                }
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>}
        {(harbourCenterData || harbourCenterDataAS) &&
        <FormControl component="fieldset" style={{height: 30}}>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="end"
              control={<Checkbox style={{color: "#3bfcd8"}} checked={showHarbourCenterLayer}
                                 onChange={(event) => switchHarbourCenterLayer(map)}/>}
              label={
                <MaterialTooltip
                  title={<Typography style={{fontSize: 12}}>Data centers serving as landing points for submarine internet connections</Typography>}
                  placement={"right"}
                  arrow
                >
                  <Typography style={{alignSelf:"center"}}>Harbour Data Center</Typography>
                </MaterialTooltip>
              }
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>}
        {cableData &&
          <FormControl component="fieldset" style={{height: 30}}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="end"
                control={<Checkbox style={{color: "#3bfcd8"}} checked={showCableLayer}
                                   onChange={(event) => switchCableLayer(map)}/>}
                label={
                  <MaterialTooltip
                    title={<Typography style={{fontSize: 12}}>Raw cable data from submarinecablemap.com</Typography>}
                    placement={"right"}
                    arrow
                  >
                    <Typography style={{alignSelf:"center"}}>Submarine Cables</Typography>
                  </MaterialTooltip>
                }
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>}
        {forbiddenZones &&
        <FormControl component="fieldset" style={{height: 30}}>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="end"
              control={<Checkbox style={{color: "#ff0000"}} checked={showForbiddenZonesLayer}
                                 onChange={(event) => switchForbiddenZonesLayer(map)}/>}
              label={
                <MaterialTooltip
                  title={<Typography style={{fontSize: 12}}>Areas that most likely don't contain standard land connections between data centers, submarine cable connections are allowed, all others are penalized during network generation</Typography>}
                  placement={"right"}
                  arrow
                >
                  <Typography style={{alignSelf:"center"}}>Penalty Zones</Typography>
                </MaterialTooltip>
              }
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>}
        {(data_clusters || allDatacenters) &&
        <FormControl component="fieldset" style={{height: 30}}>
          <FormGroup  aria-label="position" row>
            <FormControlLabel
              value="end"
              control={<Checkbox style={{color: "#ff8535"}} checked={showClusterLayer} onChange={(event) => switchClusterLayer(map)}/>}
              label={
                <MaterialTooltip
                  title={<Typography style={{fontSize: 12}}>Data centers calculated by our approximation algorithm</Typography>}
                  placement={"right"}
                  arrow
                >
                  <Typography style={{alignSelf:"center"}}>Approx. Data Center</Typography>
                </MaterialTooltip>
              }
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>}
        {centerConnectionsData &&
        <FormControl component="fieldset" style={{height: 30}}>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="end"
              control={<Checkbox style={{color: "#ffffff"}} checked={showCenterConnectionsLayer} onChange={(event) => switchCenterConnectionsLayer(map)}/>}
              label={
                <MaterialTooltip
                  title={<Typography style={{fontSize: 12}}>Calculated network</Typography>}
                  placement={"right"}
                  arrow
                >
                  <Typography style={{alignSelf:"center"}}>DC Connections</Typography>
                </MaterialTooltip>
              }
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>}
        {(data_raw || geoIpData) &&
        <>
          <FormControl component="fieldset" style={{height: 30}}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="end"
                control={<Checkbox style={{color: "#ffffff"}} checked={showRawDataLayer} onChange={(event) => switchRawDataLayer(map)}/>}
                label={
                  <MaterialTooltip
                    title={<Typography style={{fontSize: 12}}>Raw GeoIP data</Typography>}
                    placement={"right"}
                    arrow
                  >
                    <Typography style={{alignSelf:"center"}}>AS IP blocks</Typography>
                  </MaterialTooltip>
                }
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </>}
      </div>
      }

      {false && data_clusters && showClusterLayer &&
      <div style={{top:90, right: 10, position: "absolute", zIndex: 1, width: 225}}>
        <Typography style={{textAlign:"right"}}>IP Addresses per Data Center</Typography>
        {legend}
      </div>
      }

    </>
  );
};

export default MapComponent;
