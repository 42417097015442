import {AsnInfoState, AsnInfoActionTypes, AsnInfoActions, AsnInfoData} from './types';
import { StoreUtils } from '@iva/store-utils';
import {Feature, FeatureCollection} from "geojson";

// Initial state
const initialState: AsnInfoState = {
  asnInfoData : undefined,
  fetchError : "",
  fetching : false,
};



// The reducer function
export function asnInfoReducer(state = initialState, action: AsnInfoActions): AsnInfoState {
  switch (action.type) {

    case AsnInfoActionTypes.FETCH_ASNINFO_REQUEST: {
      return { ...state, fetching : true};
    }
    case AsnInfoActionTypes.FETCH_ASNINFO_SUCCESS: {
      return { ...state, fetching : false, asnInfoData : action.payload as AsnInfoData };
    }
    case AsnInfoActionTypes.FETCH_ERROR: {
      return { ...state, fetching : false, asnInfoData : undefined , fetchError: action.payload };
    }

    default: {
      return state;
    }
  }
};
