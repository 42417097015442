import { AsyncResource, Action, AsyncFetchAction } from '@iva/store-utils';

export interface AsRankData {
  readonly rank : number,
  readonly tier : number,
  readonly customerConeSize : number,
}
// State definition
export interface ASRankState {
  // Used for fetching a resource via RestServiceUtils
  readonly asRankData: AsRankData[] | undefined;

}

// Action types definition
export enum ASRankActionTypes {

  FETCH_ASRANK_DATA_SUCCESS = '@@asrankdata/FETCH_ASRANK_DATA_SUCCESS',
}

// Actions for manual fetching resources

export type FetchAsnInfoSuccessAction = Action<ASRankActionTypes.FETCH_ASRANK_DATA_SUCCESS, any>;

export type ASRankActions = FetchAsnInfoSuccessAction;
