import {
  Checkbox,
  CircularProgress,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
} from "@material-ui/core";

import React, {ChangeEvent, useEffect, useState} from "react";
import {fetchPrefixData, setPrefixCollectors, setPrefixDateFrom, setPrefixDateTo} from "../../../store/bgpData/actions";
import {AsnInfoData} from "../../../store/asnInfo/types";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import SearchIcon from "@material-ui/icons/Search";
import {makeStyles} from "@material-ui/core/styles";
import "../BGPDashboard/style/input.css";
import {useSearchParams} from "react-router-dom";
import {RestServiceUtils} from "@iva/rest-service-utils";
import {green, red} from "@material-ui/core/colors";
import {CheckCircleOutline, ErrorOutline, HourglassEmpty, InfoOutlined} from "@material-ui/icons";
import {BGPProgress} from "@pages/BGPDashboard/models/BGPProgress";

let asnInfoMap = new Map<number, AsnInfoData>();

const useStyles = makeStyles((theme: Theme) => ({
  selectCollectorForm: {
    width: 200
  },
  buttonWrapper: {
    position: "relative",
    marginBottom: "auto",
    marginTop: "auto",
    marginLeft: 10
  },
  searchButton: {
    height: 40,
    width: 40,
    "&:hover, &.Mui-focusVisible": {backgroundColor: theme.palette.primary.dark},
    "&:disabled": {backgroundColor: theme.palette.action.disabled},
    background: theme.palette.primary.main,
    zIndex: 1,
  },
  progressSpinner: {
    position: 'absolute',
    top: -4,
    left: -4,
    //zIndex: 1,
  },
  progressDrawer: {
    minHeight: 300,
    maxHeight: "80%",
    padding: 20,
  },
  input: {
    fontSize: '0.9rem'
  },
  denseTableCell: {
    padding: 4,
  }
}));


const QueryComponent = (props: any) => {

  const [searchParams, setSearchParams] = useSearchParams();
  const dateFromParam = Number(searchParams.get("dateFrom")) ? Number(searchParams.get("dateFrom")) : 1559815200000;
  const dateToParam = Number(searchParams.get("dateTo")) ? Number(searchParams.get("dateTo")) : 1559816040000;
  const prefixParam = searchParams.get("prefix") ? searchParams.get("prefix")! : "46.145.0.0/16";

  const [prefix, setPrefix] = useState(prefixParam);
  const [dateFrom, setDateFrom] = useState(new Date(dateFromParam));
  const [dateTo, setDateTo] = useState(new Date(dateToParam));
  const [collectors, setCollectors] = React.useState([] as string[]);
  const [allCollectors, setAllCollectors] = React.useState([] as string[]);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const dispatch = useDispatch();
  const fetching_prefixPaths = useSelector((state: ApplicationState) => state.bgpData.fetching_prefixPaths);
  const sessionId = useSelector((state: ApplicationState) => state.websocket.sessionId);
  const colorState = useSelector((state: ApplicationState) => state.bgpData.colorState);

  const progress = useSelector((state: ApplicationState) => state.bgpData.progress);
  const progressDateFrom = useSelector((state: ApplicationState) => state.bgpData.prefixDateFrom);
  const progressDateTo = useSelector((state: ApplicationState) => state.bgpData.prefixDateTo);
  const progressCollectors = useSelector((state: ApplicationState) => state.bgpData.prefixCollectors);


  useEffect(() => {
    RestServiceUtils.jsonGET('/api/collectors',
      (result) => {
        let collectors = result as string[];
        setAllCollectors(collectors);

        let collectorParam = null;
        try {
          collectorParam = JSON.parse(searchParams.get("collectors")!);
          if (!Array.isArray(collectorParam)) {
            collectorParam = null;
          }
        } catch (e) {
          console.error("invalid collector query param", e);
        }
        let initCollectors = collectorParam ? collectorParam! : collectors;
        setCollectors(initCollectors);
      },
      (error) => {
        console.log("fetch collectors error:", error);
      },
      {});
  }, [])

  const handleFromDateChange = (date: any) => {
    if (date) {
      setDateFrom(date);
    }
  };
  const handleToDateChange = (date: any) => {
    if (date) {
      setDateTo(date);
    }
  };
  const handleCollectorChange = (event: ChangeEvent<{ name?: string | undefined; value: any; }>, child: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setCollectors(collectors.length === allCollectors.length ? [] : allCollectors);
      return;
    }
    setCollectors(value)
  }

  const startSearch = () => {
    if (prefix.length > 0 && dateFrom < dateTo) {
      let params: URLSearchParams = searchParams;
      params.set("dateFrom", String(dateFrom.getTime()));
      params.set("dateTo", String(dateTo.getTime()));
      params.set("prefix", prefix);
      if (allCollectors.length !== collectors.length) {
        params.set("collectors", JSON.stringify(collectors));
      } else {
        params.delete("collectors");
      }
      setSearchParams(params);

      dispatch(setPrefixDateFrom(dateFrom.getTime()));
      dispatch(setPrefixDateTo(dateTo.getTime()));
      dispatch(setPrefixCollectors(collectors));

      if (allCollectors.length === collectors.length) {
        dispatch(fetchPrefixData(prefix, sessionId, dateFrom.getTime(), dateTo.getTime()));
      } else {
        dispatch(fetchPrefixData(prefix, sessionId, dateFrom.getTime(), dateTo.getTime(), collectors));
      }

      asnInfoMap = new Map<number, AsnInfoData>();
    }
  }

  const handleSearchClick = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && !((event as React.KeyboardEvent).key === 'Enter')) {
      return;
    }

    if (!fetching_prefixPaths) {
      startSearch();
    } else {
      toggleDrawer(true)(event);
    }
  }

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    setDrawerOpen(open);
  };


  const generateProgressMatrix = () => {
    // window_size is set to 15 minutes on the server-side (TODO: add an API to fetch interval size dynamically)
    const windowSize = 900_000;
    const startTime = Math.floor(progressDateFrom / windowSize) * windowSize;
    const endTime = Math.ceil(progressDateTo / windowSize) * windowSize;
    const numColumns = (endTime - startTime) / windowSize;

    let headerIntervals = [];
    for (let i = 0; i < numColumns; i++) {
      const start = startTime + i * windowSize;
      const startDate = new Date(start);
      headerIntervals.push(<TableCell className={classes.denseTableCell}
                                      key={"interval" + start}><b>{startDate.toLocaleString()}</b></TableCell>);
    }

    let tableRows = [];
    for (let collector of progressCollectors) {
      let cells = [];
      cells.push(
        <TableCell className={classes.denseTableCell} key={collector + "_cell"}>
          {collector}
        </TableCell>
      )

      let intervals = progress.get(collector);

      for (let i = 0; i < numColumns; i++) {
        const start = startTime + i * windowSize;
        const iProgress: BGPProgress | undefined = intervals?.get(start);

        cells.push(
          <TableCell className={classes.denseTableCell} key={collector + "_cell_" + start}>
            {
              iProgress ?
                (iProgress.ok ?
                  <CheckCircleOutline style={{color: green[700]}}/>
                  : <ErrorOutline style={{color: red[700]}}/>)
                : <HourglassEmpty/>
            }
          </TableCell>
        );
      }

      tableRows.push(
        <TableRow key={collector + "_row"}>
          {cells}
        </TableRow>
      )
    }

    return <Table style={{marginTop: 20}}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.denseTableCell}>
            <b>Collector</b>
          </TableCell>
          {headerIntervals}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableRows}
      </TableBody>
    </Table>
  }

  let dateFromTime = dateFrom.getTime();
  let plusDate = new Date(dateFromTime);
  plusDate.setDate(dateFrom.getDate() + 1);

  const classes = useStyles();

  return (
    <>
      <div className={"query"}>
        <div style={{marginRight: 30, display: "flex", flexDirection: "row"}}>
          <TextField style={{boxSizing: "border-box"}} id="prefix" label="Enter IP Prefix" type="text"
                     defaultValue={prefix} InputProps={{className: classes.input}}
                     onChange={event => {
                       setPrefix(event.target.value)
                     }}
                     onKeyDown={handleSearchClick}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              style={{boxSizing: "border-box", position: "relative"}}
              variant="inline"
              ampm={false}
              label="From date"
              value={dateFrom}
              onChange={handleFromDateChange}
              onError={console.log}
              disableFuture={true}
              minDate={new Date("1999-01-01T00:00")}
              format="yyyy-MM-dd HH:mm"
              InputProps={{className: classes.input}}
            />
            <KeyboardDateTimePicker
              variant="inline"
              ampm={false}
              label="To date"
              value={dateTo}
              onChange={handleToDateChange}
              onError={console.log}
              disableFuture={true}
              minDate={dateFrom}
              maxDate={plusDate}
              format="yyyy-MM-dd HH:mm"
              InputProps={{className: classes.input}}
            />
          </MuiPickersUtilsProvider>
          <FormControl className={classes.selectCollectorForm} id="select-collector-form">
            {<InputLabel id="select-collector-label">Collectors</InputLabel>}
            <Select className={classes.input}
                    labelId="select-collector-label"
                    id="select-collector"
                    value={collectors}
                    renderValue={(val: any) => val.length === allCollectors.length ? "All" : val.join(', ')}
                    label="Collector"
                    multiple={true}
                    onChange={handleCollectorChange}
            >
              <MenuItem value="all">
                <Checkbox
                  checked={allCollectors.length > 0 && allCollectors.length === collectors.length}
                  indeterminate={collectors.length > 0 && collectors.length < allCollectors.length}
                />
                <ListItemText primary="Select All"/>
              </MenuItem>
              {allCollectors.map((collector) => {
                return <MenuItem key={collector} value={collector}>
                  <Checkbox checked={collectors.indexOf(collector) > -1}/>
                  <ListItemText primary={collector}/>
                </MenuItem>
              })}
            </Select>
          </FormControl>
          <div className={classes.buttonWrapper}>
            <IconButton
              aria-label="search"
              onClick={handleSearchClick}
              disabled={!(prefix.length > 0 && dateFrom < dateTo && collectors.length > 0)}
              className={classes.searchButton}
            >
              {fetching_prefixPaths ? <InfoOutlined/> : <SearchIcon/>}
            </IconButton>
            {fetching_prefixPaths && <CircularProgress size={48} className={classes.progressSpinner}/>}
            <Drawer anchor={"bottom"}
                    open={drawerOpen}
                    onClose={toggleDrawer(false)}
                    classes={{
                      paper: classes.progressDrawer
                    }}
            >
              <h1>Progress Matrix</h1>
              Visualization of BGP message parsing progress per collector in 15 minute intervals.
              {generateProgressMatrix()}
            </Drawer>
          </div>
        </div>

        {(colorState) &&
          <div style={{marginTop: 10, display: "flex", flexDirection: "row"}} className={"legend"}>
            <div style={{display: "flex", flexDirection: "column"}}>
              <div style={{display: "flex", flexDirection: "row"}}>
                <div
                  style={{position: "relative", display: "flex", flexDirection: "row", paddingRight: 20, width: 140}}>
                  <div style={{width: 15, height: 15, background: colorState.announce, display: "block", right: 20}}/>
                  <div style={{paddingLeft: 10, fontSize: 12}}>
                    Announcement
                  </div>
                </div>
                <div
                  style={{position: "relative", display: "flex", flexDirection: "row", paddingRight: 20, width: 140}}>
                  <div style={{width: 15, height: 15, background: colorState.withdraw, display: "block", right: 20}}/>
                  <div style={{paddingLeft: 10, fontSize: 12}}>
                    Withdrawal
                  </div>
                </div>
                <div
                  style={{position: "relative", display: "flex", flexDirection: "row", paddingRight: 20, width: 140}}>
                  <div style={{width: 15, height: 15, background: colorState.igp, display: "block", right: 20}}/>
                  <div style={{paddingLeft: 10, fontSize: 12}}>
                    IGP
                  </div>
                </div>
                <div
                  style={{position: "relative", display: "flex", flexDirection: "row", paddingRight: 20, width: 140}}>
                  <div style={{width: 15, height: 15, background: colorState.egp, display: "block", right: 20}}/>
                  <div style={{paddingLeft: 10, fontSize: 12}}>
                    EGP
                  </div>
                </div>
                <div
                  style={{position: "relative", display: "flex", flexDirection: "row", paddingRight: 20, width: 140}}>
                  <div style={{width: 15, height: 15, background: colorState.unknown, display: "block", right: 20}}/>
                  <div style={{paddingLeft: 10, fontSize: 12}}>
                    ?
                  </div>
                </div>
              </div>
              <div style={{display: "flex", flexDirection: "row"}}>
                <div
                  style={{position: "relative", display: "flex", flexDirection: "row", paddingRight: 20, width: 140}}>
                  <div style={{width: 15, height: 15, background: colorState.tier1, display: "block", right: 20}}/>
                  <div style={{paddingLeft: 10, fontSize: 12}}>
                    ASRank Top 30
                  </div>
                </div>
                <div
                  style={{position: "relative", display: "flex", flexDirection: "row", paddingRight: 20, width: 140}}>
                  <div style={{width: 15, height: 15, background: colorState.tier2, display: "block", right: 20}}/>
                  <div style={{paddingLeft: 10, fontSize: 12}}>
                    ASRank Top 200
                  </div>
                </div>
                <div
                  style={{position: "relative", display: "flex", flexDirection: "row", paddingRight: 20, width: 140}}>
                  <div style={{width: 15, height: 15, background: colorState.tier3, display: "block", right: 20}}/>
                  <div style={{paddingLeft: 10, fontSize: 12}}>
                    ASRank 200+
                  </div>
                </div>
                <div
                  style={{position: "relative", display: "flex", flexDirection: "row", paddingRight: 20, width: 140}}>
                  <div style={{width: 15, height: 15, background: colorState.add, display: "block", right: 20}}/>
                  <div style={{paddingLeft: 10, fontSize: 12}}>
                    Filter
                  </div>
                </div>
                <div
                  style={{position: "relative", display: "flex", flexDirection: "row", paddingRight: 20, width: 140}}>
                  <div style={{width: 15, height: 15, background: colorState.remove, display: "block", right: 20}}/>
                  <div style={{paddingLeft: 10, fontSize: 12}}>
                    Remove
                  </div>
                </div>
                <div
                  style={{position: "relative", display: "flex", flexDirection: "row", paddingRight: 20, width: 140}}>
                  <div style={{width: 15, height: 15, background: colorState.highlight, display: "block", right: 20}}/>
                  <div style={{paddingLeft: 10, fontSize: 12}}>
                    Highlight
                  </div>
                </div>
              </div>
            </div>
          </div>}

        {props.children}
      </div>
    </>
  )
}


export default QueryComponent
