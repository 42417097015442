import React, {FC, useEffect, useState} from "react";
import {BGPDataCollection} from "@pages/BGPDashboard/models/BGPDataCollection";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {delFilter, delHighlights} from "../../../store/bgpData/actions";
import {Chip} from "@material-ui/core";
import {FilterStatus} from "@pages/BGPDashboard/models/FilterStatus";
import {BGPData} from "@pages/BGPDashboard/models/BGPData";


const InteractionsComponent: FC = () => {

  const filter = useSelector((state:ApplicationState) => state.bgpData.filter);
  const dispatch = useDispatch();
  const [chips, setChips] = useState([])

  const handleDelete = (filter: FilterStatus) => {
    if(filter.filter == "highlight"){
      let val: any = filter.value;
      dispatch(delHighlights(val))
      dispatch(delFilter(filter))
    } else
    dispatch(delFilter(filter))
  };

  useEffect(() => {
    let tmp:any = [];
    if(filter){
      filter.map(d => {
        let chip = 
          <div key={"filterID:"+d.type+d.filter+d.value} style={{display:"inline-block"}}>
            <Chip style={{margin:2.5, background:d.color, }} label={d.name} onDelete={() => handleDelete(d)}/>
          </div>
        tmp.push(chip)
      })
    }
    setChips(tmp);
  }, [filter])


  return (

      <div className="layout-no-drag" style={{display: "flex", justifyContent: "center", flexDirection:"column", overflow:"auto", whiteSpace:"nowrap"}}>
        {chips}
      </div>

  );
}

export const filterData = (bgpData: BGPDataCollection | undefined, filterTimeFrom: number, filterTimeTo: number, filter: FilterStatus[] | undefined) => {
  if (bgpData?.collection) {

    let data = bgpData?.collection;
    if (filterTimeFrom != -1 && filterTimeTo != -1) {
      data = data.filter(d => d.bgpData.time * 1000 > filterTimeFrom && d.bgpData.time * 1000 < filterTimeTo)
    }
    if (filter && filter?.length > 0) {
      filter.map(d => {
        switch (d.type) {
          case "collector":
            if (d.filter == "add") {
              data = data?.filter(b => b.bgpData.collector === d.value)
            } else if (d.filter == "remove") {
              data = data?.filter(b => b.bgpData.collector != d.value)
            }
            break;
          case "type":
            let a = "";
            if (d.value === "announcement") a = "ANNOUNCEMENT"
            else a = "WITHDRAWAL"
            data = data?.filter(b => b.bgpData.type === a)
            break;
          case "origin":
            let c: any = "";
            if (d.value === "igp(i)") c = "IGP"
            else if (d.value === "egp(e)") c = "EGP"
            else c = null
            data = data?.filter(b => b.bgpData.origin === c)
            break;
          case "nextHop":
            if (d.filter == "add") {
              data = data?.filter(b => b.bgpData.nextHop === d.value)
            } else if (d.filter == "remove") {
              data = data?.filter(b => b.bgpData.nextHop != d.value)
            }
            break;
          case "duplicates":
            if (d.filter == "add") {
              data = data?.filter(b => b.bgpData.asPath.toString() == d.value.toString())
            } else if (d.filter == "remove") {
              data = data?.filter(b => b.bgpData.asPath.toString() != d.value.toString())
            }
            break;
          case "length":
            if (d.filter == "add") {
              data = data?.filter(b => b.bgpData.asPath.length == d.value)
            } else if (d.filter == "remove") {
              data = data?.filter(b => b.bgpData.asPath.length == d.value)
            }
            break;
          case "bucket":
            let tmp:any[] = [];
            tmp = tmp.concat(d.value)
            data = data?.filter(b => tmp.includes(b.id))
            break;
          case "map":
            let tmpData: BGPData[] = [];
            if (d.filter == "add") {
              if(Array.isArray(d.value))
                d.value.forEach(b => {
                 data?.map(e => {
                   if(e.bgpData.collector == b.collector && e.bgpData.time*1000 == b.timestamp && e.bgpData.asPathString == b.asPath.replace(/ /g, "")){
                     tmpData.push(e)
                   }
                 })
                })
              }
              data = tmpData;
            break;
          case "community":
            if(d.filter == "add"){
              data = data?.filter(data => {
                let community = data.bgpData.communities;
                if(community){
                  if(community.includes(d.name)){
                    return data
                  } else return;
                } else return;
              })
            } else if(d.filter == "remove"){
              data = data?.filter(data => {
                let community = data.bgpData.communities;
                if(community){
                  if(!(community.includes(d.name))){
                    return data
                  } else return ;
                } else return data;
              })
            }

            break;
          case "element":
            if (d.filter === "add")
              data = data.filter(b => b.id == d.value)
            else if (d.filter === "remove") data = data.filter(b => !(b.id == d.value))
            break;
        }
      })
    }
    return data;
  } else return;
}

export default InteractionsComponent;
