import {DemoActions, DemoActionTypes, DemoState} from './types';
import {StoreUtils} from '@iva/store-utils';

// Initial state
const initialState: DemoState = {
  selectedTimezone1: "",
  selectedTimezone2: "",
  data: undefined,
  fetching: false,
  fetchError: undefined,
  time: StoreUtils.getFetchState_init(),
};

// The reducer function
export function demoReducer(state = initialState, action: DemoActions): DemoState {
  switch (action.type) {

    // Simple action, unrelated to REST interaction
    case DemoActionTypes.SET_TIMEZONE1: {
      return { ...state, selectedTimezone1: action.payload };
    }
    case DemoActionTypes.SET_TIMEZONE2: {
      return { ...state, selectedTimezone2: action.payload };
    }

    // Manual handling of fetching resources
    case DemoActionTypes.FETCH_REQUEST: {
      return { ...state, fetching: true };
    }
    case DemoActionTypes.FETCH_SUCCESS: {
      return { ...state, fetching: false, fetchError: undefined, data: action.payload };
    }
    case DemoActionTypes.FETCH_ERROR: {
      return { ...state, fetching: false, fetchError: action.payload, data: undefined };
    }

    // Managed handling of fetching resources
    case DemoActionTypes.FETCH_MANAGED: {
      return { ...state, time: StoreUtils.getFetchState(action) };
    }

    default: {
      return state;
    }
  }
};
