import {FilterActions, FilterActionTypes, FilterState} from './types';

// Initial state
const initialState: FilterState = {
  filterTimeFrom: -1,
  filterTimeTo: -1,

};

// The reducer function
export function filterReducer(state = initialState, action: FilterActions): FilterState {
  switch (action.type) {

    // Simple action, unrelated to REST interaction
    case FilterActionTypes.SET_FILTER_FROM: {
      return { ...state, filterTimeFrom: action.payload };
    }
    case FilterActionTypes.SET_FILTER_TO: {
      return { ...state, filterTimeTo: action.payload };
    }

    default: {
      return state;
    }
  }
};
