import {FC, forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {DonutChart} from "./Vis/DonutChart";
import {BGPDataCollection} from "@pages/BGPDashboard/models/BGPDataCollection";
import {filterData} from "@pages/BGPDashboard/InteractionsComponent";
import {FilterStatus} from "@pages/BGPDashboard/models/FilterStatus";

const OriginComponent = forwardRef((props, ref) => {

  const bgpData = useSelector((state: ApplicationState) => state.bgpData.rawData);
  const refContainer = useRef(null);
  const [chart, setChart] = useState(null);
  const IGPType = "IGP";
  const EGPType = "EGP";
  const incompleteType = null;
  const filterTimeFrom = useSelector((state: ApplicationState) => state.filter.filterTimeFrom);
  const filterTimeTo = useSelector((state: ApplicationState) => state.filter.filterTimeTo);
  const filterState = useSelector((state:ApplicationState) => state.bgpData.filter);
  const filterID = useSelector((state:ApplicationState) => state.bgpData.highlightData);
  const colorState = useSelector((state: ApplicationState) => state.bgpData.colorState);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    updateDimensions() {
      // @ts-ignore
      chart?.updateDimensions();
    }
  }));

  useEffect(() => {
    // runs on destruct/unmount
    return () => {
      // @ts-ignore
      if (chart?.cleanUp) chart?.cleanUp();
    }
  }, [chart])

  const updateChart = (chart: DonutChart | null, bgpData: BGPDataCollection | undefined, filterTimeFrom: number, filterTimeTo: number, filter: FilterStatus[] | undefined, highlights: number[] | undefined) => {
    if (bgpData?.collection && chart) {

      let data = filterData(bgpData, filterTimeFrom, filterTimeTo, filter);

      if (data) {
        if (filterTimeFrom != -1 && filterTimeTo != -1) {
          data = data.filter(d => d.bgpData.time * 1000 > filterTimeFrom && d.bgpData.time * 1000 < filterTimeTo)
        }
        const donutData = [{label: "igp(i)", value: 0}, {label: "egp(e)", value: 0}, {label: "incomplete(?)", value: 0}];
        data.map(e => {
          if (e.bgpData.origin === IGPType) donutData[0].value += 1;
          else if (e.bgpData.origin === EGPType) donutData[1].value += 1;
          else if (e.bgpData.origin === incompleteType) donutData[2].value += 1;
        })

        chart.updateData(donutData, "origin", dispatch, colorState);
      }
    }
  }

    useEffect(() => {
      const initializeChart = ({setChart, refContainer}: any) => {
        const chart = new DonutChart(refContainer.current);
        setChart(chart);
        if (bgpData) {
          updateChart(chart, bgpData, filterTimeFrom, filterTimeTo, filterState, filterID);
        }
      };
      if (!chart) initializeChart({setChart, refContainer});
    }, [chart]);

    useEffect(() => {
      if(chart != null){
        updateChart(chart, bgpData, filterTimeFrom, filterTimeTo, filterState, filterID);
      }
    },[bgpData,filterTimeFrom, filterTimeTo, filterState, filterID]);


  return (<>
    <div ref={refContainer} style={{height: "100%", width: "100%"}}>
    </div>
    </>);
});

export default OriginComponent;
