import React, { FC } from 'react';
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Modal, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router';
// @ts-ignore
import routesImg from '../../../assets/routesIMC.png';
// @ts-ignore
import asImage from '../../../assets/asIMC.png';
// @ts-ignore
import geoTraceImage from '../../../assets/geoTraceImage.png';
// @ts-ignore
import bgpDashboard from '../../../assets/bgp_dashboard.jpg';
import GeoTraceLearnMore from '@pages/GeoTraceLearnMore';
import { Routes } from 'config/Routes';


const HomePage: FC = () => {

  const navigate = useNavigate();

  const [openGeoTrace, setOpenGeoTrace] = React.useState(false);
  const handleOpenGeoTraceModal = () => setOpenGeoTrace(true);
  const handleCloseGeoTraceModal = () => setOpenGeoTrace(false);

  return (
    <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', height:"90vh"}}>

      <Card style={{ width: "40%"}}>
        <Typography variant="h2" align={"center"}>Border Gateway Protocol</Typography>
        <CardActionArea onClick={() => navigate(Routes.Analysis, { replace: true })}>
          <CardMedia
            component='img'
            image={bgpDashboard}
            alt='geo trace'
          />
          <CardContent>
            <Typography gutterBottom variant='h5' component='div' color='primary'>
              BGP Analysis
            </Typography>
            <Typography variant='body2'>
              This prototype bases on ProBGP and is implemented with visual analytics for the analysis of BGP data. Different kinds of visualizations and interactions help experience users to explore
              BGP updates for a specific prefix and gain knowledge. On the "BGP Analysis" tab you can try it out and enter a prefix and a time frame.
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card style={{ width: "40%"}}>
        <Typography variant="h2" align={"center"}>Geographic Traceroute</Typography>
        <CardActionArea onClick={() => navigate(Routes.Map, { replace: true })}>
          <CardMedia
            component='img'
            image={geoTraceImage}
            alt='geo trace'
          />
          <CardContent>
            <Typography gutterBottom variant='h5' component='div' color='primary'>
              GeoTrace
            </Typography>
            <Typography variant='body2'>
              Geographic Approximation of Autonomous System Data Centers.
              Visualize traceroutes or BGP AS paths with a novel approximation algorithm
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size='small' color='primary' onClick={handleOpenGeoTraceModal}>
            Learn more
          </Button>
        </CardActions>
      </Card>
      <Modal
        open={openGeoTrace}
        onClose={handleCloseGeoTraceModal}
      >
        <Box style={{marginTop: '5%', marginLeft: '10%', width: '80%'}}>
          <Box style={{
            border: '2px solid #000',
            backgroundColor: '#4F4F4F',
            padding: 4,}}>
            <GeoTraceLearnMore/>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default HomePage;
