import {action} from '@iva/store-utils';
import {FilterActionTypes, FilterFromAction, FilterToAction,} from './types';

// Simple actions without network interaction
export function setFilterFrom(time: number): FilterFromAction{
    return action(FilterActionTypes.SET_FILTER_FROM, time);
}

export function setFilterTo(time: number): FilterToAction{
    return action(FilterActionTypes.SET_FILTER_TO, time);
}
