import React, { FC } from 'react';
import {Route, Routes as RouterRoutes} from 'react-router-dom';

import HomePage from '@pages/HomePage';
import ImprintPage from '@pages/TemplatePages/ImprintPage';
import DSGVOPage from '@pages/TemplatePages/DSGVOPage';
import NotFoundPage from '@pages/TemplatePages/NotFoundPage';

import { Routes } from 'config/Routes';

import DashboardComponent from "@pages/BGPDashboard/DashboardComponent";
import MapComponentIMC from '@pages/Map/MapComponentIMC';


const Router: FC = () => (
  <RouterRoutes>
    <Route path={Routes.Root} element={<HomePage/>} />
    <Route path={Routes.Analysis} element={<DashboardComponent/>} />
    <Route path={Routes.Map} element={<MapComponentIMC/>} />
    <Route path={Routes.Imprint} element={<ImprintPage/>} />
    <Route path={Routes.Dsgvo} element={<DSGVOPage/>} />
    <Route path="*" element={<NotFoundPage/>} />
  </RouterRoutes>
);

export default Router;
