import {Dispatch} from "redux";
import {DnsCenterActionTypes} from "./types";
import {action} from "@iva/store-utils";
import {RestServiceUtils} from "@iva/rest-service-utils";


export function fetchDnsCenterData(date: string) {
  return (dispatch: Dispatch) => {
    dispatch(action(DnsCenterActionTypes.FETCH_DNSCENTER_REQUEST, undefined));

    RestServiceUtils.jsonGET('/api/dnsCenterData/' + date,
      result => dispatch(action(DnsCenterActionTypes.FETCH_DNSCENTER_SUCCESS, result)),
      error => dispatch(action(DnsCenterActionTypes.FETCH_DNSCENTER_ERROR, error.message))
    );
  }
}

export function fetchDnsCenterDataAS(asn : number, date : string) {
  return (dispatch: Dispatch) => {
    // Dispatch action that fetching data has begun
    dispatch(action(DnsCenterActionTypes.FETCH_DNSCENTER_AS_REQUEST, undefined));

    // Call endpoint, dispatch success- or error-message, depending on server response
    // Submit the GET-request, expecting plain text as response type
    RestServiceUtils.jsonGET('/api/dnsCenterData/'+asn+"/"+date,
      result => dispatch(action(DnsCenterActionTypes.FETCH_DNSCENTER_AS_SUCCESS, result)),
      error => dispatch(action(DnsCenterActionTypes.FETCH_DNSCENTER_ERROR, error.message))
    );
  }
}
