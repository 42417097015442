import {FC, forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import React from "react";
import {BGPDataCollection} from "@pages/BGPDashboard/models/BGPDataCollection";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {AsnInfoData} from "../../../store/asnInfo/types";
import {TierPatternChart} from "./Vis/TierPatternChart";
import {filterData} from "@pages/BGPDashboard/InteractionsComponent";
import {FilterStatus} from "@pages/BGPDashboard/models/FilterStatus";


const TierPatternComponent = forwardRef((props, ref) => {

  const asRankData = useSelector((state: ApplicationState) => state.asRank.asRankData);
  const refContainer = useRef(null);
  const [chart, setChart] = useState(null);
  const filterState = useSelector((state: ApplicationState) => state.bgpData.filter);
  const bgpData = useSelector((state: ApplicationState) => state.bgpData.rawData);
  const filterTimeFrom = useSelector((state: ApplicationState) => state.filter.filterTimeFrom);
  const filterTimeTo = useSelector((state: ApplicationState) => state.filter.filterTimeTo);
  const asnInfoData = useSelector((state: ApplicationState) => state.asnInfo.asnInfoData);
  const asnInfoFetching = useSelector((state: ApplicationState) => state.asnInfo.fetching);
  const filterID = useSelector((state:ApplicationState) => state.bgpData.highlightData);
  const colorState = useSelector((state: ApplicationState) => state.bgpData.colorState);
  let asnInfoMap = new Map<number, AsnInfoData>();
  const [update, forceUpdate] = useState(0);


  useImperativeHandle(ref, () => ({
    updateDimensions() {
      // @ts-ignore
      chart?.updateDimensions();
    }
  }));

  useEffect(() => {
    // runs on destruct/unmount
    return () => {
      // @ts-ignore
      if (chart?.cleanUp) chart?.cleanUp();
    }
  }, [chart])

  const updatePathChart = (chart: TierPatternChart | null, bgpData: BGPDataCollection | undefined, filterTimeFrom: number, filterTimeTo: number,
                           filter: FilterStatus[] | undefined, highlights: number[] | undefined) => {
    if (bgpData?.collection && chart) {
      let nodeHeight = 0;
      let pathData:any = [];
      let chartData = filterData(bgpData, filterTimeFrom, filterTimeTo, filter);

      if(chartData) {
        chartData = chartData.filter(b => b.bgpData.type == "ANNOUNCEMENT") || [];
        const finalData: any = {nodes: [], links: []};
        chartData.map(d => {
          let path = d.bgpData.asPath;
          if (path && chart) {
            let tierData: any = [];
            let data = path.toString().split(",")
            data = [...new Set(data)];

            if (data) {
              data.forEach(function (as: string, idx: number, array: string[]) {
                let appendix = " - ";
                let tier = 3;
                // @ts-ignore
                if (asRankData && asRankData[as]) {
                  // @ts-ignore
                  tier = asRankData[as].tier;
                }
                const asElement = {
                  as: array[idx],
                  tier: tier,
                }
                tierData.push(asElement);
              })

              tierData.forEach(function (as: { as: string, tier: number }, idx: number, array: { as: string, tier: number }[]) {
                if ((idx + 1 != array.length) && (array[idx].as != array[idx + 1].as)) {
                  let linkData = {source: (idx + 1) + ".T" + as.tier, target: (idx + 2) + ".T" + array[idx + 1].tier};
                  if (!(finalData.links.some((e: { id: number, source: string, target: string }) => {
                    return ((linkData.source === e.source) && (linkData.target === e.target));
                  }))) {
                    const finalElement = {
                      source: (idx + 1) + ".T" + as.tier,
                      target: idx + 2 + ".T" + array[idx + 1].tier,
                      value: 1,
                      id: [d.id],
                      init: false,
                      count: 1,
                      highlight: false
                    }
                    if(highlights){
                      if(highlights.includes(d.id)) finalElement.highlight = true;
                    }
                    finalData.links.push(finalElement)
                    pathData.push(finalElement)
                  } else {
                    const increaseValue = finalData.links.find((e: { id: number, source: string, target: string }) => {
                      return ((linkData.source === e.source) && (linkData.target === e.target));
                    })
                    if (increaseValue != null) {
                      increaseValue.id.push(d.id);
                      if(highlights){
                        if(highlights.includes(d.id)) increaseValue.highlight = true;
                      }
                      increaseValue.count = increaseValue.count + 1;
                      if(increaseValue.count > nodeHeight){
                        nodeHeight = increaseValue.count;
                      }
                    }
                  }
                }
              })
            }
          }
        })

        let c = 0;
        let tiers: string[] = [];
        finalData.links.map((d: any) => {
          tiers.push(d.source);
          tiers.push(d.target);
          if (parseInt(d.target.split(".", 1)[0]) > c) {
            c = parseInt(d.target.split(".", 1)[0]);
          }
        })
        let s1 = "1.T1";
        let s2 = "1.T2";
        let s3 = "1.T3";
        finalData.nodes.push({name: s1, count:0});
        finalData.nodes.push({name: s2, count:0});
        finalData.nodes.push({name: s3, count:0});

        for (let i = 2; i <= c; i++) {
          let t1 = i + ".T1";
          let t2 = i + ".T2";
          let t3 = i + ".T3";
          finalData.nodes.push({name: t1, count: 0});
          finalData.nodes.push({name: t2, count: 0});
          finalData.nodes.push({name: t3, count: 0});
        }

        let forward = "";
          for(let h=1; h<=c; h++){
            for(let j=1; j<=3; j++){
              for(let k=1; k<=3; k++) {
                if (finalData.links.some((f: {source:string, target:string, value: number, init: boolean}) => {
                  return f.source == h + ".T" + j && f.target == h+1 + ".T" + k
                })) forward = ""
                else if(h < c) finalData.links.push({source: h + ".T"+j, target: h+1 + ".T"+k, value:1, init:true})
                }
              }
            }

        finalData.links.map((d:any) => {
          if(!d.init) {
            const sourceNode = finalData.nodes.find((f: { name: string }) => d.source == f.name)
            sourceNode.count = sourceNode.count + d.count;
          }
        })

        chart.updateData(finalData, colorState);
      }
    }
  }

  useEffect(() => {
    if(asnInfoData){
      asnInfoMap.set(asnInfoData.asn, asnInfoData)
      forceUpdate(update+1);
    }
  }, [asnInfoData]);

  useEffect(() => {
    const initializeChart = ({setChart, refContainer}: any) => {
      const chart = new TierPatternChart(refContainer.current);
      setChart(chart);
      if (bgpData) {
        updatePathChart(chart, bgpData, filterTimeFrom, filterTimeTo, filterState, filterID);
      }
    };
    if (!chart) initializeChart({setChart, refContainer});
  }, [chart]);


  useEffect(() => {
    if (chart != null) {
      updatePathChart(chart, bgpData, filterTimeFrom, filterTimeTo, filterState, filterID)
    }
  }, [bgpData, filterTimeFrom, filterTimeTo, filterState, filterState, filterID])


  return(<>
    <div style={{width: "100%", height: "100%"}} ref={refContainer}/>
  </>);
});


export default TierPatternComponent;
