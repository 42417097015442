import * as d3 from "d3";


export abstract class SVGChart {

  private rootNode!: HTMLElement;
  public svgElement!: SVGSVGElement;
  private _width: number;
  private _height: number;

  public minWidth!: number;
  public minHeight!: number;

  protected constructor(node: HTMLElement, minWidth: number = 0, minHeight: number = 0) {
    this.minHeight = minHeight;
    this.minWidth = minWidth;

    // * add 5pt padding for svg container
    // * add another div we control
    //   -> should have no padding so that clientWidth/clientHeight are accurate
    this.rootNode = d3.select(node)
      .style("padding", "5pt")
      .append('div')
      .style('width', '100%')
      .style('height', '100%')
      .node()!;

    let wrapper = d3.select(this.rootNode)
      .append('svg')
      .attr("class", "wrapper")
      .style('width', '100%')
      .style('height', '100%')
      .style('display', 'block')
      .node();

    if (wrapper != null) {
      this.svgElement = wrapper;
    } else {
      throw new Error("Newly created SVG Element does not exist");
    }

    this._width = this.rootNode.clientWidth;
    this._height = this.rootNode.clientHeight;
  }

  public get height(): number {
    return this._height;
  }

  public get width(): number {
    return this._width;
  }

  updateDimensions(){
    this._updateDimensions();
    // after resizing, a scrollbar can appear, making another resize necessary
    this._updateDimensions();
  }

  _updateDimensions() {
    let outerWidth = this.rootNode.clientWidth;
    let outerHeight = this.rootNode.clientHeight;

    // make svg mismatch parent size for minimal width
    let width = Math.max(this.minWidth, outerWidth);
    let height = Math.max(this.minHeight, outerHeight);

    if (this.width != width || this.height != height) {
      this._width = width;
      this._height = height;

      d3.select(this.svgElement)
        .style('width', this.width + "px")
        .style('height', this.height + "px");

      this.resizeChart();
    }
  }

  cleanUp(){}

  abstract resizeChart(): void;
}
