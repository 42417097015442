import React, { FC } from 'react';
import AppConfig from 'config/App';
import Gdpr from '@iva/gdpr-component';
import { Box } from '@material-ui/core';

const DSGVOPage: FC = () => {
    return (
        <Box paddingLeft="5vw" paddingRight="5vw">
            <Gdpr {...AppConfig.gdpr} />
        </Box>
    )
}

export default DSGVOPage;
