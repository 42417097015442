import {GeoDataState, GeoDataActionTypes, GeoDataActions, ClearTraceSummary} from './types';
import { StoreUtils } from '@iva/store-utils';
import {Feature, FeatureCollection} from "geojson";

// Initial state
const initialState: GeoDataState = {
  allDataCenters: undefined,
  fetching_allDataCenters: false,
  geoIpData: undefined,
  prefixDateFrom : -1,
  prefixDateTo : -1,
  prefixData: undefined,
  centerConnectionsData: undefined,
  data_raw: undefined,
  data_clusters: undefined,
  fetching_raw: false,
  fetching_centers: false,
  fetching_connections: false,
  fetching_prefixPaths: false,
  fetching_geoip: false,
  fetchError: undefined,
  fetchingTrace: false,
  traceData: undefined,
  traceSummary: undefined,
};



// The reducer function
export function geoDataReducer(state = initialState, action: GeoDataActions): GeoDataState {
  switch (action.type) {

    // Manual handling of fetching resources
    case GeoDataActionTypes.SET_PREFIX_DATE_FROM: {
      return { ...state, prefixDateFrom: action.payload };
    }
    case GeoDataActionTypes.SET_PREFIX_DATE_TO: {
      return { ...state, prefixDateTo: action.payload };
    }
    case GeoDataActionTypes.FETCH_PREFIX_DATA_REQUEST: {
      return { ...state, fetching_prefixPaths: true, prefixData: undefined };
    }
    case GeoDataActionTypes.FETCH_PREFIX_DATA_FINISH: {
      return { ...state, fetching_prefixPaths: false };
    }
    case GeoDataActionTypes.FETCH_PREFIX_DATA_SUCCESS: {
      let featureCollection : FeatureCollection = {type: "FeatureCollection", features: []};
      if(state.prefixData == undefined){
        featureCollection.features.push(action.payload as Feature);
      }
      else{
        featureCollection.features = featureCollection.features.concat(state.prefixData.features);
        featureCollection.features.push(action.payload as Feature);
      }
      return { ...state, prefixData: featureCollection };
    }
    case GeoDataActionTypes.FETCH_PREFIX_DATA_BATCH_SUCCESS: {
      let featureCollection : FeatureCollection = {type: "FeatureCollection", features: []};

      featureCollection.features = featureCollection.features.concat(action.payload as Array<Feature>);
      if(state.prefixData != undefined){
        featureCollection.features = featureCollection.features.concat(state.prefixData.features);
      }
      return { ...state, prefixData: featureCollection };
    }
    case GeoDataActionTypes.FETCH_CENTERCONNECTIONS_REQUEST: {
      return { ...state, fetching_connections: true };
    }
    case GeoDataActionTypes.FETCH_CENTERCONNECTIONS_SUCCESS: {
      return { ...state, fetching_connections: false, fetchError: undefined, centerConnectionsData: action.payload };
    }
    case GeoDataActionTypes.FETCH_RAW_REQUEST: {
      return { ...state, fetching_raw: true };
    }
    case GeoDataActionTypes.FETCH_RAW_SUCCESS: {
      return { ...state, fetching_raw: false, fetchError: undefined, data_raw: action.payload };
    }
    case GeoDataActionTypes.FETCH_CENTERS_REQUEST: {
      return { ...state, fetching_centers: true };
    }
    case GeoDataActionTypes.FETCH_CENTERS_SUCCESS: {
      return {...state, fetching_centers: false, fetchError: undefined, data_clusters: action.payload}
    }
    case GeoDataActionTypes.FETCH_GEOIP_REQUEST: {
        return { ...state, fetching_geoip: true };
    }
    case GeoDataActionTypes.FETCH_GEOIP_SUCCESS: {
        return { ...state, fetching_geoip: false, fetchError: undefined, geoIpData: action.payload };
    }
    case GeoDataActionTypes.FETCH_ALLDATACENTERS_REQUEST: {
      return { ...state, fetching_allDataCenters: true };
    }
    case GeoDataActionTypes.FETCH_ALLDATACENTERS_SUCCESS: {
      return { ...state, fetching_allDataCenters: false, fetchError: undefined, allDataCenters: action.payload };
    }
    case GeoDataActionTypes.FETCH_TRACE_DATA_REQUEST: {
      return { ...state, fetchingTrace: true };
    }
    case GeoDataActionTypes.FETCH_TRACE_DATA_SUCCESS: {
      let featureCollectionPath : FeatureCollection = {type: "FeatureCollection", features: []};
      featureCollectionPath.features = featureCollectionPath.features.concat(action.payload.tracePath);

      let featureCollectionSummary : FeatureCollection = {type: "FeatureCollection", features: []};
      featureCollectionSummary.features = featureCollectionSummary.features.concat(action.payload.traceSummary);

      return { ...state, fetchingTrace: false, fetchError: undefined, traceData: featureCollectionPath, traceSummary: featureCollectionSummary };
    }
    case GeoDataActionTypes.FETCH_ERROR: {
      //TODO check which one errored and set fields
      return { ...state, fetchingTrace: false, fetching_raw: false, fetching_prefixPaths: false, fetching_connections: false, fetching_centers: false,fetchError: action.payload };
    }
    case GeoDataActionTypes.CLEAR_TRACE_DATA: {
      return { ...state, traceData: undefined };
    }
    case GeoDataActionTypes.CLEAR_TRACESUMMARY: {
      return { ...state, traceSummary: undefined};
    }
    case GeoDataActionTypes.CLEAR_CENTERCONNECTIONS: {
      return { ...state, centerConnectionsData: undefined};
    }
    default: {
      return state;
    }
  }
}
