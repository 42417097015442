import { action } from '@iva/store-utils';
import {
  GeoDataActions,
  GeoDataActionTypes,
} from './types';

import { RestServiceUtils } from '@iva/rest-service-utils';
import { Dispatch } from 'redux';

export function setPrefixDateFrom(dateFrom: number): GeoDataActions{
  return action(GeoDataActionTypes.SET_PREFIX_DATE_FROM, dateFrom);
}

export function setPrefixDateTo(dateTo: number): GeoDataActions{
  return action(GeoDataActionTypes.SET_PREFIX_DATE_TO, dateTo);
}

export function clearTraceData(): GeoDataActions{
  return action(GeoDataActionTypes.CLEAR_TRACE_DATA, undefined);
}

export function clearTraceSummary(): GeoDataActions{
  return action(GeoDataActionTypes.CLEAR_TRACESUMMARY, undefined);
}

export function clearCenterConnections(): GeoDataActions{
  return action(GeoDataActionTypes.CLEAR_CENTERCONNECTIONS, undefined);
}

export function fetchGeoIpData(date : string) {
  return (dispatch: Dispatch) => {
    // Dispatch action that fetching data has begun
    dispatch(action(GeoDataActionTypes.FETCH_GEOIP_REQUEST, undefined));

    // Call endpoint, dispatch success- or error-message, depending on server response
    // Submit the GET-request, expecting plain text as response type
    RestServiceUtils.jsonGET('/api/geoipdata/'+date,
      result => dispatch(action(GeoDataActionTypes.FETCH_GEOIP_SUCCESS, result)),
      error => dispatch(action(GeoDataActionTypes.FETCH_ERROR, error.message))
    );
  }
}

export function fetchAllDataCenters(date : string) {
  return (dispatch: Dispatch) => {
    // Dispatch action that fetching data has begun
    dispatch(action(GeoDataActionTypes.FETCH_ALLDATACENTERS_REQUEST, undefined));

    // Call endpoint, dispatch success- or error-message, depending on server response
    // Submit the GET-request, expecting plain text as response type
    RestServiceUtils.jsonGET('/api/allDataCenters/'+date,
      result => dispatch(action(GeoDataActionTypes.FETCH_ALLDATACENTERS_SUCCESS, result)),
      error => dispatch(action(GeoDataActionTypes.FETCH_ERROR, error.message))
    );
  }
}

export function fetchAsnDataCentersV2(asn : number, date : string) {
  return (dispatch: Dispatch) => {
    // Dispatch action that fetching data has begun
    dispatch(action(GeoDataActionTypes.FETCH_CENTERS_REQUEST, undefined));

    // Call endpoint, dispatch success- or error-message, depending on server response
    // Submit the GET-request, expecting plain text as response type
    RestServiceUtils.jsonGET('/api/asnCenterV2/'+asn+"/"+date,
      result => dispatch(action(GeoDataActionTypes.FETCH_CENTERS_SUCCESS, result)),
      error => dispatch(action(GeoDataActionTypes.FETCH_ERROR, error.message))
    );
  }
}

export function fetchAsnDataCentersMinBlockSize(minBlockSize : number, date : string) {
  return (dispatch: Dispatch) => {
    // Dispatch action that fetching data has begun
    dispatch(action(GeoDataActionTypes.FETCH_CENTERS_REQUEST, undefined));

    // Call endpoint, dispatch success- or error-message, depending on server response
    // Submit the GET-request, expecting plain text as response type
    RestServiceUtils.jsonGET('/api/asnCenterMinBlock/'+minBlockSize+"/"+date,
      result => dispatch(action(GeoDataActionTypes.FETCH_CENTERS_SUCCESS, result)),
      error => dispatch(action(GeoDataActionTypes.FETCH_ERROR, error.message))
    );
  }
}

// Example for fetching a resource via RestServiceUtils
export function fetchAsnDataCentersRaw(asn : number, date : string) {
    return (dispatch: Dispatch) => {
        // Dispatch action that fetching data has begun
        dispatch(action(GeoDataActionTypes.FETCH_RAW_REQUEST, undefined));

        // Call endpoint, dispatch success- or error-message, depending on server response
        // Submit the GET-request, expecting plain text as response type
        RestServiceUtils.jsonGET('/api/asnRaw/'+asn+"/"+date,
            result => dispatch(action(GeoDataActionTypes.FETCH_RAW_SUCCESS, result)),
            error => dispatch(action(GeoDataActionTypes.FETCH_ERROR, error.message))
        );
    }
}
export function fetchAsnDataCenters(asn : number, date : string) {
  return (dispatch: Dispatch) => {
    // Dispatch action that fetching data has begun
    dispatch(action(GeoDataActionTypes.FETCH_CENTERS_REQUEST, undefined));

    // Call endpoint, dispatch success- or error-message, depending on server response
    // Submit the GET-request, expecting plain text as response type
    RestServiceUtils.jsonGET('/api/asnCenter/'+asn+"/"+date,
      result => dispatch(action(GeoDataActionTypes.FETCH_CENTERS_SUCCESS, result)),
      error => dispatch(action(GeoDataActionTypes.FETCH_ERROR, error.message))
    );
  }
}

export function fetchAsnDataCentersConnections(asn : number, date : string) {
  return (dispatch: Dispatch) => {
    // Dispatch action that fetching data has begun
    dispatch(action(GeoDataActionTypes.FETCH_CENTERCONNECTIONS_REQUEST, undefined));

    // Call endpoint, dispatch success- or error-message, depending on server response
    // Submit the GET-request, expecting plain text as response type
    RestServiceUtils.jsonGET('/api/asnCenterConnections/'+asn+"/"+date,
      result => dispatch(action(GeoDataActionTypes.FETCH_CENTERCONNECTIONS_SUCCESS, result)),
      error => dispatch(action(GeoDataActionTypes.FETCH_ERROR, error.message))
    );
  }
}

export function fetchPrefixData(prefix : string, sessionId : string, dateFrom : number, dateTo : number) {
  return (dispatch: Dispatch) => {
    // Dispatch action that fetching data has begun
    dispatch(action(GeoDataActionTypes.FETCH_PREFIX_DATA_REQUEST, undefined));

    // Call endpoint, dispatch success- or error-message, depending on server response
    // Submit the GET-request, expecting plain text as response type
    RestServiceUtils.jsonPOST('/api/prefix', {prefix: prefix, sessionId: sessionId, dateFrom: dateFrom, dateTo: dateTo},
      result => {},
      error => dispatch(action(GeoDataActionTypes.FETCH_ERROR, error.message))
    );
  }
}

export function fetchTracePathData(trace : {as: string[], ip:string[], domain:string[], rtt:string[][]}, date : string, evalMode : boolean) {
  return (dispatch: Dispatch) => {
    // Dispatch action that fetching data has begun
    dispatch(action(GeoDataActionTypes.FETCH_TRACE_DATA_REQUEST, undefined));

    // Call endpoint, dispatch success- or error-message, depending on server response
    // Submit the GET-request, expecting plain text as response type
    if(evalMode) {
      RestServiceUtils.jsonPOST('/api/evalTrace/' + date, {asList: trace.as, ipList: trace.ip, domainList: trace.domain, rttList: trace.rtt},
        result => dispatch(action(GeoDataActionTypes.FETCH_TRACE_DATA_SUCCESS, result)),
        error => dispatch(action(GeoDataActionTypes.FETCH_ERROR, error.message))
      );
    } else {
      RestServiceUtils.jsonPOST('/api/trace/' + date, {asList: trace.as, ipList: trace.ip, domainList: trace.domain, rttList: trace.rtt},
        result => dispatch(action(GeoDataActionTypes.FETCH_TRACE_DATA_SUCCESS, result)),
        error => dispatch(action(GeoDataActionTypes.FETCH_ERROR, error.message))
      );
    }
  }
}
export function fetchBGPTracePathData(trace : {startIP: string, endIP:string, asPath: string[]}, date : string) {
  return (dispatch: Dispatch) => {
    // Dispatch action that fetching data has begun
    dispatch(action(GeoDataActionTypes.FETCH_TRACE_DATA_REQUEST, undefined));

    // Call endpoint, dispatch success- or error-message, depending on server response
    // Submit the GET-request, expecting plain text as response type
    RestServiceUtils.jsonPOST('/api/BGPtrace/'+date, {startIP: trace.startIP, endIP: trace.endIP, asPath: trace.asPath},
      result => dispatch(action(GeoDataActionTypes.FETCH_TRACE_DATA_SUCCESS, result)),
      error => dispatch(action(GeoDataActionTypes.FETCH_ERROR, error.message))
    );
  }
}
