import React, {FC, forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {DuplicatesBarChart} from "../BGPDashboard/Vis/DuplicatesBarChart";
import {BGPDataCollection} from "@pages/BGPDashboard/models/BGPDataCollection";
import {filterData} from "@pages/BGPDashboard/InteractionsComponent";
import {FilterStatus} from "@pages/BGPDashboard/models/FilterStatus";

const DuplicatesComponent = forwardRef((props, ref) => {

  const refContainer = useRef(null);
  const [chart, setChart] = useState(null);
  const bgpData = useSelector((state: ApplicationState) => state.bgpData.rawData);
  const filterTimeFrom = useSelector((state: ApplicationState) => state.filter.filterTimeFrom);
  const filterTimeTo = useSelector((state: ApplicationState) => state.filter.filterTimeTo);
  const filterState = useSelector((state:ApplicationState) => state.bgpData.filter);
  const filterID = useSelector((state:ApplicationState) => state.bgpData.highlightData);
  const colorState = useSelector((state: ApplicationState) => state.bgpData.colorState);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    updateDimensions() {
      // @ts-ignore
      chart?.updateDimensions();
    }
  }));

  useEffect(() => {
    // runs on destruct/unmount
    return () => {
      // @ts-ignore
      if (chart?.cleanUp) chart?.cleanUp();
    }
  }, [chart])

  const updateChart = (chart: DuplicatesBarChart | null, bgpData: BGPDataCollection | undefined, filterTimeFrom: number, filterTimeTo: number, filter: FilterStatus[] | undefined, highlights: number[] | undefined) => {
    if (bgpData?.collection && chart) {
      let chartData = filterData(bgpData, filterTimeFrom, filterTimeTo, filter)
      if(chartData) {
        chartData = chartData.filter(d => d.bgpData.type == "ANNOUNCEMENT")
        let finalData: any = [];
        if (chartData.length > 0) finalData.push({duplicate: 0, asPath: chartData[0].bgpData.asPath});
        chartData.map(e => {
          if (finalData.every((d: { duplicate: number, asPath: number[] }) => {
            return JSON.stringify(d.asPath) != JSON.stringify(e.bgpData.asPath)
          })) {
            const chartElement = {
              ids: [e.id],
              duplicate: 1,
              asPath: e.bgpData.asPath,
              highlight: false,
            }
            finalData.push(chartElement);
          } else {
            const index = finalData.find((f: { duplicate: number, asPath: number[] }) => JSON.stringify(f.asPath) === JSON.stringify(e.bgpData.asPath));
            if (index != null) {
              index.duplicate = index.duplicate + 1;
              let idAttr: number[] = [];
              idAttr = idAttr.concat(index.ids)
              idAttr.push(e.id)
              index.ids = idAttr
            }
          }
        })
        finalData.map((d: any) => {
          d.ids.map((e: any) => {
            if (highlights)
              if (highlights.includes(e)) {
                d.highlight = true;
              }
          })
        })

        finalData.sort((d: { duplicate: number }, e: { duplicate: number }) => {
          if (d.duplicate > e.duplicate) {
            return -1;
          }
          if (d.duplicate < e.duplicate) {
            return 1;
          }
          return 0;
        })

        let updateData = finalData.slice(0, 15)
        if(filter)
          filter.map(d => {
            if(d.type == "element" && d.filter == "highlight"){
              finalData.map((e:any) => {
                let id:any = [];
                if(Array.isArray(d.value)){
                  id = d.value;
                }
                if(e.ids.includes(id[0])){
                  if(!updateData.includes(e)){
                    updateData.push(e)
                  }
                }
              })
            }
          })
        chart.updateData(updateData, dispatch, colorState!);
      }
    }
  }

  useEffect(() => {
    const initializeChart = ({setChart, refContainer}: any) => {
      const chart = new DuplicatesBarChart(refContainer.current);
      setChart(chart);
      if (bgpData) {
        updateChart(chart, bgpData, filterTimeFrom, filterTimeTo, filterState, filterID);
      }
    };
    if (!chart) initializeChart({setChart, refContainer});
  }, [chart]);

  useEffect(() => {
    if (chart != null) {
      updateChart(chart, bgpData, filterTimeFrom, filterTimeTo, filterState, filterID)
    }
  }, [bgpData, filterTimeFrom, filterTimeTo, filterState, filterID])

  return (
    <>
      <div ref={refContainer} style={{height: '100%'}}>
      </div>
    </>
  );
});

export default DuplicatesComponent;
