import {PeeringDBState, PeeringDBActionTypes, PeeringDBActions} from './types';
import { StoreUtils } from '@iva/store-utils';
import {Feature, FeatureCollection} from "geojson";

// Initial state
const initialState: PeeringDBState = {
  peeringDBData : undefined,
  peeringDBASData : undefined,
  fetchError : "",
  fetching : false,
};



// The reducer function
export function peeringDBReducer(state = initialState, action: PeeringDBActions): PeeringDBState {
  switch (action.type) {

    case PeeringDBActionTypes.FETCH_PEERINGDB_REQUEST: {
      return { ...state, fetching : true};
    }
    case PeeringDBActionTypes.FETCH_PEERINGDB_SUCCESS: {
      return { ...state, fetching : false, peeringDBData : action.payload };
    }
    case PeeringDBActionTypes.FETCH_PEERINGDB_AS_REQUEST: {
      return { ...state, fetching : true};
    }
    case PeeringDBActionTypes.FETCH_PEERINGDB_AS_SUCCESS: {
      return { ...state, fetching : false, peeringDBASData : action.payload };
    }
    case PeeringDBActionTypes.FETCH_ERROR: {
      return { ...state, fetching : false, peeringDBData : undefined , fetchError: action.payload };
    }

    default: {
      return state;
    }
  }
};
