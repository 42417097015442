import React, {FC} from "react";
import {Paper, Theme, useTheme} from "@material-ui/core";
import ThemeProvider from "../../App/ThemeProvider";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {MuiThemeProvider} from "@material-ui/core/styles";

interface IProps {
  description : any;
  theme : Theme;
}

const TooltipComponent: FC<IProps> = (props) => {
  return (
    <MuiThemeProvider theme={props.theme}>
      <Paper style={{padding:10, textAlign:"center", display:"flow-root", justifyContent:"center", flexDirection:"column", maxHeight:300, overflowY:"auto"}}>
        {props.description}
      </Paper>
    </MuiThemeProvider>
  );
};

export default TooltipComponent;
