import { AsyncResource, Action, AsyncFetchAction } from '@iva/store-utils';
import {Feature, FeatureCollection} from "geojson";

// State definition
export interface PeeringDBState {
  // Used for fetching a resource via RestServiceUtils
  readonly peeringDBData: FeatureCollection | undefined;
  readonly peeringDBASData: FeatureCollection | undefined;
  readonly fetchError : string,
  readonly fetching : boolean;

}

// Action types definition
export enum PeeringDBActionTypes {

  FETCH_PEERINGDB_REQUEST = '@@PeeringDB/FETCH_PEERINGDB_REQUEST',
  FETCH_PEERINGDB_SUCCESS = '@@PeeringDB/FETCH_PEERINGDB_SUCCESS',
  FETCH_PEERINGDB_AS_REQUEST = '@@PeeringDB/FETCH_PEERINGDB_AS_REQUEST',
  FETCH_PEERINGDB_AS_SUCCESS = '@@PeeringDB/FETCH_PEERINGDB_AS_SUCCESS',
  FETCH_ERROR = '@@PEERINGDB/FETCH_ERROR',
}

// Actions for manual fetching resources

export type FetchPeeringDBRequestAction = Action<PeeringDBActionTypes.FETCH_PEERINGDB_REQUEST>;
export type FetchPeeringDBSuccessAction = Action<PeeringDBActionTypes.FETCH_PEERINGDB_SUCCESS, FeatureCollection>;
export type FetchPeeringDB_ASRequestAction = Action<PeeringDBActionTypes.FETCH_PEERINGDB_AS_REQUEST>;
export type FetchPeeringDB_ASSuccessAction = Action<PeeringDBActionTypes.FETCH_PEERINGDB_AS_SUCCESS, FeatureCollection>;
export type FetchPeeringDBErrorAction = Action<PeeringDBActionTypes.FETCH_ERROR, string>;


export type PeeringDBActions =  FetchPeeringDBRequestAction |
  FetchPeeringDBSuccessAction | FetchPeeringDB_ASRequestAction | FetchPeeringDB_ASSuccessAction |
  FetchPeeringDBErrorAction;
