import {Theme, useTheme} from "@material-ui/core";
import React, {FC, forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {TimeFilter} from "./Vis/TimeFilter";
import {ApplicationState} from "../../../store";
import {FeatureCollection} from "geojson";
import {BGPDataCollection} from "@pages/BGPDashboard/models/BGPDataCollection";
import {BGPData} from "@pages/BGPDashboard/models/BGPData";



const TimeFilterComponent = forwardRef((props, ref) => {

  const buckets = 100;
  const theme = useTheme<Theme>();
  const dispatch = useDispatch();
  const refContainer = useRef(null);
  const [chart, setChart] = useState(null);
  const prefixDateFrom = useSelector((state: ApplicationState) => state.bgpData.prefixDateFrom);
  const prefixDateTo = useSelector((state: ApplicationState) => state.bgpData.prefixDateTo);
  const bgpData = useSelector((state: ApplicationState) => state.bgpData.rawData);
  const filter = useSelector((state:ApplicationState) => state.bgpData.filter);
  const filterID = useSelector((state:ApplicationState) => state.bgpData.highlightData);
  const colorState = useSelector((state: ApplicationState) => state.bgpData.colorState);

  useImperativeHandle(ref, () => ({
    updateDimensions() {
      // @ts-ignore
      chart?.updateDimensions();
    }
  }));

  useEffect(() => {
    // runs on destruct/unmount
    return () => {
      // @ts-ignore
      if (chart?.cleanUp) chart?.cleanUp();
    }
  }, [chart])

  const format2DataAndUpdateChart = (chart : TimeFilter | null, bgpData : BGPDataCollection | undefined, buckets : number) => {

    if(bgpData?.collection && chart) {
      // create buckets format data to stacked format
      let chartData = bgpData?.collection

      if (filter && filter?.length > 0) {
        filter.map(d => {
          switch (d.type) {
            case "collector":
              if (d.filter == "add") {
                chartData = chartData?.filter(b => b.bgpData.collector === d.value)
              } else if (d.filter == "remove") {
                chartData = chartData?.filter(b => b.bgpData.collector != d.value)
              }
              break;
            case "type":
              let a = "";
              if (d.value === "announcement") a = "ANNOUNCEMENT"
              else a = "WITHDRAWAL"
              chartData = chartData?.filter(b => b.bgpData.type === a)
              break;
            case "origin":
              let c: any = "";
              if (d.value === "igp(i)") c = "IGP"
              else if (d.value === "egp(e)") c = "EGP"
              else c = null
              chartData = chartData?.filter(b => b.bgpData.origin === c)
              break;
            case "nextHop":
              if (d.filter == "add") {
                chartData = chartData?.filter(b => b.bgpData.nextHop === d.value)
              } else if (d.filter == "remove") {
                chartData = chartData?.filter(b => b.bgpData.nextHop != d.value)
              }
              break;
            case "duplicates":
              if (d.filter == "add") {
                chartData = chartData?.filter(b => b.bgpData.asPath.toString() == d.value.toString())
              } else if (d.filter == "remove") {
                chartData = chartData?.filter(b => b.bgpData.asPath.toString() != d.value.toString())
              }
              break;
            case "length":
              if (d.filter == "add") {
                chartData = chartData?.filter(b => b.bgpData.asPath.length == d.value)
              } else if (d.filter == "remove") {
                chartData = chartData?.filter(b => b.bgpData.asPath.length == d.value)
              }
              break;
            case "bucket":
              let tmp:any[] = [];
              tmp = tmp.concat(d.value)
              chartData = chartData?.filter(b => tmp.includes(b.id))
              break;
            case "map":
              let tmpData: BGPData[] = [];
              if (d.filter == "add") {
                if(Array.isArray(d.value))
                  d.value.forEach(b => {
                    chartData?.map(e => {
                      if(e.bgpData.collector == b.collector && e.bgpData.time*1000 == b.timestamp && e.bgpData.asPathString == b.asPath.replace(/ /g, "")){
                        tmpData.push(e)
                      }
                    })
                  })
              }
              chartData = tmpData;
              break;
            case "element":
              if (d.filter === "add")
                chartData = chartData.filter(b => b.id == d.value)
              else if (d.filter === "remove") chartData = chartData.filter(b => !(b.id == d.value))
              break;
          }
        })
      }
      let data: any = [];
      let bucketSize = (prefixDateTo - prefixDateFrom) / buckets;
      for (let i = 0; i < buckets; i++) {
        data.push({time: prefixDateFrom + (i * bucketSize), announce: 0, withdraw: 0, highlight: false, ids:[]});

      }
      for (let i = 0; i < chartData.length; i++) {
        let f = chartData[i].bgpData;
        let time = 0;

        if (f) {
          time = f.time * 1000
        }
        if (time < prefixDateFrom || time > prefixDateTo) {
          continue;
        }
        let bucket = Math.floor((time - prefixDateFrom) / bucketSize);
        if (bucket > buckets - 1) bucket = bucket - 1;
        // TODO Check Announce/Withdraw
        if(f.type == "WITHDRAWAL")data[bucket].withdraw= data[bucket].withdraw+1;
        else data[bucket].announce = data[bucket].announce + 1;

        if(filterID){
          if(filterID.includes(chartData[i].id)){
            data[bucket].highlight = true ;
          }
        }
        data[bucket].ids.push(chartData[i].id)

      }

      chart.updateData(data, bucketSize, prefixDateFrom, prefixDateTo, colorState)


    }
  };



  useEffect(() => {
    const initializeChart = ({setChart, refContainer}: any) => {
      const chart = new TimeFilter(refContainer.current, dispatch, theme);
      setChart(chart);
      if(bgpData){
        format2DataAndUpdateChart(chart, bgpData, buckets);
      }
    };
    if (!chart) initializeChart({setChart, refContainer});
    }, [chart]);

  useEffect(() => {
    if(chart != null){
      format2DataAndUpdateChart(chart, bgpData, buckets);
    }
  }, [bgpData, filter, filterID]);

  return (
    <>
      <div style={{position: "relative", width: "auto", height: "100%"}} ref={refContainer}/>
    </>
  );
});

export default TimeFilterComponent;
