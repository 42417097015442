import { AsyncResource, Action, AsyncFetchAction } from '@iva/store-utils';
import {Feature, FeatureCollection} from "geojson";

export interface AsnInfoData {
  readonly asn : number,
  readonly country : string,
  readonly rir : string,
  readonly registration : string,
  readonly holder : string,
  readonly querytime : string,

}
// State definition
export interface AsnInfoState {
  // Used for fetching a resource via RestServiceUtils
  readonly asnInfoData: AsnInfoData | undefined;
  readonly fetchError: string;
  readonly fetching : boolean;

}

// Action types definition
export enum AsnInfoActionTypes {

  FETCH_ASNINFO_REQUEST = '@@asnInfo/FETCH_ASNINFO_REQUEST',
  FETCH_ASNINFO_SUCCESS = '@@asnInfo/FETCH_ASNINFO_SUCCESS',
  FETCH_ERROR = '@@asnInfo/FETCH_ERROR'
}

// Actions for manual fetching resources

export type FetchAsnInfoRequestAction = Action<AsnInfoActionTypes.FETCH_ASNINFO_REQUEST>;
export type FetchAsnInfoSuccessAction = Action<AsnInfoActionTypes.FETCH_ASNINFO_SUCCESS, AsnInfoData>;
export type FetchAsnInfoErrorAction = Action<AsnInfoActionTypes.FETCH_ERROR, string>;



export type AsnInfoActions =  FetchAsnInfoRequestAction |
  FetchAsnInfoSuccessAction |
  FetchAsnInfoErrorAction;
