import {ForbiddenZonesAction, ForbiddenZonesActionTypes, ForbiddenZonesState} from "./types";

// Initial state
const initialState: ForbiddenZonesState = {
  forbiddenZonesData: undefined,
  fetchError: "",
  fetching: false,
};

export function forbiddenZonesReducer(state = initialState, action: ForbiddenZonesAction): ForbiddenZonesState {
  switch (action.type) {

    case ForbiddenZonesActionTypes.FETCH_FORBIDDENZONES_REQUEST: {
      return {...state, fetching: true};
    }
    case ForbiddenZonesActionTypes.FETCH_FORBIDDENZONES_SUCCESS: {
      return {...state, fetching: false, forbiddenZonesData: action.payload};
    }
    case ForbiddenZonesActionTypes.FETCH_FORBIDDENZONES_ERROR: {
      return {...state, fetching: false, forbiddenZonesData: undefined, fetchError: action.payload};
    }

    default: {
      return state;
    }
  }
}
