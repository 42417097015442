import {FeatureCollection} from "geojson";
import {Action} from "@iva/store-utils";

export interface DataCenterMapState {
  readonly dataCenterMapData: FeatureCollection | undefined,
  readonly dataCenterMapDataAS: FeatureCollection | undefined,
  readonly fetchError: string,
  readonly fetching: boolean,
}

export enum DataCenterMapActionTypes {
  FETCH_DATACENTERMAP_REQUEST = '@@DataCenterMap/FETCH_DATACENTERMAP_REQUEST',
  FETCH_DATACENTERMAP_SUCCESS = '@@DataCenterMap/FETCH_DATACENTERMAP_SUCCESS',
  FETCH_DATACENTERMAP_AS_REQUEST = '@@DataCenterMap/FETCH_DATACENTERMAP_AS_REQUEST',
  FETCH_DATACENTERMAP_AS_SUCCESS = '@@DataCenterMap/FETCH_DATACENTERMAP_AS_SUCCESS',
  FETCH_DATACENTERMAP_ERROR = '@@DataCenterMap/FETCH_DATACENTERMAP_ERROR',
}

export type FetchDataCenterMapRequestAction = Action<DataCenterMapActionTypes.FETCH_DATACENTERMAP_REQUEST>;
export type FetchDataCenterMapSuccessAction = Action<DataCenterMapActionTypes.FETCH_DATACENTERMAP_SUCCESS, FeatureCollection>;
export type FetchDataCenterMapASRequestAction = Action<DataCenterMapActionTypes.FETCH_DATACENTERMAP_AS_REQUEST>;
export type FetchDataCenterMapASSuccessAction = Action<DataCenterMapActionTypes.FETCH_DATACENTERMAP_AS_SUCCESS, FeatureCollection>;
export type FetchDataCenterMapErrorAction = Action<DataCenterMapActionTypes.FETCH_DATACENTERMAP_ERROR, string>;

export type DataCenterMapAction =
  FetchDataCenterMapRequestAction |
  FetchDataCenterMapSuccessAction |
  FetchDataCenterMapASRequestAction |
  FetchDataCenterMapASSuccessAction |
  FetchDataCenterMapErrorAction;
