import React, { FC } from 'react';

import {
  Box, createStyles, makeStyles, Paper, Theme, Typography,
} from '@material-ui/core';

// @ts-ignore
import routesImg from '../../../assets/routesIMC.png';
// @ts-ignore
import asImage from '../../../assets/asIMC.png';
// @ts-ignore
import geoTraceImage from '../../../assets/geoTraceImage.png';

// Style definitions
const useStyles = makeStyles((theme: Theme) => createStyles({
  tutorialBox: {
    padding: 15,
    maxWidth: "80%",
    margin:"auto",
    marginTop: 15,
    marginBottom: 15,
  },
  tutorialHeader: {
    fontSize: 15,
    marginRight: 5,
    fontWeight: "bold",
  },
  tutorialText: {
    maxWidth: "29%",
  }
}))

const GeoTraceLearnMore: FC = () => {
  // Get the styles defined above
  const classes = useStyles();

  return (
    <>
      <Typography variant="h4" align="center">GeoTrace</Typography>
      <Box height={10}/>

      <Typography variant="subtitle1" align="center" style={{fontSize:"large"}}>Geographic Approximation of Autonomous System Data Centers</Typography>
      <Typography style={{marginTop:10}} align="center"> Visualize traceroutes or BGP AS paths with a novel approximation algorithm</Typography>
      {/*<Box height={40}/>*/}
      {/*<Typography variant="subtitle1" align="center" style={{fontSize:"large"}}>Website under construction. Grand reopening on 01.06.2021</Typography>*/}

      <div style={{width:"80%" , margin:"auto", display: "flex"}}>
        <div style={{width: "50%", marginRight:10}}>
          <img width={"100%"} style={{marginRight:5}} src={asImage}/>
          <Typography>This image shows the usage of the first input row. You can insert an AS number and the
            approximated internal AS network is displayed.
            Hovering over the dots shows how many IP addresses were aggregated to the data centers.
          </Typography>
        </div>
        <br/>
        <div style={{width:"50%", marginLeft:10}}>
          <img width={"100%"} src={geoTraceImage}/>
          <Typography>
            This image shows the usage of the trace inputs. There are multiple options to provide data as input for our
            algorithm. See the explanation below for more details on the differences between the Linux, Windows and BGP
            inputs.
          </Typography>
        </div>
      </div>
      <div>
        <Paper className={classes.tutorialBox}>
          <div>
            <Typography style={{fontSize: 20, marginBottom: 15}}>
              Using the traceroute queries
            </Typography>
          </div>
          <div style={{display: "flex", marginTop: 5, justifyContent: "space-between"}}>
            <Typography className={classes.tutorialHeader}>
              Linux
            </Typography>
            <div className={classes.tutorialText}>
              <Typography>
                You can paste a linux traceroute using "traceroute -4 example-url.com" or "traceroute -A -4 example-url.com" to visualize
                the path using our approximated internal AS network for the given hops.
              </Typography>
              <Typography>
                Using the -A option may be more accurate as we try to map IP addresses to AS numbers using our database otherwise.
              </Typography>
            </div>
            <Typography className={classes.tutorialHeader} style={{marginLeft: 10}}>
              Windows
            </Typography>
            <div className={classes.tutorialText}>
              <Typography>
                You can paste a windows "tracert -4 example-url.com" to visualize the path using using our approximated internal AS network
                for the given hops.
              </Typography>
              <Typography>
                There is no option for the tracert command to log AS numbers and therefore our internal mapping from IP to AS number is always necessary
                for windows traceroutes.
              </Typography>
            </div>
            <Typography className={classes.tutorialHeader} style={{marginLeft: 10}}>
              BGP
            </Typography>
            <div className={classes.tutorialText}>
              <Typography>
                To visualize BGP paths, we expect a start and end IPv4 IP address (or address blocks) and list of AS numbers seperated by whitespaces.
                For example:
              </Typography>
              <Typography>
                start IP "84.116.191.221" or "84.116.191.221/21"
              </Typography>
              <Typography>
                end IP "195.2.18.217" or "195.2.18.217/24"
              </Typography>
              <Typography>
                AS path "6830 1273"
              </Typography>
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default GeoTraceLearnMore;
