import React, {FC, forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {PathLengthBarChart} from "../BGPDashboard/Vis/PathLengthBarChart";
import {BGPDataCollection} from "@pages/BGPDashboard/models/BGPDataCollection";
import {filterData} from "@pages/BGPDashboard/InteractionsComponent";
import {FilterStatus} from "@pages/BGPDashboard/models/FilterStatus";

const PathLengthComponent = forwardRef((props, ref) => {

  const refContainer = useRef(null);
  const [chart, setChart] = useState(null);
  const bgpData = useSelector((state: ApplicationState) => state.bgpData.rawData);
  const colorState = useSelector((state: ApplicationState) => state.bgpData.colorState);
  const filterTimeFrom = useSelector((state: ApplicationState) => state.filter.filterTimeFrom);
  const filterTimeTo = useSelector((state: ApplicationState) => state.filter.filterTimeTo);
  const filterState = useSelector((state:ApplicationState) => state.bgpData.filter);
  const filterID = useSelector((state:ApplicationState) => state.bgpData.highlightData);

  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    updateDimensions() {
      // @ts-ignore
      chart?.updateDimensions();
    }
  }));

  useEffect(() => {
    // runs on destruct/unmount
    return () => {
      // @ts-ignore
      if (chart?.cleanUp) chart?.cleanUp();
    }
  }, [chart])

  const updateChart = (chart: PathLengthBarChart | null, bgpData: BGPDataCollection | undefined, filterTimeFrom: number, filterTimeTo: number, filter: FilterStatus[] | undefined, highlights: number[] | undefined) => {
    if (bgpData?.collection && chart) {

      let chartData = filterData(bgpData, filterTimeFrom, filterTimeTo, filter);
      if(chartData) {
        chartData = chartData.filter(b => b.bgpData.type == "ANNOUNCEMENT") || [];
        let finalData: any = [];
        if (chartData.length > 0) finalData.push({count: 0, length: chartData[0].bgpData.asPath.length});
        chartData.map(e => {
          if (finalData.every((d: { count: number, length: number }) => {
            return d.length != e.bgpData.asPath.length
          })) {
            const chartElement = {
              ids: [e.id],
              count: 1,
              length: e.bgpData.asPath.length,
              highlight: false
            }
            finalData.push(chartElement);
          } else {
            const index = finalData.find((f: { count: number, length: number }) => f.length === e.bgpData.asPath.length);
            if (index != null) {
              index.count = index.count + 1;
              let idAttr: number[] = [];
              idAttr = idAttr.concat(index.ids)
              idAttr.push(e.id)
              index.ids = idAttr
            }
          }
        })

        finalData.map((d: any) => {
          d.ids.map((e: any) => {
            if (highlights)
              if (highlights.includes(e)) {
                d.highlight = true;
              }
          })
        })
        finalData.sort((d: { length: number }, e: { length: number }) => {
          if (d.length > e.length) {
            return 1;
          }
          if (d.length < e.length) {
            return -1;
          }
          return 0;
        })

        finalData = finalData;

        chart.updateData(finalData, dispatch, colorState!);
      }
    }
  }

  useEffect(() => {
    const initializeChart = ({setChart, refContainer}: any) => {
      const chart = new PathLengthBarChart(refContainer.current);
      setChart(chart);
      if (bgpData) {
        updateChart(chart, bgpData, filterTimeFrom, filterTimeTo, filterState, filterID);
      }
    };
    if (!chart) initializeChart({setChart, refContainer});
  }, [chart]);

  useEffect(() => {
    if (chart != null) {
      updateChart(chart, bgpData, filterTimeFrom, filterTimeTo, filterState, filterID)
    }
  }, [bgpData, filterTimeFrom, filterTimeTo, filterState, filterID])


  return (
    <>
      <div ref={refContainer} style={{height:'100%'}}>
      </div>
    </>
  );
});

export default PathLengthComponent;
