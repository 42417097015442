import {Dispatch} from "redux";
import {DataCenterMapActionTypes} from "./types";
import {action} from "@iva/store-utils";
import {RestServiceUtils} from "@iva/rest-service-utils";


export function fetchDataCenterMapData() {
  return (dispatch: Dispatch) => {
    dispatch(action(DataCenterMapActionTypes.FETCH_DATACENTERMAP_REQUEST, undefined));

    RestServiceUtils.jsonGET('/api/dataCenterMapData',
      result => dispatch(action(DataCenterMapActionTypes.FETCH_DATACENTERMAP_SUCCESS, result)),
      error => dispatch(action(DataCenterMapActionTypes.FETCH_DATACENTERMAP_ERROR, error.message))
    );
  }
}

export function fetchDataCenterMapDataAS(asn: number) {
  return (dispatch: Dispatch) => {
    // Dispatch action that fetching data has begun
    dispatch(action(DataCenterMapActionTypes.FETCH_DATACENTERMAP_AS_REQUEST, undefined));

    // Call endpoint, dispatch success- or error-message, depending on server response
    // Submit the GET-request, expecting plain text as response type
    RestServiceUtils.jsonGET('/api/dataCenterMapData/'+asn,
      result => {
        dispatch(action(DataCenterMapActionTypes.FETCH_DATACENTERMAP_AS_SUCCESS, result))
      },
      error => {
        dispatch(action(DataCenterMapActionTypes.FETCH_DATACENTERMAP_ERROR, error.message))
      }
    );
  }
}
