import {
  ApplicationLayoutActionTypes,
  ThemeTypes,
  SetThemeAction,
  SetShowTraceSummaryAction,
  SetShowTraceConfidenceColorsAction, SetTraceInputTypeAction,
} from './types';
import { action } from '@iva/store-utils';

// Actions
export function setThemeType(type: ThemeTypes): SetThemeAction{
    return action(ApplicationLayoutActionTypes.SET_THEME, type);
}

export function setShowTraceSummary(status: boolean): SetShowTraceSummaryAction{
  return action(ApplicationLayoutActionTypes.SET_SHOW_TRACE_SUMMARY, status);
}

export function setShowTraceConfidenceColors(status: boolean): SetShowTraceConfidenceColorsAction{
  return action(ApplicationLayoutActionTypes.SET_SHOW_TRACE_CONFIDENCE_COLORS, status);
}

export function setTraceInputType(type: "Linux" | "Windows" | "BGP"): SetTraceInputTypeAction{
  return action(ApplicationLayoutActionTypes.SET_TRACE_INPUT_TYPE, type);
}
