import React, {FC, forwardRef, useEffect, useState} from "react";
import MaterialTable, {EditCellColumnDef, Icons} from '@material-table/core';
import {BGPDataCollection} from "@pages/BGPDashboard/models/BGPDataCollection";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import AddBox from '@material-ui/icons/AddBox';
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import Highlight from '@material-ui/icons/Highlight';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {ArrowUpward, ExpandLess} from "@material-ui/icons";
import {
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MuiThemeProvider, TableCell,
  Typography
} from "@material-ui/core";
import {setFilter, setHighlights} from "../../../store/bgpData/actions";
import {filterData} from "../BGPDashboard/InteractionsComponent"
import {FilterStatus} from "@pages/BGPDashboard/models/FilterStatus";
import {createTheme} from "@material-ui/core/styles";
import {BGPData} from "@pages/BGPDashboard/models/BGPData";

const tableIcons : Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const BGPTable: FC = () => {

  const bgpData = useSelector((state: ApplicationState) => state.bgpData.rawData);
  const filterState = useSelector((state:ApplicationState) => state.bgpData.filter);
  const filterTimeFrom = useSelector((state: ApplicationState) => state.filter.filterTimeFrom);
  const filterTimeTo = useSelector((state: ApplicationState) => state.filter.filterTimeTo);
  const filterID = useSelector((state:ApplicationState) => state.bgpData.highlightData);
  const colorState = useSelector((state: ApplicationState) => state.bgpData.colorState);
  const dispatch = useDispatch();
  const [table, setTable] = useState([]);
  const [key, setKey] = useState(0);

  const testData:BGPData[]  = [];

  const [data, setData] = useState(testData)




  const theme = createTheme({
    palette: {
      type: 'dark',
      primary: {
        main: (colorState?.highlight) ? colorState?.highlight : "#fca426"
      },
      secondary: {
        main: '#fff'
      },
    },

  });

  const updateTable = (data: BGPDataCollection | undefined, filterTimeFrom: number, filterTimeTo: number, filter: FilterStatus[] | undefined) => {

    let tmp:any = [];
    if(data && colorState) {
      let updateData = filterData(data, filterTimeFrom, filterTimeTo, filter)
      if(updateData)
        updateData.map(e => {
          e.bgpData.timeString = new Date(e.bgpData.time*1000).toLocaleString()
          e.bgpData.communitiesString = e.bgpData.communities?.join(', ')
          e.bgpData.asPathString = e.bgpData.asPath?.join('-')
          e.bgpData.id = e.id
          e.bgpData.key = e.id
        })

      if(updateData)
        setData(updateData)

    }
  }

  useEffect(() => {
    updateTable(bgpData, filterTimeFrom, filterTimeTo, filterState)
  }, [bgpData, filterTimeFrom, filterTimeTo, filterState, filterID])

  return (
    <div style={{width: "100%"}}>
      <Accordion style={{position: "fixed", bottom: 28, width: "85%", left: "7.5%", right: "7.5%", zIndex: 999, maxHeight:1000}}>
        <AccordionSummary style={{minHeight: 0, height: 35}}
                               expandIcon={<ExpandLess/>}
                               aria-controls="panel1a-content"
                               id="panel1a-header">
          <Typography style={{margin:"auto"}}><b>{data ? data.length : ""}</b> bgp data elements left after applying filter {((1000 < ((data) ? (data.length) : 0 )) ? ("- Limited to 1000 entries!") : "")}</Typography>
        </AccordionSummary>
        <Divider/>
        <AccordionDetails style={{padding: 0, justifyContent: "center"}}>
          <MuiThemeProvider theme={theme}>
            {data && colorState && <MaterialTable title={"BGP Updates"} icons={tableIcons} style={{ width: "100%"}} options={{pageSizeOptions: [5,10]}}
                           actions={[
                             {
                               icon: Add,
                               tooltip: 'Filter Update',
                               onClick: (event, rowData) => {
                                 let id = 0
                                 let info = null;
                                 if(!Array.isArray(rowData)){
                                   id = rowData.id
                                   info = {
                                     type: rowData.type,
                                     collector: rowData.collector,
                                     timestamp: rowData.timeString,
                                     asPathString: rowData.asPathString,
                                     id: rowData.id,
                                     nextHop: rowData.nextHop
                                   }
                                 }
                                 dispatch(setFilter({type: "element", value: id, filter: "add", color:colorState?.add, name:"ID: "+id, info: info}))
                               }
                             },
                             {
                               icon: Remove,
                               tooltip: 'Remove Update',
                               onClick: (event, rowData) => {
                                 let id = 0;
                                 let info = null;
                                 if(!Array.isArray(rowData)){
                                   id = rowData.id
                                   info = {
                                     type: rowData.type,
                                     collector: rowData.collector,
                                     timestamp: rowData.timeString,
                                     asPathString: rowData.asPathString,
                                     id: rowData.id,
                                     nextHop: rowData.nextHop
                                   }
                                 }
                                 dispatch(setFilter({type: "element", value: id, filter: "remove", color:colorState.remove, name: "ID: "+id, info: info}))
                               }
                             },
                             rowData => {
                               let active =
                                 rowData && filterID &&
                                 filterID.includes(rowData.id);
                               return {
                                 icon: () => <Highlight color={active ? "primary" : "secondary"}/>,
                                 tooltip: 'Highlight Update',
                                 onClick: (event, rowData) => {
                                   let id = 0;
                                   let info = null;
                                   if (!Array.isArray(rowData)) {
                                     id = rowData.id
                                     info = {
                                       type: rowData.type,
                                       collector: rowData.collector,
                                       timestamp: rowData.timeString,
                                       asPathString: rowData.asPathString,
                                       id: rowData.id,
                                       nextHop: rowData.nextHop
                                     }
                                   }
                                   dispatch(setFilter({
                                     type: "element",
                                     value: [id],
                                     filter: "highlight",
                                     color: colorState?.highlight,
                                     name: "ID: " + id,
                                     info: info
                                   }))
                                   dispatch(setHighlights([id]))
                                 },
                               }
                             }
                           ]}
                           columns={[
                             {title: "ID", field: "id"},
                             {title: "Timestamp", field: "timeString"},
                             {title: "Type", field: "type"},
                             {title: "Origin", field: "origin"},
                             {title: "NextHop", field: "nextHop"},
                             {title: "AsPath", field: "asPathString", width: "15%"},
                             {title: "Collector", field: "collector"},
                             {title: "Communities", field: "communitiesString"}
                           ]}
                           data={(data) ? (data.map((d,i) => {
                             return d.bgpData
                           })) : []}
            ></MaterialTable>}
          </MuiThemeProvider>
        </AccordionDetails>
      </Accordion>
    </div>);
}

export default BGPTable;
