import 'babel-polyfill';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { Provider } from 'react-redux';

import App from './containers/App/App';

import { createBrowserHistory } from 'history';
import configureStore from './configureStore';

const history = createBrowserHistory();
const store = configureStore(history);

const Root = () => (
  <Provider store={store}>
      <App />
  </Provider>
);

const render = () => {
  ReactDOM.render(
    <Root />,
    document.getElementById('app-main')
  );
  
}

render()

// Hot reloading
if ((module as any).hot) {
  // Reload components
  (module as any).hot.accept('./containers/App/App', () => {
    render()
  })
}
