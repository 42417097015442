

export interface WebSocketState {
    readonly sessionId : string;
    readonly prefixRequest : {
      prefix: string,
      dateFrom: string,
      dateTo: string
    };
}

// Action types definition
export enum WebsocketActionTypes {
    SET_SESSION_ID = '@@websocket/SET_SESSION_ID',
    SET_PREFIX_REQUEST = '@@websocket/SET_PREFIX_REQUEST',
    RESET_PREFIX_REQUEST = '@@websocket/RESET_PREFIX_REQUEST',
}
