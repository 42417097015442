import { AsyncResource, Action, AsyncFetchAction } from '@iva/store-utils';

// State definition
export interface FilterState {
  // Used to persist the UI state (user selections)
  readonly filterTimeFrom: number;
  readonly filterTimeTo: number;
}

// Action types definition
export enum FilterActionTypes {
  SET_FILTER_FROM = '@@filter/SET_FILTER_FROM',
  SET_FILTER_TO = '@@filter/SET_FILTER_TO',

}

// Actions definition
export type FilterFromAction = Action<FilterActionTypes.SET_FILTER_FROM, number>;
export type FilterToAction = Action<FilterActionTypes.SET_FILTER_TO, number>;


export type FilterActions = FilterFromAction | FilterToAction;
