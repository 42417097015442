import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import {Box, Chip, Drawer, IconButton, Tooltip as MaterialTooltip} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import {Feature, FeatureCollection} from "geojson";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../store";

interface IProps {
  isOpen : boolean;
  summary: FeatureCollection;
  searchAsn: (asn: number) => void;
  clearAsn: () => void;
  currentAsn: number;
  setSelectedHop: Function;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      padding: theme.spacing(2),
    },
    category: {
      fontWeight: 'bold',
      marginTop: 5,
    }
  }),
);

function getStepInfo(featureInfo: Feature, searchAsn: (asn: number) => void, clearAsn: () => void, currentAsn: number) {
  const classes = useStyles();

  if(featureInfo.properties) {
    if (featureInfo.properties.type == "ip") {
      // timed out hop
      if (featureInfo.properties.ip == "***"){
        return <Typography>
          No information on Hop because Router did not respond.
        </Typography>
      }
      // ip reserved for private use
      if (featureInfo.properties.isPrivate) {
        return <Typography>
          Ip is private.
        </Typography>
      }
      // Ip not found by database
      if (!featureInfo.properties.inDB) {
        return <Typography>
          Ip was not found by Database.
        </Typography>
      }
      // Ip known as unknown (use geolocation of ip)
      if (featureInfo.properties.geoLoc) {
        let domain = featureInfo.properties.domain ? featureInfo.properties.domain : '-';
        let accuracy = featureInfo.properties.accuracy ? featureInfo.properties.accuracy : '-';
        let source = featureInfo.properties.dataSource ? featureInfo.properties.dataSource : '-';
        let score = featureInfo.properties.score ? featureInfo.properties.score : '-';
        let distanceThreshold = featureInfo.properties.distanceThreshold ? featureInfo.properties.distanceThreshold : '-';
        return <Typography component="div">
          Geo Location of IP used. <br/>
          AS number is not known. <br/>
          <Box className={classes.category}>Hostname: </Box> <Box style={{wordBreak: 'break-all'}}>{domain}</Box>
          <MaterialTooltip
            title={
              <Typography style={{fontSize: 12}}>
                The approximated distance between this and the previous hop (previous to previous, etc. if no ICMP response was received in traceroute)
                calculated using the difference in latency between the two hops.
              </Typography>}
            placement={"left"}
            arrow
          >
            <Box>
              <Box className={classes.category}>Latency distance: </Box>
              <Box>
                {isNaN(distanceThreshold) ? "N/A" : parseFloat(distanceThreshold).toFixed(2) + " km"}
              </Box>
            </Box>
          </MaterialTooltip>
          <Box className={classes.category}>Location source: </Box> <Box>{source}</Box>
          <MaterialTooltip
            title={
              <Typography style={{fontSize: 12}}>
                Confidence values mean different things for the different sources: <br/>
                <b>MaxMind</b>: The size of the confidence radius as given by MaxMind. <br/>
                <b>Big Data Cloud</b>: The confidence expressed as either low,  medium or high as given by Big Data Cloud. <br/>
                <b>RIPE</b>: No confidence value can be given. <br/>
                <b>DNS Location</b>: The confidence as calculated by our algorithm analyzing the hostname. <br/>
              </Typography>}
            placement={"left"}
            arrow
          >
            <Box>
              <Box className={classes.category}>Confidence: </Box>
              <Box>
                {source.includes("Big Data Cloud") ?
                  accuracy === 100 ? "High" : accuracy === 400 ?
                    "Medium" : "Low"
                  : source.includes("DNS") || accuracy === "N/A" ?
                    accuracy : accuracy + " km"}
              </Box>
            </Box>
          </MaterialTooltip>
          <MaterialTooltip
            title={
              <Typography style={{fontSize: 12}}>
                The value calculated by the multi-source fusion algorithm displaying the relative uncertainty for the chosen source. <br/>
                The lower the score, the less uncertainty was inferred.
              </Typography>}
            placement={"left"}
            arrow
          >
            <Box>
              <Box className={classes.category}>Algorithm score: </Box> <Box>{score}</Box>
            </Box>
          </MaterialTooltip>
        </Typography>
      }
      let domain = featureInfo.properties.domain ? featureInfo.properties.domain : '-';
      let asn = featureInfo.properties.asn ? featureInfo.properties.asn : '-';
      let accuracy = featureInfo.properties.accuracy ? featureInfo.properties.accuracy : '-';
      let source = featureInfo.properties.dataSource ? featureInfo.properties.dataSource : '-';
      let score = featureInfo.properties.score ? featureInfo.properties.score : '-';
      let distanceThreshold = featureInfo.properties.distanceThreshold ? featureInfo.properties.distanceThreshold : '-';
      let rir = featureInfo.properties.rir ? featureInfo.properties.rir : '-';
      let registration = featureInfo.properties.registration ? new Date(featureInfo.properties.registration).toLocaleDateString('de-DE') : '-';
      let country = featureInfo.properties.country ? featureInfo.properties.country : '-';
      let holder = featureInfo.properties.holder ? featureInfo.properties.holder : '-';
      return <Typography component="div">
        <Box className={classes.category}>Hostname: </Box> <Box style={{wordBreak: 'break-all'}}>{domain}</Box>
        <Box className={classes.category}>AS number: </Box>
        <Box>
          {asn}
          <IconButton color="primary" size="small" onClick={() => currentAsn == parseInt(asn) ? clearAsn() : searchAsn(asn)}>
            {currentAsn == parseInt(asn) ? <ClearIcon/> : <SearchIcon/>}
          </IconButton>
        </Box>
        {featureInfo.properties.rirError &&
          <>
            <Box className={classes.category}>Registration Information: </Box> <Box>RIPEstat API unavailable</Box>
          </>
        }
        {!featureInfo.properties.rirError &&
          <>
            <Box className={classes.category}>Regional internet registry: </Box> <Box>{rir}</Box>
            <Box className={classes.category}>Registered since: </Box> <Box>{registration}</Box>
            <Box className={classes.category}>Country: </Box> <Box>{country}</Box>
            <Box className={classes.category}>AS holder: </Box> <Box>{holder}</Box>
          </>
        }
        <MaterialTooltip
          title={
            <Typography style={{fontSize: 12}}>
              The approximated distance between this and the previous hop (previous to previous, etc. if no ICMP response was received in traceroute)
              calculated using the difference in latency between the two hops.
            </Typography>}
          placement={"left"}
          arrow
        >
          <Box>
            <Box className={classes.category}>Latency distance: </Box>
            <Box>
              {isNaN(distanceThreshold) ? "N/A" : parseFloat(distanceThreshold).toFixed(2) + " km"}
            </Box>
          </Box>
        </MaterialTooltip>
        <Box className={classes.category}>Location source: </Box> <Box>{source}</Box>
        <MaterialTooltip
          title={
          <Typography style={{fontSize: 12}}>
            Confidence values mean different things for the different sources: <br/>
            <b>MaxMind</b>: The size of the confidence radius as given by MaxMind. <br/>
            <b>Big Data Cloud</b>: The confidence expressed as either low,  medium or high as given by Big Data Cloud. <br/>
            <b>RIPE</b>: No confidence value can be given. <br/>
            <b>DNS Location</b>: The confidence as calculated by our algorithm analyzing the hostname. <br/>
          </Typography>}
          placement={"left"}
          arrow
        >
          <Box>
            <Box className={classes.category}>Confidence: </Box>
            <Box>
              {source.includes("Big Data Cloud") ?
                accuracy === 100 ? "High" : accuracy === 400 ?
                  "Medium" : "Low"
                : source.includes("DNS") || accuracy === "N/A" ?
                  accuracy : accuracy + " km"}
            </Box>
          </Box>
        </MaterialTooltip>
        <MaterialTooltip
          title={
            <Typography style={{fontSize: 12}}>
              The value calculated by the multi-source fusion algorithm displaying the relative uncertainty for the chosen source. <br/>
              The lower the score, the less uncertainty was inferred.
            </Typography>}
          placement={"left"}
          arrow
        >
          <Box>
            <Box className={classes.category}>Algorithm score: </Box> <Box>{score}</Box>
          </Box>
        </MaterialTooltip>
      </Typography>
    } else { // AS Summary Info
      let rir = featureInfo.properties.rir ? featureInfo.properties.rir : '-';
      let registration = featureInfo.properties.registration ? new Date(featureInfo.properties.registration).toLocaleDateString('de-DE') : '-';
      let country = featureInfo.properties.country ? featureInfo.properties.country : '-';
      let holder = featureInfo.properties.holder ? featureInfo.properties.holder : '-';
      if (!featureInfo.properties.inDB) {
        return <Typography>
          AS was not found by Database
        </Typography>
      }
      if(featureInfo.properties.rirError) {
        return <Typography component="div">
          <Box className={classes.category}>Registration Information: </Box> <Box>RIPEstat API unavailable</Box>
        </Typography>
      } else {
        return <Typography component="div">
          <Box className={classes.category}>Regional internet registry: </Box> <Box>{rir}</Box>
          <Box className={classes.category}>Registered since: </Box> <Box>{registration}</Box>
          <Box className={classes.category}>Country: </Box> <Box>{country}</Box>
          <Box className={classes.category}>AS holder: </Box> <Box>{holder}</Box>
        </Typography>
      }
    }
  }
  return
}

function VerticalLinearStepper(ipInfos: FeatureCollection, searchAsn: (asn: number) => void, clearAsn: () => void, currentAsn: number, setSelectedHop : Function) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const showTraceConfidenceColors = useSelector((state: ApplicationState) => state.applicationLayout.showTraceConfidenceColors);

  const handleStep = (step: number, feature: Feature) => () => {
    setActiveStep(step);
  };

  const handleFly = (step: number, feature: Feature) => () => {
    if (feature.properties?.inDB && !feature.properties?.isPrivate && feature.properties?.ip != "***") {
      // @ts-ignore
      setSelectedHop(feature.properties.validHopCounter);
    }
  };

  return (
    <Stepper
      nonLinear
      activeStep={activeStep}
      orientation="vertical">
      {ipInfos.features.map((feature, index) => (
        <Step key={index} >
        <StepLabel
          StepIconComponent={() =>
            <Chip
              style={{ backgroundColor: showTraceConfidenceColors ? feature.properties?.confHex : feature.properties?.hex,
                fontWeight: "bold", color: "black", borderColor: "white", borderWidth: 2, borderStyle: "solid" }}
              size="small"
              label={`${feature.properties && feature.properties.origHopNumber ? feature.properties.origHopNumber : index + 1}`}/>}
            onClick={handleStep(index, feature)}>
          {feature.properties?.type == 'ip' ? feature.properties.ip : feature.properties?.asn}
          <IconButton color="primary" size="small" onClick={handleFly(index, feature)}>
            <PlayForWorkIcon/>
          </IconButton>

          {
            feature.properties?.type != 'ip' && index == activeStep && feature.properties?.inDB &&
            <IconButton color="primary" size="small" onClick={() => currentAsn == parseInt(feature.properties?.asn) ? clearAsn() : searchAsn(feature.properties?.asn)}>
              {currentAsn == parseInt(feature.properties?.asn) ? <ClearIcon/> : <SearchIcon/>}
            </IconButton>
          }
        </StepLabel>
        <StepContent>
          {getStepInfo(feature, searchAsn, clearAsn, currentAsn)}
        </StepContent>
        </Step>
        ))}
    </Stepper>
  );
}

export function TraceRouteSummary(props: IProps) {
  const classes = useStyles();

  return (
      <Drawer
        className={classes.drawer}
        PaperProps={{ style: { top: 48, height: 'calc(100vh - 80px)', width: 300, zIndex: 1,} }}
        anchor={'right'}
        open={props.isOpen}
        variant='persistent'
      >
        {VerticalLinearStepper(props.summary, props.searchAsn, props.clearAsn, props.currentAsn, props.setSelectedHop)}
      </Drawer>
  );
}
