import {Dispatch} from "redux";
import {ForbiddenZonesActionTypes} from "./types";
import {action} from "@iva/store-utils";
import {RestServiceUtils} from "@iva/rest-service-utils";


export function fetchForbiddenZones() {
  return (dispatch: Dispatch) => {
    dispatch(action(ForbiddenZonesActionTypes.FETCH_FORBIDDENZONES_REQUEST, undefined));

    RestServiceUtils.jsonGET('/api/forbiddenZones',
      result => dispatch(action(ForbiddenZonesActionTypes.FETCH_FORBIDDENZONES_SUCCESS, result)),
      error => dispatch(action(ForbiddenZonesActionTypes.FETCH_FORBIDDENZONES_ERROR, error.message))
    );
  }
}
