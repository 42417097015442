import {DnsCenterAction, DnsCenterActionTypes, DnsCenterState} from "./types";

// Initial state
const initialState: DnsCenterState = {
  dnsCenterData: undefined,
  dnsCenterDataAS: undefined,
  fetchError: "",
  fetchingDns: false,
};

export function dnsCenterReducer(state = initialState, action: DnsCenterAction): DnsCenterState {
  switch (action.type) {
    case DnsCenterActionTypes.FETCH_DNSCENTER_REQUEST:
      return {...state, fetchingDns: true};
    case DnsCenterActionTypes.FETCH_DNSCENTER_SUCCESS:
      return {...state, dnsCenterData: action.payload, fetchingDns: false};
    case DnsCenterActionTypes.FETCH_DNSCENTER_AS_REQUEST:
      return {...state, fetchingDns: true};
    case DnsCenterActionTypes.FETCH_DNSCENTER_AS_SUCCESS:
      return {...state, dnsCenterDataAS: action.payload, fetchingDns: false};
    case DnsCenterActionTypes.FETCH_DNSCENTER_ERROR:
      return {...state, fetchError: action.payload, fetchingDns: false};
    default: {
      return state;
    }
  }
}
