import React, { FC } from 'react';
import Imprint from '@iva/imprint-component';
import { Box } from '@material-ui/core';

const ImprintPage: FC = () => {
  return (
    <Box paddingLeft="5vw" paddingRight="5vw">
      <Imprint />
    </Box>
  )
}

export default ImprintPage;