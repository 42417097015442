import {FeatureCollection} from "geojson";
import {Action} from "@iva/store-utils";

export interface ForbiddenZonesState {
  readonly forbiddenZonesData: FeatureCollection | undefined,
  readonly fetchError: string,
  readonly fetching: boolean,
}

export enum ForbiddenZonesActionTypes {
  FETCH_FORBIDDENZONES_REQUEST = '@@ForbiddenZones/FETCH_FORBIDDENZONES_REQUEST',
  FETCH_FORBIDDENZONES_SUCCESS = '@@ForbiddenZones/FETCH_FORBIDDENZONES_SUCCESS',
  FETCH_FORBIDDENZONES_ERROR = '@@ForbiddenZones/FETCH_FORBIDDENZONES_ERROR',
}

export type FetchForbiddenZonesRequestAction = Action<ForbiddenZonesActionTypes.FETCH_FORBIDDENZONES_REQUEST>;
export type FetchForbiddenZonesSuccessAction = Action<ForbiddenZonesActionTypes.FETCH_FORBIDDENZONES_SUCCESS, FeatureCollection>;
export type FetchForbiddenZonesErrorAction = Action<ForbiddenZonesActionTypes.FETCH_FORBIDDENZONES_ERROR, string>;

export type ForbiddenZonesAction =
  FetchForbiddenZonesRequestAction |
  FetchForbiddenZonesSuccessAction |
  FetchForbiddenZonesErrorAction;
