import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';
import { ApplicationState } from 'store/index';
import { useLocation } from "react-router-dom";


const NotFoundPage: FC = () => {
  const locationPath = useLocation().pathname;

  return (
    <>
      <Typography variant="h4">
        <strong>Error 404</strong>
      </Typography>
      <Typography>
        Location not found: {locationPath}
      </Typography>
    </>
  )
}

export default NotFoundPage;
