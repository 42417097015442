import * as d3 from "d3";
import {setFilter, setHighlights} from "../../../../store/bgpData/actions";
import {SVGChart} from "@pages/BGPDashboard/Vis/SVGChart";
import {Dispatch} from "redux";
import {ColorState} from "@pages/BGPDashboard/models/ColorState";
import {Axis, ScaleBand, ScaleLinear} from "d3";

import ReactDOMServer from 'react-dom/server';
import React from 'react';
import HighlightIcon from '@material-ui/icons/Highlight';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {Tooltip} from "@pages/BGPDashboard/Vis/Tooltip";

const t = 750

export class NextHopBarChart extends SVGChart {
  // padding needed because the xAxis is larger
  private padding = {left: 9, right:9};

  private rootElem: any;
  private tip!: Tooltip;

  private data?: any[];
  private colorState?: ColorState;
  private dispatch?: Dispatch;

  private xAxis: any;
  private xScale!: ScaleLinear<number, number>;
  private xAxisGenerator!: Axis<any>;

  private yScale!: ScaleBand<any>;

  private barHeight = 15;
  private barPadding = 0.1;

  private readonly highlightIcon: string;
  private readonly addIcon: string;
  private readonly removeIcon: string;

  constructor(node: HTMLElement) {
    super(node, 250, 200);

    this.highlightIcon = ReactDOMServer.renderToString(React.createElement(HighlightIcon));
    this.addIcon = ReactDOMServer.renderToString(React.createElement(AddCircleOutlineIcon));
    this.removeIcon = ReactDOMServer.renderToString(React.createElement(RemoveCircleOutlineIcon));

    this.init();
  }

  init(){
    this.rootElem = d3.select(this.svgElement).append('g');

    this.tip = new Tooltip()
      .content((args: any[]) => {
        let [d] = args;
        return "Count: " +  d.count
      });

    this.xScale = d3.scaleLinear();
    this.xAxisGenerator = d3.axisBottom(this.xScale);

    this.yScale = d3.scaleBand()
      .padding(this.barPadding)

    this.xAxis = this.rootElem
      .append('g')
      .attr("class", "x axis")
      .call(this.xAxisGenerator)

    this.xAxis.append('text')
      .attr("y", 30)
      .attr("x", "50%")
      .attr("fill", "white")
      .style("font-size", "12px")
      .text("Frequency of next hops")

    this.resizeChart();
  }

  cleanUp() {
    this.tip.destroy();
  }


  resizeChart(): void {
    let xAxisHeight = this.xAxis.node()?.getBoundingClientRect().height;
    let filterButtonWidth = 3*this.barHeight;
    this.xScale.range([this.padding.left, this.width-this.padding.right-filterButtonWidth]);

    this.xAxis.attr("transform", "translate(0, " + (this.height - xAxisHeight) + ")");
    this.xAxis.call(this.xAxisGenerator);

    this.drawBarChartBars();
  }

  updateData(data: any[], dispatch: Dispatch<any>, color: ColorState) {
    this.data = data;
    this.dispatch = dispatch;
    this.colorState = color;

    this.calculateMinimumDimensions();
    this.updateDimensions();
    this.drawBarChartBars();
  }

  calculateMinimumDimensions() {
    if (!this.data) {
      return;
    }

    let chartHeight = (this.data.length+1) * Math.ceil(this.barHeight / (1-this.barPadding)) - this.barHeight;
    let xAxisHeight = this.xAxis.node()?.getBoundingClientRect().height;

    this.yScale.rangeRound([0, chartHeight]);
    this.minHeight = chartHeight + xAxisHeight;
  }

  drawBarChartBars() {
    if (!this.data || !this.colorState || !this.dispatch) {
      return;
    }
    let data = this.data;
    let color = this.colorState;
    let dispatch = this.dispatch;

    let xDom = this.xScale.domain([0, d3.max(data, d => d.count)])
    let yDom = this.yScale.domain(d3.range(data.length))

    const xAxisTicks = this.xScale.ticks()
      .filter(tick => Number.isInteger(tick));

    this.xAxisGenerator.scale(xDom).tickValues(xAxisTicks)
      .tickFormat(d3.format('d'));

    this.rootElem.select(".x")
      .transition().duration(t)
      .call(this.xAxisGenerator)


    this.rootElem
      .selectAll('rect')
      .data(data)
      .join('rect')
      .on("mouseover", (event:any, d: any) => this.tip.show(d))
      .on("mousemove", (event: any, d: any) => {
        this.tip.setPosition(event.pageY, event.pageX)
      })
      .on("mouseout", () => this.tip.hide())
      .transition()
      .duration(t)
      .attr('x', this.xScale(0))
      .attr('y', (d: any,i: any) => this.yScale(i))
      .attr('width', (d: any) => this.xScale(d.count) - this.xScale(0))
      .attr('height', this.yScale.bandwidth())
      .attr("fill", (d: any) => {
        if(d.highlight) return color.highlight;
        else return color.announce
      })

    let dia = this.barHeight - 1;


    const infoLayer = d3.select(this.svgElement).selectAll(".infoLayer").data(data);
    infoLayer.exit().remove();

    const groupsEnter: d3.Selection<any, any, any, any> = infoLayer.enter().append('g').attr("class", "infoLayer");

    infoLayer
      .merge(groupsEnter)
      .transition()
      .duration(t)


    const collectorData = groupsEnter.append('g')
      .append("text")
      .attr("class", "label")
      .attr("fill", "white")
      .attr("text-anchor", "left")
      .attr("font-family", "sans-serif")
      .attr("font-size", "calc(.5vw + .5vh)")
      .merge(infoLayer.select('.label'))
      .on("mouseover", (event:any, d: any) => this.tip.show(d))
      .on("mousemove", (event: any, d: any) => {
        this.tip.setPosition(event.pageY, event.pageX)
      })
      .on("mouseout", () => this.tip.hide())
      .attr("x", 15)
      .attr("y", (d, i) => this.yScale(i)! + this.yScale.bandwidth() / 2)
      .attr("dy", "0.35em")
      .attr("dx", 10)
      .text(d => d.nextHop)

    if(data.length > 1) {

      const filterLayer = d3.select(this.svgElement).selectAll(".filterLayer").data(data);
      filterLayer.exit().remove();

      const filterEnter: d3.Selection<any, any, any, any> = filterLayer.enter().append('g').attr("class", "filterLayer");

      filterLayer
        .merge(filterEnter)
        .transition()
        .duration(t)

      const addFilterButton = (className: string, btn_index: number, iconHtml: string, color: string, onClick: (event: any, d:any) => any) => {
        let svg = filterEnter
          .append('g')
          .html(iconHtml)
          .select('svg')
          .attr("class", className)
          .style("fill", color)
          .attr('fill-opacity', 0.8)
          .style("cursor", "pointer")
          .on("click", onClick)
          .attr("pointer-events", "all");

        // background rect for clean pointer events
        svg.append("rect")
          .style("fill-opacity", 0)
          .attr("width", "100%")
          .attr("height", "100%")

        svg.merge(filterLayer.select("."+className))
          .transition()
          .duration(t)
          .attr('x', this.width - dia * btn_index)
          .attr("y", (d, i) => this.yScale(i)!)
          .attr('width', dia + "px")
          .attr('height', dia + "px");
      }

      addFilterButton("highlight", 3, this.highlightIcon, color.highlight,
        (e, d) => {
          dispatch(setFilter({type: "nextHop", value: d.ids, filter: "highlight", color:color.highlight, name: d.nextHop, info: null}))
          dispatch(setHighlights(d.ids))
        }
      );
      addFilterButton("plus", 2, this.addIcon, color.add,
        (e, d) => {
          dispatch(setFilter({type: "nextHop", value: d.nextHop, filter: "add", color:color.add, name: d.nextHop, info: null}))
        }
      );
      addFilterButton("minus", 1, this.removeIcon, color.remove,
        (e, d) => {
          dispatch(setFilter({type: "nextHop", value: d.nextHop, filter: "remove", color:color.remove, name: d.nextHop, info: null}))
        }
      );
    } else {
      d3.select(this.svgElement).selectAll(".filterLayer").remove();
    }

  }
}
