import { action } from '@iva/store-utils';
import {
  AsnInfoActions,
  AsnInfoActionTypes,
} from './types';

import { RestServiceUtils } from '@iva/rest-service-utils';
import { Dispatch } from 'redux';
import {ApplicationLayoutActionTypes, SetThemeAction, ThemeTypes} from "../applicationLayout/types";

// Example for fetching a resource via RestServiceUtils
export function fetchAsnInfo(asn : string, startTime : number, endTime : number) {
    return (dispatch: Dispatch) => {
        // Dispatch action that fetching data has begun
        dispatch(action(AsnInfoActionTypes.FETCH_ASNINFO_REQUEST, undefined));

        // Call endpoint, dispatch success- or error-message, depending on server response
        // Submit the GET-request, expecting plain text as response type
        RestServiceUtils.jsonGET('/api/asninfo?asn='+asn+'&startTime='+startTime+'&endTime='+endTime,
            result => dispatch(action(AsnInfoActionTypes.FETCH_ASNINFO_SUCCESS, result)),
            error => dispatch(action(AsnInfoActionTypes.FETCH_ERROR, error.message))
        );
    }
}

