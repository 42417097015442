import {ASRankState, ASRankActionTypes, ASRankActions} from './types';
import { StoreUtils } from '@iva/store-utils';
import {Feature, FeatureCollection} from "geojson";

// Initial state
const initialState: ASRankState = {
  asRankData : undefined,
};



// The reducer function
export function asRankReducer(state = initialState, action: ASRankActions): ASRankState {
  switch (action.type) {

    case ASRankActionTypes.FETCH_ASRANK_DATA_SUCCESS: {
      let newASRank = action.payload;

      if(state.asRankData != undefined){
        for (let entry in state.asRankData as any){
          // @ts-ignore
          newASRank[entry] = state.asRankData[entry]
        }
      }

      return { ...state, asRankData : newASRank};
    }

    default: {
      return state;
    }
  }
};
