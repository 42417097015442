
import * as d3 from "d3";
import {setFilterFrom, setFilterTo} from "../../../../store/filter/actions";
import {format} from "date-fns";

export class TimeFilter {
  constructor (node, dispatch, theme, data, options) {
    this.node = node;
    this.options = {...this.getDefaultOptions(), ...options};
    this.width = this.options.width || node.offsetWidth;
    this.height = this.options.height || node.offsetHeight;
    this.data = this.formatData(data || {});
    this.dispatch = dispatch;
    this.theme = theme;
    this.init();
  }

  getDefaultOptions() {
    return {
      padding: {top: 2, right: 15, bottom: 20, left: 18},
    };
  }


  init() {
    const {padding} = this.options;
    this.width = this.width - padding.left - padding.right;
    this.height = this.height - padding.bottom - padding.top;
    this.maxTime = 0;
    this.minTime = Number.MAX_VALUE;
    this.tooltipWidth = 150;

    this.svg = d3.select(this.node)
      .append('div')
      .style('width', '100%')
      .style('height', '100%')
      .append('svg')
      .attr("class", "wrapper")
      .style('width', '100%')
      .style('height', '100%')
      .style('display', 'block')

    this.context = this.svg.append('g');
    this.context.attr('class', 'overview')
      .attr('width', this.width)
      .attr('height', this.height)
      .attr('transform', 'translate(' + padding.left + ',' + (padding.top) + ')');

    // AXIS
    let formatMillisecond = d3.timeFormat(".%L"),
      formatSecond = d3.timeFormat(":%S"),
      formatMinute = d3.timeFormat("%H:%M"),
      formatHour = d3.timeFormat("%H:%M"),
      formatDay = d3.timeFormat("%a %d"),
      formatWeek = d3.timeFormat("%b %d"),
      formatMonth = d3.timeFormat("%B"),
      formatYear = d3.timeFormat("%Y");

// Define filter conditions
    function multiFormat(date) {
      return (d3.timeSecond(date) < date ? formatMillisecond
        : d3.timeMinute(date) < date ? formatSecond
          : d3.timeHour(date) < date ? formatMinute
            : d3.timeDay(date) < date ? formatHour
              : d3.timeMonth(date) < date ? (d3.timeWeek(date) < date ? formatDay : formatWeek)
                : d3.timeYear(date) < date ? formatMonth
                  : formatYear)(date);
    }

    this.stackedContext = this.context.append('g');
    this.xScaleContext = d3.scaleTime().range([0, this.width]);
    this.yScaleContext = d3.scaleLinear().range([this.height, 0]);
    this.xAxisContext = d3.axisBottom(this.xScaleContext).tickFormat(multiFormat);
      // .ticks(5);

    this.brush = d3.brushX();
    this.brush
      .extent([[0, 0], [this.width, this.height]])
      .on('brush', this.brushMove.bind(this))
      .on('end', this.brushEnd.bind(this));
    this.brushContainer = this.context.append('g')
      .attr('class', 'brush')
      .attr("height", this.height)
      .attr("width", this.width);
    this.brushContainer.call(this.brush);

    this.contextXAxis = this.context.append('g')
      .attr('class', 'x axis')
      .attr('transform', 'translate(0,' + this.height + ')');

    this.contextXAxis.call(this.xAxisContext);

    this.tooltip = d3.select(this.node).append("div")
      .style("top", 0)
      .style("width", this.tooltipWidth)
      .style("padding", "3px")
      .style("background", "rgba(0, 0, 0, 0.8)")
      .style("position", "absolute")
      .style("visibility", "hidden")
      .style("border-radius","2px")
      // .style("background-color", "#4f4f4f");

    let brushRange = [0,this.width];
    this.context.select('.brush')
      .transition()
      .duration(750)
      .call(this.brush.move, brushRange);

  }

  initVariables(){

  }

  updateData(data, bucketSize, minTime, maxTime, color) {

    this.color = color;
    // let extent = d3.extent(data, function (d) {return d.time});
    let extent = [];
    this.maxTime = maxTime;
    this.minTime = minTime;
    extent[0] = this.minTime;
    extent[1] = this.maxTime;
    let xDom = this.xScaleContext.domain(extent);
    this.xAxisContext.scale(xDom);
    this.contextXAxis.call(this.xAxisContext);
    this.xDomContext = xDom;
    this.data = data;
    this.bucketSize = bucketSize;

    let maxVolume = d3.max(data, function(d) { return d.announce+d.withdraw+d.highlight; });
    let yDom = this.yScaleContext.domain([0, maxVolume]).nice();
    this.yDomContext = yDom;

    let calcWidth = xDom(extent[0]+bucketSize);

    let colors = {announce: this.theme.palette.primary.main, withdraw: this.theme.palette.secondary.main, highlight:color.highlight};
    this.keys = ["announce", "withdraw"];

    let stack = d3.stack()
      .keys(this.keys)
      .order(d3.stackOrderNone)
      .offset(d3.stackOffsetNone);
    let stackedContext = this.stackedContext;

    this.keys.forEach((key, key_index) => {

      let bar = stackedContext.selectAll(".bar-" + key)
        .data(stack(data)[key_index], function(d){ return d.data.time + "-" + key; });

      bar.exit()
        .transition()
        .duration(500)
        .delay(function (d, i) {
          return Math.floor(i / 10)*50;
        })
        .attr("y", yDom(0))
        .attr("height", 0)
        .remove();

      bar
        .transition()
        .duration(750)
        .attr("height", function(d){ return yDom(d[0]) - yDom(d[1]); })
        .attr("y", function(d){ return yDom(d[1]); })
        .attr("x", function(d){ return xDom(d.data.time); })
        .attr("width", calcWidth);


      // modifiy new bars
      bar.join("rect")
        .attr("class", "bar bar-" + key)
        .attr("x", function(d){ return xDom(d.data.time); })
        .attr("width", calcWidth)
        .attr("fill",  colors[key])
        .attr("y", yDom(0))
        .attr("height", 0)
        .transition()
        .duration(750)
        .attr("stroke", d => {
          if(d.data.highlight) return color.highlight; else return "none";
      })
        .attr("stroke-width", d => {
          if(d.data.highlight) return 2; else return "1";
        })
        .attr("y", function(d){ return yDom(d[1]); })
        .attr("height", function(d){ return yDom(d[0]) - yDom(d[1]); });
    });

    let tooltip = this.tooltip;
    this.context.select('.brush').on("mousemove", this.mousemove.bind(this))
      .on("mouseover", function() { tooltip.style("top", -5000+"px"); tooltip.style("visibility", "visible"); })
      .on("mouseout", function() { d3.selectAll(".bar").attr("stroke", d => {
        if(d.data.highlight) return color.highlight; else return "none"}); tooltip.style("visibility", "hidden"); });

  }

  updateTheme(theme){
  }

  updateDimensions (node) {
  }

  formatData(data) {
  }

  mousemove (event) {
      let bisectDate = d3.bisector(function(d) {return d.time;}).left;
      let x0 = this.xScaleContext.invert(d3.pointer(event));
      let i = bisectDate(this.data, x0, 0),
        d0 = this.data[i - 1],
        d1 = this.data[i];
      if(!d0){
        d0 = d1;
        d1 = {time: d0.time+this.bucketSize};
      }
      if(!d1){
        d1 = {time: d0.time+this.bucketSize};
      }
      d3.selectAll(".bar").attr("stroke", "none");
      let bar = d3.selectAll(".bar").filter((e) => {return d0.time === e.data.time});
      bar.attr("stroke", "#FFF");
      bar.attr("stroke-width", 2);
      let xPosition = d3.pointer(event) - 15;
      if (xPosition + this.tooltipWidth > this.width){
        xPosition = (this.width - this.tooltipWidth)
      }
    this.tooltip.style("top", -85 +"px").style("left",(xPosition)+"px");
      // tooltip.attr("transform", "translate(" + xPosition + "," + yPosition + ")");
    let d0String = format(d0.time, "dd. MMM yyyy HH:mm:ss");
    let d1String = format(d1.time, "dd. MMM yyyy HH:mm:ss");

    // this.tooltip.html("<span>"+d0String+" - </span><br/><span>"+d1String+"</span><br/><span>Announcements: "+d0.announce+ "</span><br/><span>Withdraws: "+d0.withdraw+"</span>");
    this.tooltip.html("<span>"+d0String+" - </span><br/><span>"+d1String+"</span><br/><span>Announcements: "+d0.announce+ "<br/>" +
      "Withdraw: "+d0.withdraw+ "<br/>" +"Highlight: "+d0.highlight+"</span>");

  }

  brushMove (event) {
    if(this.data) {
      let paths = this.data.reduce((a, b) => a + (b.announce || 0), 0);
      if (paths < 100) {
        this.tooltip.style("visibility", "hidden");
        d3.selectAll(".bar").attr("stroke", d => {
          if (d.data.highlight) return this.color.highlight; else return "none"
        });
        // this.context.select('.brush').selectAll("text").attr("x", s[0]+(s[1]-s[0])/2);
        if (event.sourceEvent == null) return; // to skip initial events on creation

        const x = event.selection || this.xScaleContext.range();

        if (event.sourceEvent == null || this.noData) return; // to skip initial events on creation and brushing without data
        let currentTimeStart = this.xScaleContext.invert(x[0]).getTime();
        let currentTimeEnd = this.xScaleContext.invert(x[1]).getTime();

        this.dispatch(setFilterFrom(currentTimeStart));
        this.dispatch(setFilterTo(currentTimeEnd));
      }
    }

  }

  brushEnd(event){
    if(this.data) {
      let paths = this.data.reduce((a, b) => a + (b.announce || 0), 0);
      if (paths >= 100) {
        const s = event.selection || this.xScaleContext.range();
        if (event.sourceEvent == null || this.noData) return; // to skip initial events on creation and brushing without data

        let currentTimeStart = this.xScaleContext.invert(s[0]).getTime();
        let currentTimeEnd = this.xScaleContext.invert(s[1]).getTime();

        this.dispatch(setFilterFrom(currentTimeStart));
        this.dispatch(setFilterTo(currentTimeEnd));
      }
    }
  }

  render() {
  }


}

