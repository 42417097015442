import {DataCenterMapAction, DataCenterMapActionTypes, DataCenterMapState} from "./types";

// Initial state
const initialState: DataCenterMapState = {
  dataCenterMapData: undefined,
  dataCenterMapDataAS: undefined,
  fetchError: "",
  fetching: false,
};

export function dataCenterMapReducer(state = initialState, action: DataCenterMapAction): DataCenterMapState {
  switch (action.type) {

    case DataCenterMapActionTypes.FETCH_DATACENTERMAP_REQUEST: {
      return {...state, fetching: true};
    }
    case DataCenterMapActionTypes.FETCH_DATACENTERMAP_SUCCESS: {
      return {...state, fetching: false, dataCenterMapData: action.payload};
    }
    case DataCenterMapActionTypes.FETCH_DATACENTERMAP_AS_REQUEST: {
      return {...state, fetching: true};
    }
    case DataCenterMapActionTypes.FETCH_DATACENTERMAP_AS_SUCCESS: {
      return {...state, fetching: false, dataCenterMapDataAS: action.payload};
    }
    case DataCenterMapActionTypes.FETCH_DATACENTERMAP_ERROR: {
      return {...state, fetching: false, dataCenterMapData: undefined, dataCenterMapDataAS: undefined, fetchError: action.payload};
    }

    default: {
      return state;
    }
  }
}
