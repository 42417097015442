import React, {FC} from 'react';

import {
  AppBar,
  Toolbar,
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@material-ui/core';
import { Routes } from 'config/Routes';
import {Link, useLocation} from "react-router-dom";

// @ts-ignore
import igdImage from '../../../assets/igd.png';
// @ts-ignore
import atheneImg from '../../../assets/athene_en.png';

// Style definitions
const useStyles = makeStyles((theme: Theme) => createStyles({
  whiteText: {
    color: 'white',
    textTransform: 'none',
  },
  flex: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  buttonLabel: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.typography.caption.fontSize,
    textTransform: "none"
  },
}))

const Header: FC = () => {
  // Get the styles defined above
  const classes = useStyles();
  let location = useLocation();

  return (
    <>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar variant="dense">
          <Typography className={classes.whiteText} style={{marginRight: 10 }}>BGP Analysis and GeoTrace</Typography>
          <Tabs
            value={location.pathname}
            indicatorColor="primary"
            textColor="primary">
            <Tab className={classes.whiteText} component={Link} label="Home" to={Routes.Root} value={Routes.Root}/>
            <Tab className={classes.whiteText} component={Link} label="BGP Analysis" to={Routes.Analysis} value={Routes.Analysis}/>
            <Tab className={classes.whiteText} component={Link} label="GeoTrace" to={Routes.Map} value={Routes.Map}/>
          </Tabs>
          <div className={classes.flex} />
          {/*<ThemeConfigComponent />*/}
          <a target="_blank" href={'https://www.athene-center.de/en/'}>
            <img style={{marginTop: 2, marginBottom: 2, paddingRight: 20}} height={40} src={atheneImg}/>
          </a>

          <a target="_blank" href={'https://www.igd.fraunhofer.de/'}>
            <img style={{marginTop: 2, marginBottom: 2,paddingRight: 15}} height={40} src={igdImage} />
          </a>
        </Toolbar>
      </AppBar>
    </>
  );

}

export default Header;
