import React, {FC, forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {BGPDataCollection} from "@pages/BGPDashboard/models/BGPDataCollection";
import {ASLinkChart} from "./Vis/ASLinkChart";
import {AsnInfoData} from "../../../store/asnInfo/types";
import {filterData} from "@pages/BGPDashboard/InteractionsComponent";
import {CollectorBarChart} from "@pages/BGPDashboard/Vis/CollectorBarChart";
import {FilterStatus} from "@pages/BGPDashboard/models/FilterStatus";
import {fetchAsnInfo} from "../../../store/asnInfo/actions";

const ASLinkComponent = forwardRef((props, ref) => {

  const refContainer = useRef(null);
  const [chart, setChart] = useState(null);
  const bgpData = useSelector((state: ApplicationState) => state.bgpData.rawData);
  const filterTimeFrom = useSelector((state: ApplicationState) => state.filter.filterTimeFrom);
  const filterTimeTo = useSelector((state: ApplicationState) => state.filter.filterTimeTo);
  const filterState = useSelector((state: ApplicationState) => state.bgpData.filter);
  const filterID = useSelector((state:ApplicationState) => state.bgpData.highlightData);
  const asRankData = useSelector((state: ApplicationState) => state.asRank.asRankData);
  const asnInfoData = useSelector((state: ApplicationState) => state.asnInfo.asnInfoData);
  const colorState = useSelector((state: ApplicationState) => state.bgpData.colorState);
  const dateFrom = useSelector((state:ApplicationState) => state.bgpData.prefixDateFrom);
  const dateTo = useSelector((state:ApplicationState) => state.bgpData.prefixDateTo);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    updateDimensions() {
      // @ts-ignore
      chart?.updateDimensions();
    }
  }));

  useEffect(() => {
    // runs on destruct/unmount
    return () => {
      // @ts-ignore
      if (chart?.cleanUp) chart?.cleanUp();
    }
  }, [chart])

  const updateChart = (chart: ASLinkChart | null, bgpData: BGPDataCollection | undefined, filterTimeFrom: number, filterTimeTo: number, filter: FilterStatus[] | undefined, highlights: number[] | undefined) => {
    if (bgpData?.collection && chart) {
      let chartData = filterData(bgpData, filterTimeFrom, filterTimeTo, filter);
      if (chartData) {
        chartData = chartData.filter(d => d.bgpData.type == "ANNOUNCEMENT")

        let finalData: { nodes: any[]; links: any[] } = {nodes: [], links: []};
        let nodes:any = [];
        let links:any = [];


           chartData.map(e => {
          let path = e.bgpData.asPath
          if (path) {
            let previous = "";
            let group = 1;
            path.map((as,index) => { // iterate trough each AS

              if(!nodes.includes(parseInt(as))){
                let tier = 3;
                // @ts-ignore
                if (asRankData && asRankData[as]) {
                  // @ts-ignore
                  tier = asRankData[as].tier;
                }
                let rank: number | string = "unknown";
                let customerConeSize: number | string = "unknown";

                if (asRankData && parseInt(as) != -1 && asRankData[parseInt(as)]) {
                  tier = asRankData[parseInt(as)].tier
                  rank = asRankData[parseInt(as)].rank;
                  customerConeSize = asRankData[parseInt(as)].customerConeSize;
                }

                if(index == path.length-1) group = 0;
                const newNode = {
                  id: as,
                  group: group,
                  tier: tier,
                  rank: rank,
                  cCsize: customerConeSize,
                }
                nodes.push(parseInt(as));
                finalData.nodes.push(newNode);
              }


              if (previous != "" && previous != as) { // if not the first and the same as
                if (finalData.links.some((d: { source: string, target: string, value: number }) => {
                  return ((d.source == previous) && (d.target == as));
                })){
                  const sameLink = finalData.links.find((f: { source: string, target: string, value: number, ids: any[] }) => (f.source == previous) && (f.target == as));
                  if (sameLink) {
                    sameLink.value = sameLink.value + 1;
                    if(!sameLink.ids.includes(e.id)) sameLink.ids.push(e.id)
                    previous = as;
                  }
                  } else {
                    finalData.links.push({source: previous, target: as, value: 1, highlight:false, ids: [e.id]})
                    previous = as;
                  }
                }
              else previous = as;
          })
          }
        })

        if(highlights){
          finalData.links.map((l:any) => {
            if(l.ids)
            l.ids.forEach((i:number) => {
              if(highlights.includes(i)) l.highlight = true
            })
          })
        }


        chart.updateData(finalData, dispatch, colorState!, dateFrom, dateTo);
      }
    }
  }

  const updateInfo = (chart: ASLinkChart | null, info: any) => {
    chart?.updateInfo(info);
  }

  useEffect(() => {
    const initializeChart = ({setChart, refContainer}: any) => {
      const chart = new ASLinkChart(refContainer.current);
      setChart(chart);

    };
    if (!chart) initializeChart({setChart, refContainer});
  }, [chart]);

  useEffect(() => {
    if (chart != null) {
      updateChart(chart, bgpData, filterTimeFrom, filterTimeTo, filterState, filterID)
    }
  }, [bgpData,filterTimeFrom, filterTimeTo, filterState, filterID])

  useEffect(() => {
    if(chart != null) {
      updateInfo(chart,asnInfoData);
    }
  }, [asnInfoData]);


  return (
    <>
      <div ref={refContainer} style={{height: "100%"}}>
      </div>
    </>
  );
});

export default ASLinkComponent;
