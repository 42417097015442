import { AsyncResource, Action, AsyncFetchAction } from '@iva/store-utils';

// Definition of the response from the backend server
export interface TimeResponse {
  timezone1: string;
  timezone2: string;
  time1: string;
  time2: string;
  difference: number;
}

// State definition
export interface DemoState {
  // Used to persist the UI state (user selections)
  readonly selectedTimezone1: string;
  readonly selectedTimezone2: string;

  // Used for fetching a resource via RestServiceUtils
  readonly fetching: boolean;
  readonly data?: string;
  readonly fetchError?: string;

  // Used for fetching a resource via StoreUtils
  readonly time: AsyncResource<TimeResponse>;
}

// Action types definition
export enum DemoActionTypes {

  SET_TIMEZONE1 = '@@demo/SET_TIMEZONE1',
  SET_TIMEZONE2 = '@@demo/SET_TIMEZONE2',

  FETCH_REQUEST = '@@demo/FETCH_REQUEST',
  FETCH_SUCCESS = '@@demo/FETCH_SUCCESS',
  FETCH_ERROR = '@@demo/FETCH_ERROR',

  FETCH_MANAGED = '@@demo/FETCH_MANAGED',
}

// Actions definition
export type SelectTimezone1Action = Action<DemoActionTypes.SET_TIMEZONE1, string>;
export type SelectTimezone2Action = Action<DemoActionTypes.SET_TIMEZONE2, string>;

// Actions for manual fetching resources
export type FetchTimeRequestAction = Action<DemoActionTypes.FETCH_REQUEST>;
export type FetchTimeSuccessAction = Action<DemoActionTypes.FETCH_SUCCESS, string>;
export type FetchTimeErrorAction = Action<DemoActionTypes.FETCH_ERROR, string>;

// Actions for managed fetching resources
export type FetchManagedTimeAction = AsyncFetchAction<DemoActionTypes.FETCH_MANAGED, TimeResponse>;

export type DemoActions = SelectTimezone1Action | SelectTimezone2Action | FetchTimeRequestAction | FetchTimeSuccessAction | FetchTimeErrorAction | FetchManagedTimeAction;
