import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'typeface-roboto';

import { ApplicationState } from 'store/index';
import { ThemeOptions } from "@material-ui/core/styles/createTheme";


// Utility function to create a custom MUI theme
function createMyTheme(options: ThemeOptions) {
  return createTheme({
    ...options,
  })
}

// Extend MuiTheme with custom properties in the Typescript way!
// Example code for a custom color integration into the material theme
declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    fontColors: {
      grey: string
      customColor2: string
    }
  }
  // Allow configuration using `createMuiTheme`
  interface ThemeOptions {
    fontColors?: {
      grey?: string
      customColor2?: string
    }
  }
}

/*********************************************************************
 *                          The dark theme
 ********************************************************************/

const darkTheme = createMyTheme({
  // Define primary and secondary color
  palette: {
    type: 'dark',
    primary: {
      main: "#009374",
    },
    secondary: {
      main: "#4869bf",
    }
  },
  // Example code for a custom color integration into the material theme
  fontColors: {
    grey: "#b4b4b4",
    customColor2: "#62a460",
  },
  // Example code for a global style override of a material ui component
  // (Custom color for links, and the AppBar) 
  overrides: {
    MuiLink: {
      root: {
        color: "#7daee1",
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#000000",
      }
    },
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '0.7em'
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,0.4)',
          outline: '1px solid slategrey'
        }
      }
    }
  },
  // End example code
});

/*********************************************************************
 *                          The light theme
 ********************************************************************/

const lightTheme = createMyTheme({
  // Define primary and secondary color
  palette: {
    type: 'light',
    primary: {
      main: "#009374",
    },
    secondary: {
      main: "#8a0000",
    },
  },

  // Example code for a custom color integration into the material theme
  fontColors: {
    grey: "#4a88c8",
    customColor2: "#71c86f",
  },
  // Example code for a global style override of a material ui component
  // (Custom color for links) 
  overrides: {
    MuiLink: {
      root: {
        color: "#356cb1",
      },
    },
    MuiCssBaseline: {
    '@global': {
      '*::-webkit-scrollbar': {
        width: '0.7em'
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.4)',
        outline: '1px solid slategrey'
      }
    }
  }
  },
  // End example code
});


const ThemeProvider: FC = (props) => {
  const type = useSelector((state: ApplicationState) => state.applicationLayout.type);

  function getTheme() {
    if (type === "dark")
      return darkTheme;
    else
      return lightTheme;
  }

  return (
    <MuiThemeProvider theme={getTheme()}>
      <CssBaseline />
      {props.children}
    </MuiThemeProvider>
  );
}


export default ThemeProvider;
