import {makeStyles} from "@material-ui/core/styles";
import React, {FC, MutableRefObject, useEffect, useRef, useState} from 'react';
import ReactGridLayout, {
  Layout,
  //LayoutITem,
  Responsive, WidthProvider} from 'react-grid-layout';
import {
  Button,
  Paper,
  Tooltip,
  Typography,
  withStyles
} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import TimeFilterComponent from "@pages/BGPDashboard/TimeFilterComponent";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {AsnInfoData} from "../../../store/asnInfo/types";
import CollectorComponent from "@pages/BGPDashboard/CollectorComponent";
import QueryComponent from "@pages/BGPDashboard/QueryComponent";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import TypeChartComponent from "@pages/BGPDashboard/TypeComponent";
import BGPTable from "@pages/BGPDashboard/BGPTable";
import MiniMapComponent from "@pages/BGPDashboard/MiniMapComponent";
import OriginComponent from "@pages/BGPDashboard/OriginComponent";
import NextHopComponent from "@pages/BGPDashboard/NextHopComponent";
import PathLengthComponent from "@pages/BGPDashboard/PathLengthComponent";
import DuplicatesComponent from "@pages/BGPDashboard/DuplicatesComponent";
import TierPatternComponent from "@pages/BGPDashboard/TierPatternComponent";
import InteractionsComponent from "@pages/BGPDashboard/InteractionsComponent";
import NodeLinkComponent from "@pages/BGPDashboard/ASLinkDiagram";
import CommunityComponent from "@pages/BGPDashboard/CommunityComponent";
import './style/tip.css'

// CSS Styles for Panel
const useStyles = makeStyles((theme) => ({
  popup: {
    padding: 0,
    backgroundColor: "none",
  },
  infoIcon: {
    cursor: "pointer",
    marginLeft: 5,
    marginRight: 5,
    color: "#009374"
  },
  layoutHead: {
    backgroundColor: "rgba(28,28,30,0.19)",
    cursor: "grab",
    padding: ".2em",
    display: "inline-flex",
    alignItems: "center",
  },
  layoutBody: {
    flexGrow: 1,
    height:0,
    overflow: "auto",
  },
  title : {
    fontSize: 14,
  },
  secondaryMainColor: {
    color: theme.palette.primary.main
  },
  btn : {
    color: "#009374",
    background: "none",
    border: "1px solid",
    borderRadius: "5px",
    margin: "0 0 0 10px",
    cursor: "pointer",
    "&:hover, &:focus, &:active": {
      color: "white",
      background: "#009374"
    }
  },
  componentStyle : {
    display:"flex",
    flexDirection:"column",
    height:"100%",
    background:"#2a2a2a",
    position:"relative",
    overflow: "hidden",
  }


}));

// HtmlToolTip Styles
const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);


const ResponsiveGridLayout = WidthProvider(Responsive);


const DashboardComponent: FC = () => {

  const filter = useSelector((state:ApplicationState) => state.bgpData.filter);

  const gridRefs: Map<String, MutableRefObject<any>> = new Map([
    ["map", useRef(null)],
    ["tier", useRef(null)],
    ["diagram", useRef(null)],
    ["type", useRef(null)],
    ["origin", useRef(null)],
    ["community", useRef(null)],
    ["collector", useRef(null)],
    ["nextHop", useRef(null)],
    ["duplicate", useRef(null)],
    ["length", useRef(null)],
    ["timeFilter", useRef(null)],
  ]);

  /** set the layout for dashboard components */
  const defaultLayouts : ReactGridLayout.Layouts = {
    xl: [
      { i: "map", w: 6, h: 15, x: 0, y: 0 },
      { i: "tier", w: 9, h: 15, x: 6, y: 0 },
      { i: "type", w: 2, h: 4, x: 0, y: 15 },
      { i: "origin",  w: 2, h: 4, x: 0, y: 19 },
      { i: "community", w: 4, h: 8, x: 2, y: 15 },
      { i: "collector", w: 4, h: 8, x: 6, y: 15 },
      { i: "nextHop", w: 4, h: 8, x: 10, y: 15 },
      { i: "duplicate", w: 4, h: 8, x: 14, y: 15 },
      { i: "length", w: 4, h: 8, x: 18, y: 15 },
      { i: "diagram", w: 9, h: 15, x: 15, y: 0 },
      { i: "filter", w: 2, h: 8, x: 22, y: 15 },
      { i: "timefilter", w: 24, h: 4, x: 0, y: 23, isResizable: false }
      ],
    lg: [
      {i: "map", x: 0, y: 0, w: 6, h: 7},
      {i: "tier", x: 6, y: 0, w: 9, h: 7},
      {i: "diagram", x: 15, y: 0, w: 9, h: 7},
      {i: "type", x: 0, y: 7, w: 2, h: 4},
      {i: "origin", x: 0, y: 11, w: 2, h: 4},
      {i: "community", x: 2, y: 7, w: 4, h: 8},
      {i: "collector", x: 6, y: 7, w: 4, h: 8},
      {i: "nextHop", x: 10, y: 7, w: 4, h: 8},
      {i: "duplicate", x: 14, y: 7, w: 4, h: 8},
      {i: "length", x: 18, y: 7, w: 4, h: 8},
      {i: "filter", x: 22, y: 7, w: 2, h: 8},
      {i: "timefilter", x: 0, y: 15, w: 24, h: 4, isResizable: false}
      ],
    sm: [
      {i: "map", x: 0, y: 0, w:8, h: 5},
      {i: "tier", x: 8, y: 0, w: 12, h: 5},
      {i: "diagram", x: 15, y: 5, w: 9, h: 10},
      {i: "type", x: 0, y: 5, w: 6, h: 5},
      {i: "origin", x: 0, y: 10, w: 6, h: 5},
      {i: "community", x: 0, y: 24, w: 12, h: 8},
      {i: "collector", x: 6, y: 5, w: 9, h: 10},
      {i: "nextHop", x: 12, y: 15, w: 12, h: 9},
      {i: "duplicate", x: 0, y: 15, w: 12, h: 9},
      {i: "length", x: 12, y: 24, w: 12, h: 8},
      {i: "filter", x: 20, y: 0, w: 4, h: 5},
      {i: "timefilter", x: 0, y: 32, w: 24, h: 4, isResizable: false}
      ],
    md: [
      {w: 6, h: 6, x: 0, y: 0, i: "map"},
      {w: 9, h: 6, x: 6, y: 0, i: "tier"},
      {w: 2, h: 4, x: 0, y: 6, i: "type"},
      {w: 2, h: 4, x: 0, y: 10, i: "origin"},
      {w: 4, h: 8, x: 2, y: 6, i: "community"},
      {w: 4, h: 8, x: 6, y: 6, i: "collector"},
      {w: 4, h: 8, x: 10, y: 6, i: "nextHop"},
      {w: 4, h: 8, x: 14, y: 6, i: "duplicate"},
      {w: 4, h: 8, x: 18, y: 6, i: "length"},
      {w: 9, h: 6, x: 15, y: 0, i: "diagram"},
      {w: 2, h: 8, x: 22, y: 6, i: "filter"},
      {w: 24, h: 4, x: 0, y: 14, i: "timefilter", isResizable: false}
    ],
    xs: [
      {w: 22, h: 5, x: 0, y: 0, i: "map"},
      {w: 22, h: 12, x: 0, y: 20, i: "tier"},
      {w: 11, h: 4, x: 0, y: 5, i: "type"},
      {w: 11, h: 4, x: 11, y: 5, i: "origin"},
      {w: 22, h: 11, x: 0, y: 9, i: "community"},
      {w: 22, h: 11, x: 0, y: 32, i: "collector"},
      {w: 22, h: 11, x: 0, y: 43, i: "nextHop"},
      {w: 22, h: 12, x: 0, y: 54, i: "duplicate"},
      {w: 22, h: 10, x: 0, y: 66, i: "length"},
      {w: 22, h: 6, x: 0, y: 76, i: "diagram"},
      {w: 2, h: 2, x: 22, y: 0, i: "filter"},
      {w: 24, h: 1, x: 0, y: 82, i: "timefilter", isResizable: false}
    ],
    xxs: [
      {w: 22, h: 5, x: 0, y: 0, i: "map"},
      {w: 22, h: 12, x: 0, y: 20, i: "tier"},
      {w: 11, h: 4, x: 0, y: 5, i: "type"},
      {w: 11, h: 4, x: 11, y: 5, i: "origin"},
      {w: 22, h: 11, x: 0, y: 9, i: "community"},
      {w: 22, h: 11, x: 0, y: 32, i: "collector"},
      {w: 22, h: 11, x: 0, y: 43, i: "nextHop"},
      {w: 22, h: 12, x: 0, y: 54, i: "duplicate"},
      {w: 22, h: 10, x: 0, y: 66, i: "length"},
      {w: 22, h: 6, x: 0, y: 76, i: "diagram"},
      {w: 2, h: 2, x: 22, y: 0, i: "filter"},
      {w: 24, h: 1, x: 0, y: 82, i: "timefilter", isResizable: false}
    ]
    };
  const getInitialLayouts = () => {
    const layouts = getLayoutsFromLS();
    if (layouts){
      return layouts;
    }
    return defaultLayouts;
  }
  const [layouts, setLayouts] = useState<ReactGridLayout.Layouts>(getInitialLayouts());
  const updateDimensions = () => {
    for (let [key, value] of gridRefs.entries()) {
      if(!value.current) continue;
      value.current.updateDimensions();
    }
  }
  const resetLayouts = () => {
    setLayouts(defaultLayouts);
  }

  const onLayoutChange = (layout: any, layouts: any) => {
    saveLayoutsToLS(layouts)
    setLayouts(layouts);

    updateDimensions();
  }

  const classes = useStyles();

  return(
    <>
      <div style={{position:"relative", width:"100%", zIndex:10, marginLeft: 10}}>
        <div className="layout-no-drag" style={{flexGrow:1}}>
          <QueryComponent>
            <Button onClick={resetLayouts}>Default Layout</Button>
          </QueryComponent>
        </div>
      </div>
      <ResponsiveGridLayout className="layout" layouts={layouts} onLayoutChange={onLayoutChange} autoSize={true}
                            breakpoints={{xl: 2000, lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                            rowHeight={32}
                            cols={{xl: 24, lg: 24, md: 24, sm: 24, xs: 24, xxs: 24}} draggableCancel ='.layout-no-drag' isResizable={true} style={{height: "100%"}}
      >
        {/*map component*/}
        <div key="map">
          <div className={classes.componentStyle}>
            <div className={classes.layoutHead}>
              <HtmlTooltip
                title={
                  <Paper elevation={0} className={"layout-no-drag"}>
                    <Typography
                      className={classes.secondaryMainColor}>Information:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>The map displays BGP routes. A legend is given to identify
                      next Hops and origin. Furthermore, collectors are shown as well.</Typography>
                    <Typography
                      className={classes.secondaryMainColor}>Interaction:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>A click on a route layer filters data by the selected routes.</Typography>
                  </Paper>
                }
                interactive
              >
                <InfoIcon className={classes.infoIcon} color="secondary" fontSize="small"/>
              </HtmlTooltip>
              <Typography className={classes.title}>
                Geolocated BGP Routes
              </Typography>
            </div>
            <MiniMapComponent ref={gridRefs.get("map")}/>
          </div>
        </div>

        {/*data visualization*/}
        <div key="tier">
          <div className={classes.componentStyle}>
            <div className={classes.layoutHead} style={{display:"inline-flex", alignItems: "center"}} >
              <HtmlTooltip
                title={
                  <Paper elevation={0}>
                    <Typography
                      className={classes.secondaryMainColor}>Information:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>A sankey diagram which displays the connection of ASes based on their tier level. The ASes are
                      represented by vertical rectangles. The strokes identify a connection between ASes.</Typography>
                    <Typography
                      className={classes.secondaryMainColor}>Interaction:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>A mouse over about a node or a stroke display more information.</Typography>
                  </Paper>
                }
                interactive
              >
                <InfoIcon className={classes.infoIcon} color="secondary" fontSize="small"/>
              </HtmlTooltip>
              <Typography className={classes.title}>
                Tier Pattern Visualization
              </Typography>
            </div>
            <div className={`${classes.layoutBody} ${"layout-no-drag"}`}>
              <TierPatternComponent ref={gridRefs.get("tier")}/>
            </div>
          </div>
        </div>

        {/*w/a type visualization*/}
        <div key="type">
          <div className={classes.componentStyle}>
            <div className={classes.layoutHead} style={{display:"inline-flex", alignItems: "center"}} >
              <HtmlTooltip
                title={
                  <Paper elevation={0}>
                    <Typography
                      className={classes.secondaryMainColor}>Information:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>Pie chart which presents the distribution between
                      the quantity of announcements and withdrawals.</Typography>
                    <Typography
                      className={classes.secondaryMainColor}>Interaction:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>A click on a portion of the chart and data will be filtered
                      by the values of the portion.</Typography>
                  </Paper>
                }
                interactive
              >
                <InfoIcon className={classes.infoIcon} color="secondary" fontSize="small"/>
              </HtmlTooltip>
              <Typography className={classes.title}>
                Type
              </Typography>
            </div>
            <div className={`${classes.layoutBody} ${"layout-no-drag"}`}>
              <TypeChartComponent ref={gridRefs.get("type")}/>
            </div>
          </div>
        </div>

        {/*origin donut visualization*/}
        <div key="origin">
          <div className={classes.componentStyle}>
            <div className={classes.layoutHead} style={{display:"inline-flex", alignItems: "center"}} >
              <HtmlTooltip
                title={
                  <Paper elevation={0}>
                    <Typography
                      className={classes.secondaryMainColor}>Information:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>Pie chart which represents distribution between the quantity of updates with
                      origin values of IGP, EGP and unknown.</Typography>
                    <Typography
                      className={classes.secondaryMainColor}>Interaction:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>A click on a portion of the chart and data will be filtered
                      by the value of the portion.</Typography>
                  </Paper>
                }
                interactive
              >
                <InfoIcon className={classes.infoIcon} color="secondary" fontSize="small"/>
              </HtmlTooltip>
              <Typography className={classes.title}>
                Origin
              </Typography>
            </div>
            <div className={`${classes.layoutBody} ${"layout-no-drag"}`}>
              <OriginComponent ref={gridRefs.get("origin")}/>
            </div>
          </div>
        </div>

        {/*community*/}
        <div key="community">
          <div className={classes.componentStyle}>
            <div className={classes.layoutHead} style={{display:"inline-flex", alignItems: "center"}} >
              <HtmlTooltip
                title={
                  <Paper elevation={0}>
                    <Typography
                      className={classes.secondaryMainColor}>Information:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>Bar chart which represents community tags ordered by their
                      quantity of their appearance in data.</Typography>
                    <Typography
                      className={classes.secondaryMainColor}>Interaction:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>A click on plus button filters data by the selected community tags. The - button removes
                      the selected community tag from data. The ! button highlights all visualizations in which the selected community tag is included.</Typography>
                  </Paper>
                }
                interactive
              >
                <InfoIcon className={classes.infoIcon} color="secondary" fontSize="small"/>
              </HtmlTooltip>
              <Typography className={classes.title}>
                {(filter) ? (filter.length > 0) ? ("Filtered Community Tags") : ("Top 15 Community Tags") : ("Top 15 Community Tags")}
              </Typography>
            </div>
            <div className={`${classes.layoutBody} ${"layout-no-drag"}`}>
              <CommunityComponent ref={gridRefs.get("community")}/>
            </div>
          </div>
        </div>

        {/*collector rank*/}
        <div key="collector">
          <div className={classes.componentStyle}>
            <div className={classes.layoutHead} style={{display:"inline-flex", alignItems: "center"}} >
              <HtmlTooltip
                title={
                  <Paper elevation={0}>
                    <Typography
                      className={classes.secondaryMainColor}>Information:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>Bar chart which represents the top 15 collectors indicated by their
                      quantity of their collected updates.</Typography>
                    <Typography
                      className={classes.secondaryMainColor}>Interaction:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>A click on plus button filters data by the selected collector. The - button removes
                      the selected collector from data. The ! button highlights all visualizations in which the selected collector is included.</Typography>
                  </Paper>
                }
                interactive
              >
                <InfoIcon className={classes.infoIcon} color="secondary" fontSize="small"/>
              </HtmlTooltip>
              <Typography className={classes.title}>
                {(filter) ? (filter.length > 0) ? ("Filtered Collectors") : ("Top 15 Collectors") : ("Top 15 Collectors")}
              </Typography>
            </div>
            <div className={`${classes.layoutBody} ${"layout-no-drag"}`}>
              <CollectorComponent ref={gridRefs.get("collector")}/>
            </div>
          </div>
        </div>

        {/*nextHop*/}
        <div key="nextHop">
          <div className={classes.componentStyle}>
            <div className={classes.layoutHead} style={{display:"inline-flex", alignItems: "center"}} >
              <HtmlTooltip
                title={
                  <Paper elevation={0}>
                    <Typography
                      className={classes.secondaryMainColor}>Information:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>Bar chart which represents top 15 next Hops indicated by their
                      quantity of their appearance in data.</Typography>
                    <Typography
                      className={classes.secondaryMainColor}>Interaction:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>A click on plus button filters data by the selected next Hop. The - button removes
                      the selected next Hop from data. The ! button highlights all visualizations in which the selected next Hop is included.</Typography>
                  </Paper>
                }
                interactive
              >
                <InfoIcon className={classes.infoIcon} color="secondary" fontSize="small"/>
              </HtmlTooltip>
              <Typography className={classes.title}>
                {(filter) ? (filter.length > 0) ? ("Filtered Next Hops") : ("Top 15 Next Hops") : ("Top 15 Next Hops")}
              </Typography>
            </div>
            <div className={`${classes.layoutBody} ${"layout-no-drag"}`}>
              <NextHopComponent ref={gridRefs.get("nextHop")}/>
            </div>
          </div>
        </div>

        {/*asDuplicate*/}
        <div key="duplicate">
          <div className={classes.componentStyle}>
            <div className={classes.layoutHead} style={{display:"inline-flex", alignItems: "center"}} >
              <HtmlTooltip
                title={
                  <Paper elevation={0}>
                    <Typography
                      className={classes.secondaryMainColor}>Information:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>Bar chart which represents top 15 AS paths which are duplicated in data.</Typography>
                    <Typography
                      className={classes.secondaryMainColor}>Interaction:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>A click on plus button filters data by the selected AS path. The - button removes
                      the selected AS path from data. The ! button highlights all visualizations in which the selected AS path is included.</Typography>
                  </Paper>
                }
                interactive
              >
                <InfoIcon className={classes.infoIcon} color="secondary" fontSize="small"/>
              </HtmlTooltip>
              <Typography className={classes.title}>
                {(filter) ? (filter.length > 0) ? ("Filtered AS Path Duplicates") : ("Top 15 AS Path Duplicates") : ("Top 15 AS Path Duplicates")}
              </Typography>
            </div>
            <div className={`${classes.layoutBody} ${"layout-no-drag"}`}>
              <DuplicatesComponent ref={gridRefs.get("duplicate")}/>
            </div>
          </div>
        </div>

        {/*length visualization*/}
        <div key="length">
          <div className={classes.componentStyle}>
            <div className={classes.layoutHead} style={{display:"inline-flex", alignItems: "center"}} >
              <HtmlTooltip
                title={
                  <Paper elevation={0}>
                    <Typography
                      className={classes.secondaryMainColor}>Information:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>Bar chart which represents the distribution of AS paths with
                      the same length of ASes in asPath attribute.</Typography>
                    <Typography
                      className={classes.secondaryMainColor}>Interaction:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>A click on a bar filters the data for AS paths which has the same length of ASes.</Typography>
                  </Paper>
                }
                interactive
              >
                <InfoIcon className={classes.infoIcon} color="secondary" fontSize="small"/>
              </HtmlTooltip>
              <Typography className={classes.title}>
                AS Path Length Distribution
              </Typography>
            </div>
            <div className={`${classes.layoutBody} ${"layout-no-drag"}`}>
              <PathLengthComponent ref={gridRefs.get("length")}/>
            </div>
          </div>
        </div>

        {/*node link diagram*/}
        <div key="diagram">
          <div className={classes.componentStyle}>
            <div className={classes.layoutHead} style={{display:"inline-flex", alignItems: "center"}} >
              <HtmlTooltip
                title={
                  <Paper elevation={0}>
                    <Typography
                      className={classes.secondaryMainColor}>Information:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>Node Link diagram in which nodes represent ASes and links represent connections
                      between ASes.</Typography>
                    <Typography
                      className={classes.secondaryMainColor}>Interaction:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>ASes can be dragged and dropped in different positions to get a desire
                      overview about the connections. With a mouse-scroll, it is also possible to zoom in the diagram.</Typography>
                  </Paper>
                }
                interactive
              >
                <InfoIcon className={classes.infoIcon} color="secondary" fontSize="small"/>
              </HtmlTooltip>
              <Typography className={classes.title}>
                AS Link Diagram
              </Typography>
            </div>
            <div className={`${classes.layoutBody} ${"layout-no-drag"}`}>
              <NodeLinkComponent ref={gridRefs.get("diagram")}/>
            </div>
          </div>
        </div>

        {/*filter*/}
        <div key="filter">
          <div className={classes.componentStyle}>
            <div className={classes.layoutHead} style={{display:"inline-flex", alignItems: "center"}} >
              <HtmlTooltip
                title={
                  <Paper elevation={0}>
                    <Typography
                      className={classes.secondaryMainColor}>Information:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>All interactions included adding filters, removing filters
                      and applying highlights, are displayed in this component. </Typography>
                    <Typography
                      className={classes.secondaryMainColor}>Interaction:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>Filters and highlights can be removed with a click on the x button.</Typography>
                  </Paper>
                }
                interactive
              >
                <InfoIcon className={classes.infoIcon} color="secondary" fontSize="small"/>
              </HtmlTooltip>
              <Typography className={classes.title}>
                Interactions
              </Typography>
            </div>
            <div className={`${classes.layoutBody} ${"layout-no-drag"}`}>
              <InteractionsComponent/>
            </div>
          </div>
        </div>

        {/*timefilter*/}
        <div key="timefilter">
          <div className={classes.componentStyle}>
            <div className={classes.layoutHead} style={{display:"inline-flex", alignItems: "center"}} >
              <HtmlTooltip
                title={
                  <Paper elevation={0}>
                    <Typography style={{fontWeight: "bold"}}>Protocol Stats:</Typography>
                    <Typography
                      className={classes.secondaryMainColor}>Information:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>Timeline displays all BGP updates which are requested in the query. Both Announcements and  </Typography>
                    Withdraws are shown.
                    <Typography
                      className={classes.secondaryMainColor}>Interaction:</Typography>
                    <Typography style={{fontSize: "small", textAlign: "justify"}}>The vertical axes of the timeline can be moved to contain a specific timeslot which would like to
                      examine in more detail. Further, it is also possible to move the box to cover particular BGP updates. A hover on a bar shows more information.</Typography>
                  </Paper>
                }
                interactive
              >
                <InfoIcon className={classes.infoIcon} color="secondary" fontSize="small"/>
              </HtmlTooltip>
              <Typography className={classes.title}>
                BGP Updates Timeline
              </Typography>
            </div>
            <div className={`${classes.layoutBody} ${"layout-no-drag"}`}>
              <TimeFilterComponent ref={gridRefs.get("timefilter")}/>
            </div>
          </div>
        </div>

      </ResponsiveGridLayout>

      <BGPTable/>
    </>
  )
}

function getLayoutsFromLS(): any {
  let lstr = localStorage.getItem("probgp-analysis-layouts");
  if (!lstr) {
    return null;
  }
  return JSON.parse(lstr);
}

function saveLayoutsToLS(value: any) {
  localStorage.setItem("probgp-analysis-layouts", JSON.stringify(value))
}

export default DashboardComponent;
