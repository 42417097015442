import {FeatureCollection} from "geojson";
import {Action} from "@iva/store-utils";

export interface DnsCenterState {
  readonly dnsCenterData: FeatureCollection | undefined,
  readonly dnsCenterDataAS: FeatureCollection | undefined,
  readonly fetchError: string,
  readonly fetchingDns: boolean,
}

export enum DnsCenterActionTypes {
  FETCH_DNSCENTER_REQUEST = '@@DnsCenter/FETCH_DNSCENTER_REQUEST',
  FETCH_DNSCENTER_SUCCESS = '@@DnsCenter/FETCH_DNSCENTER_SUCCESS',
  FETCH_DNSCENTER_AS_REQUEST = '@@DnsCenter/FETCH_DNSCENTER_AS_REQUEST',
  FETCH_DNSCENTER_AS_SUCCESS = '@@DnsCenter/FETCH_DNSCENTER_AS_SUCCESS',
  FETCH_DNSCENTER_ERROR = '@@DnsCenter/FETCH_DNSCENTER_ERROR',
}

export type FetchDnsCenterRequestAction = Action<DnsCenterActionTypes.FETCH_DNSCENTER_REQUEST>;
export type FetchDnsCenterSuccessAction = Action<DnsCenterActionTypes.FETCH_DNSCENTER_SUCCESS, FeatureCollection>;
export type FetchDnsCenterASRequestAction = Action<DnsCenterActionTypes.FETCH_DNSCENTER_AS_REQUEST>;
export type FetchDnsCenterASSuccessAction = Action<DnsCenterActionTypes.FETCH_DNSCENTER_AS_SUCCESS, FeatureCollection>;
export type FetchDnsCenterErrorAction = Action<DnsCenterActionTypes.FETCH_DNSCENTER_ERROR, string>;

export type DnsCenterAction =
  FetchDnsCenterRequestAction |
  FetchDnsCenterSuccessAction |
  FetchDnsCenterASRequestAction |
  FetchDnsCenterASSuccessAction |
  FetchDnsCenterErrorAction;
