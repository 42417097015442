import { Store, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import { History } from 'history';

// Import the state interface and combined reducers/sagas.
import { ApplicationState, rootReducer } from './store';

export default function configureStore(history: History): Store<ApplicationState> {
  const store = createStore(
    rootReducer(history),
    /* preloadedState */
    composeWithDevTools(
      applyMiddleware(thunk)
    )
  );

  // Hot reloading
  if ((module as any).hot) {
    // Enable Webpack hot module replacement for reducers
    (module as any).hot.accept('./store', () => {
      store.replaceReducer(rootReducer(history));
    });
  }

  return store;
}
