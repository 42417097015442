import React, {FC, useEffect, useState} from 'react';
import * as StompJs from '@stomp/stompjs';
import RandomString from "randomstring";
import SockJS from "sockjs-client"
import {Client, IFrame} from '@stomp/stompjs';
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../store";
import {
  registerASRankData, registerCollectorData,
  registerPrefixData,
  registerPrefixDataBatch,
  registerPrefixDataProgress,
  registerPrefixDataFinish, registerRawData, registerRawDataBatch, registerRawDataFinish,
  setSessionId
} from "../store/webSocket/actions";

const WebsocketManager : FC = () => {

  const [stompClient, setStompClient] = useState(new StompJs.Client({
    debug: function (str) {
      // console.log(str);
    },
    reconnectDelay: 2500,
    heartbeatIncoming: 1000,
    heartbeatOutgoing: 1000
  }));
  const sessionId = useSelector((state: ApplicationState) => state.websocket.sessionId);
  const prefixRequest = useSelector((state: ApplicationState) => state.websocket.prefixRequest);
  const dispatch = useDispatch();

  // Example push something over WS to server
  useEffect(() => {
    if(stompClient != null && stompClient.connected && prefixRequest.prefix.length > 0){
      stompClient.publish({
        destination: '/serverData/test',
        body: JSON.stringify(prefixRequest),
      });
    }
  }, [prefixRequest]); // listen to store variable / queue

  useEffect(() => {
    stompClient.webSocketFactory = () => {
      let id = "";
      if (sessionId.length === 0) {
        id = RandomString.generate(8);
        dispatch(setSessionId(id));
        console.log("sessionId: " + id );
      }
      else{
        id = sessionId;
      }

      let webSocket = new SockJS("/sock/dataService", [], {sessionId: () => (id)});
      webSocket.onclose = function () {
        console.log("webSocket.onclose")
      };

      webSocket.onerror = function () {
        console.log("webSocket.onclose")
      };
      return webSocket;
    };

    stompClient.onConnect = (frame: IFrame) => {
      console.log('Connected: ' + frame);
      // Listen to messages from the server -> store data in the store -> make other classes react to changes in the store
      stompClient.subscribe('/user/clientData/data', (data: any) => {
        dispatch(registerPrefixData(JSON.parse(data.body)));
      });
      stompClient.subscribe('/user/clientData/dataBatch', (data: any) => {
        dispatch(registerPrefixDataBatch(JSON.parse(data.body)));
      });
      stompClient.subscribe('/user/clientData/dataFinish', () => {
        dispatch(registerPrefixDataFinish());
      });
      stompClient.subscribe('/user/clientData/asrankdata', (data: any) => {
        dispatch(registerASRankData(JSON.parse(data.body)));
      });
      stompClient.subscribe('/user/clientData/collectorData', (data: any) => {
        dispatch(registerCollectorData(JSON.parse(data.body)));
      });
      // raw data added
     stompClient.subscribe('/user/clientData/rawData',(data: any) => {
        dispatch(registerRawData(JSON.parse(data.body)));
      });
      stompClient.subscribe('/user/clientData/rawDataBatch',(data: any) => {
        dispatch(registerRawDataBatch(JSON.parse(data.body)));
      });
      stompClient.subscribe('/user/clientData/rawDataFinish', () => {
        dispatch(registerRawDataFinish());
      });

      stompClient.subscribe('/user/clientData/prefixDataProgressBatch', (data: any) => {
        dispatch(registerPrefixDataProgress(JSON.parse(data.body)));
      });
    };

    stompClient.onStompError = (frame) => {
      console.log("OnStompError: ",frame)
    };

    stompClient.onDisconnect = (frame) => {
      console.warn('Websocket connection to server closed. Trying to reconnect...');
    };
    stompClient.appendMissingNULLonIncoming = true;

    console.log("activate stompClient...");
    // stompClient.debug = function(str: string) {
    //   console.log(str);
    // };
    stompClient.activate();
  }, []);

  return (
    <>

    </>
  );

};

export default WebsocketManager;
