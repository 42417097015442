import { GdprConfiguration } from '@iva/gdpr-component';

type ApplicationConfiguration = {
  title: string;
  gdpr: GdprConfiguration;
}

// Set global definitions here
const configuration: ApplicationConfiguration = {
  title: 'Progressive BGP Update Visualization',
  gdpr: {
    projectName: 'Progressive BGP Update Visualization',
    projectUrl: 'probgp.igd.fraunhofer.de',
    youtube: false,
    contactFormEnabled: false,
    webanalytics: {},
    social: {}
  }
};

export default configuration;
