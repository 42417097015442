import { Action } from '@iva/store-utils';
import {Feature, FeatureCollection} from "geojson";


// State definition
export interface GeoDataState {
  // Used for fetching a resource via RestServiceUtils
  readonly prefixDateFrom: number;
  readonly prefixDateTo: number;

  readonly prefixData?: FeatureCollection;
  readonly centerConnectionsData?: string;
  readonly fetching_raw: boolean;
  readonly fetching_centers: boolean;
  readonly fetching_connections: boolean;
  readonly fetching_prefixPaths: boolean;
  readonly fetching_geoip: boolean;
  readonly fetching_allDataCenters: boolean;
  readonly data_raw?: string;
  readonly data_clusters?: string;
  readonly fetchError?: string;

  readonly traceData?: FeatureCollection;
  readonly fetchingTrace : boolean;
  readonly traceSummary?: FeatureCollection;

  readonly geoIpData?: string;
  readonly allDataCenters?: string;

}

// Action types definition
export enum GeoDataActionTypes {
  SET_PREFIX_DATE_FROM = '@@geodata/SET_PREFIX_DATE_FROM',
  SET_PREFIX_DATE_TO = '@@geodata/SET_PREFIX_DATE_TO',

  FETCH_PREFIX_DATA_REQUEST = '@@geodata/FETCH_PREFIX_DATA_REQUEST',
  FETCH_PREFIX_DATA_SUCCESS = '@@geodata/FETCH_PREFIX_DATA_SUCCESS',
  FETCH_PREFIX_DATA_BATCH_SUCCESS = '@@geodata/FETCH_PREFIX_DATA_BATCH_SUCCESS',
  FETCH_PREFIX_DATA_FINISH = '@@geodata/FETCH_PREFIX_DATA_FINISH',

  FETCH_CENTERCONNECTIONS_REQUEST ='@@geodata/FETCH_CENTERCONNECTIONS_REQUEST',
  FETCH_CENTERCONNECTIONS_SUCCESS ='@@geodata/FETCH_CENTERCONNECTIONS_SUCCESS',
  FETCH_RAW_REQUEST = '@@geodata/FETCH_RAW_REQUEST',
  FETCH_RAW_SUCCESS = '@@geodata/FETCH_RAW_SUCCESS',
  FETCH_CENTERS_REQUEST = '@@geodata/FETCH_CENTERS_REQUEST',
  FETCH_CENTERS_SUCCESS = '@@geodata/FETCH_CENTERS_SUCCESS',
  FETCH_GEOIP_REQUEST = '@@geodata/FETCH_GEOIP_REQUEST',
  FETCH_GEOIP_SUCCESS = '@@geodata/FETCH_GEOIP_SUCCESS',
  FETCH_ALLDATACENTERS_REQUEST = '@@geodata/FETCH_ALLDATACENTERS__REQUEST',
  FETCH_ALLDATACENTERS_SUCCESS = '@@geodata/FETCH_ALLDATACENTERS__SUCCESS',
  FETCH_TRACE_DATA_REQUEST = '@@geodata/FETCH_TRACE_DATA_REQUEST',
  FETCH_TRACE_DATA_SUCCESS = '@@geodata/FETCH_TRACE_DATA_SUCCESS',
  FETCH_ERROR = '@@geodata/FETCH_ERROR',

  CLEAR_TRACE_DATA = '@@geodata/CLEAR_TRACE_DATA',
  CLEAR_TRACESUMMARY = '@@geodata/CLEAR_TRACESUMMARY',
  CLEAR_CENTERCONNECTIONS = '@@geodata/CLEAR_CENTERCONNECTIONS'
}

// Actions for manual fetching resources
export type FetchCenterConnectionsRequestAction = Action<GeoDataActionTypes.FETCH_CENTERCONNECTIONS_REQUEST>;
export type FetchCenterConnectionsSuccessAction = Action<GeoDataActionTypes.FETCH_CENTERCONNECTIONS_SUCCESS, string>;
export type FetchPrefixDataRequestAction = Action<GeoDataActionTypes.FETCH_PREFIX_DATA_REQUEST>;
export type FetchPrefixDataSuccessAction = Action<GeoDataActionTypes.FETCH_PREFIX_DATA_SUCCESS, Feature>;
export type FetchPrefixDataBatchSuccessAction = Action<GeoDataActionTypes.FETCH_PREFIX_DATA_BATCH_SUCCESS, Array<Feature>>;
export type FetchPrefixDataFinishAction = Action<GeoDataActionTypes.FETCH_PREFIX_DATA_FINISH, boolean>;
export type FetchAsnDataCenterRawRequestAction = Action<GeoDataActionTypes.FETCH_RAW_REQUEST>;
export type FetchAsnDataCenterRawSuccessAction = Action<GeoDataActionTypes.FETCH_RAW_SUCCESS, string>;
export type FetchAsnDataCenterRequestAction = Action<GeoDataActionTypes.FETCH_CENTERS_REQUEST>;
export type FetchAsnDataCenterSuccessAction = Action<GeoDataActionTypes.FETCH_CENTERS_SUCCESS, string>;
export type FetchGeoIpDataRequestAction = Action<GeoDataActionTypes.FETCH_GEOIP_REQUEST>;
export type FetchGeoIpDataSuccessAction = Action<GeoDataActionTypes.FETCH_GEOIP_SUCCESS, string>;
export type FetchAllDataCentersRequestAction = Action<GeoDataActionTypes.FETCH_ALLDATACENTERS_REQUEST>;
export type FetchAllDataCentersSuccessAction = Action<GeoDataActionTypes.FETCH_ALLDATACENTERS_SUCCESS, string>;
export type FetchTraceDataRequestAction = Action<GeoDataActionTypes.FETCH_TRACE_DATA_REQUEST>;
export type FetchTraceDataSuccessAction = Action<GeoDataActionTypes.FETCH_TRACE_DATA_SUCCESS, {tracePath: Array<Feature>, traceSummary: Array<Feature>}>;
export type FetchTimeErrorAction = Action<GeoDataActionTypes.FETCH_ERROR, string>;

export type ClearTraceDataAction = Action<GeoDataActionTypes.CLEAR_TRACE_DATA>;
export type ClearTraceSummary = Action<GeoDataActionTypes.CLEAR_TRACESUMMARY>;
export type ClearCenterConnections = Action<GeoDataActionTypes.CLEAR_CENTERCONNECTIONS>;

export type SetPrefixDateFromAction = Action<GeoDataActionTypes.SET_PREFIX_DATE_FROM, number>;
export type SetPrefixDateToAction = Action<GeoDataActionTypes.SET_PREFIX_DATE_TO, number>;


export type GeoDataActions =  FetchPrefixDataRequestAction |
  FetchPrefixDataSuccessAction |
  FetchPrefixDataBatchSuccessAction |
  FetchCenterConnectionsRequestAction |
  FetchCenterConnectionsSuccessAction |
  FetchAsnDataCenterRawRequestAction |
  FetchAsnDataCenterRawSuccessAction |
  FetchTimeErrorAction |
  FetchAsnDataCenterRequestAction |
  FetchPrefixDataFinishAction |
  SetPrefixDateFromAction |
  SetPrefixDateToAction |
  FetchAsnDataCenterSuccessAction |
  FetchGeoIpDataRequestAction |
  FetchGeoIpDataSuccessAction | ClearTraceDataAction | ClearTraceSummary | ClearCenterConnections |
  FetchAllDataCentersRequestAction | FetchTraceDataRequestAction | FetchTraceDataSuccessAction |
  FetchAllDataCentersSuccessAction;
