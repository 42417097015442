import React, { FC } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

import ThemeProvider from './ThemeProvider';
import Header from './Header';
import Footer from './Footer';
import Router from './Router';
import WebsocketManager from "../../websocket/WebsocketManager";
import {BrowserRouter} from "react-router-dom";

// Style definitions
const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    textAlign: 'left',
    paddingTop: theme.spacing(6),
    paddingBottom: '32px', // remove if not using footer
    marginLeft: '1%',
    marginRight: '1%',
  },
}))


const App: FC = () => {
  const classes = useStyles();

  return (
    <BrowserRouter>
      <ThemeProvider>
        <div className={classes.root}>
          <Header />
          <main>
            <Router />
            <WebsocketManager />
          </main>
          <Footer />
        </div>
      </ThemeProvider>
    </BrowserRouter>

  );
}

export default App;
