import { Action } from "@iva/store-utils";

// Definitions used in action objects
export type ThemeTypes = 'light' | 'dark';

// State definition
export interface ApplicationLayoutState {
  readonly type: ThemeTypes;
  readonly showTraceSummary: boolean;
  readonly showTraceConfidenceColors: boolean;
  readonly traceInputType: "Linux" | "Windows" | "BGP";
}

// Action types definition
export enum ApplicationLayoutActionTypes {
  SET_THEME = '@@applicationLayout/SET_THEME',
  SET_SHOW_TRACE_SUMMARY = '@@applicationLayout/SET_SHOW_TRACE_SUMMARY',
  SET_SHOW_TRACE_CONFIDENCE_COLORS = '@@applicationLayout/SET_SHOW_TRACE_CONFIDENCE_COLORS',
  SET_TRACE_INPUT_TYPE = '@@applicationLayout/SET_TRACE_INPUT_TYPE',
}

// Actions definition
export type SetThemeAction = Action<ApplicationLayoutActionTypes.SET_THEME, ThemeTypes>;
export type SetShowTraceSummaryAction = Action<ApplicationLayoutActionTypes.SET_SHOW_TRACE_SUMMARY, boolean>;
export type SetShowTraceConfidenceColorsAction = Action<ApplicationLayoutActionTypes.SET_SHOW_TRACE_CONFIDENCE_COLORS, boolean>;
export type SetTraceInputTypeAction = Action<ApplicationLayoutActionTypes.SET_TRACE_INPUT_TYPE, "Linux" | "Windows" | "BGP">;

export type ApplicationLayoutActions = SetThemeAction | SetShowTraceSummaryAction | SetShowTraceConfidenceColorsAction | SetTraceInputTypeAction;
