import * as d3 from "d3";
import {fetchAsnInfo} from "../../../../store/asnInfo/actions";
import "../style/nodelink.css"
import {SVGChart} from "@pages/BGPDashboard/Vis/SVGChart";
import {Dispatch} from "redux";
import {ColorState} from "@pages/BGPDashboard/models/ColorState";
import {fetchAsnDataCenters, setFilter} from "../../../../store/bgpData/actions";
import {Tooltip} from "@pages/BGPDashboard/Vis/Tooltip";
import {D3ZoomEvent} from "d3";

let savedPositions: any = null;
let asnInfo: any[] = [];

export class ASLinkChart extends SVGChart {

  private rootElem: any;
  private tip!: Tooltip;
  private zoom: any;
  private data?: any;
  private colorState?: ColorState;
  private dispatch?: Dispatch<any>;
  private dateFrom?: number;
  private dateTo?: number;
  private flag: boolean = false;
  private first: boolean = false;
  private info: any = {};


  constructor(node: HTMLElement) {
    super(node, 50, 50);
    this.init();
  }

  init(){
    this.rootElem = d3.select(this.svgElement)
      .append('g');

    this.tip = new Tooltip()
      .content((args: any[]) => {
        let [d] = args;
        if (d) {
          let asInfo: any = {};
          if (asnInfo) asInfo = asnInfo.find(info => {
            return d.id == info.asn
          })
          if (asInfo)
            return 'RIPE RIS Data (stat.ripe.net):' + '<br><br>' +
              '<span class="label">ASN:</span> ' + asInfo.asn + '<br>' +
              '<span class="label">Holder:</span> ' + asInfo.holder + '<br>' +
              '<span class="label">Country:</span> ' + asInfo.country + '<br>' +
              '<span class="label">RIR:</span> ' + asInfo.rir + '<br>' +
              '<span class="label">Registration:</span> ' + asInfo.registration + '<br>' +
              '<span class="label">Querytime:</span> ' + asInfo.querytime + '<br><br><br>' +
              'AS Rank Data (asrank.caida.org):' + '<br><br>' +
              '<span class="label">AS Tier:</span> ' + d.tier + '<br>' +
              '<span class="label">AS rank:</span> ' + d.rank + '<br>' +
              '<span class="label">AS Customer Cone Size:</span> ' + d.cCsize
          else return "...load"
        }

        return undefined;
      });

    const rootElem = this.rootElem;
    this.zoom =
      d3.zoom()
        .on('zoom', zoomed)

    d3.select(this.svgElement)
      .call(this.zoom);

    function zoomed(event: any) {
      rootElem.attr("transform", event.transform);
    }
  }

  cleanUp() {
    this.tip.destroy();
  }

  resizeChart(): void {
    this.drawGraph();
  }

  updateData(data: any, dispatch: Dispatch<any>, color: ColorState, dateFrom: number, dateTo: number) {
    this.data = data;
    this.dispatch = dispatch;
    this.colorState = color;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.drawGraph();
  }



  drawGraph() {
    if (!this.data || !this.colorState || !this.dispatch || !this.dateFrom || !this.dateTo) {
      return;
    }
    let data = this.data;
    let color = this.colorState;
    let dispatch = this.dispatch;
    let dateFrom = this.dateFrom;
    let dateTo = this.dateTo;

    if (savedPositions) {
      data.nodes.map((d: any) => {
        savedPositions.forEach((e: any) => {
          if (e.id == d.id) {
            d.fx = e.fx;
            d.fy = e.fy;
          }
        })
      })
    }

    let graphLayout = d3.forceSimulation()
      .nodes(data.nodes)
      .force("charge", d3.forceManyBody().strength(-500))
      //.force("center", d3.forceCenter(dimensions.boundedWidth / 2, dimensions.boundedHeight / 2))
      .force("x", d3.forceX(this.width / 2).strength(0.2))
      .force("y", d3.forceY(this.width / 2).strength(0.4))
      .force("link", d3.forceLink().id(function (d: any) {
        return d.id;
      }).distance(60).links(data.links))
      .stop();
    graphLayout.tick(50);

    if(!savedPositions) {
      savedPositions = data.nodes.map((elem: any) => {
        return {
          id: elem.id,
          fx: elem.x,
          fy: elem.y
        };
      });
    }

    data.nodes.forEach((d: any) => {
      if (!d.x) {
        d.x = this.width / 2 + (Math.random() - 0.5) * 75;
        d.y = this.height / 2 + (Math.random() - 0.5) * 75;
      }
    });

    let maxValue = 0;
    data.links.map((d: any) => {
      if (d.value > maxValue) maxValue = d.value;
    })
    let minValue = maxValue;
    data.links.map((d: any) => {
      if (d.value < minValue) minValue = d.value;
    })

    const link = this.rootElem
      .selectAll("line")
      .data(data.links)
      .join("line")
      .attr("x1", function (d: any) {
        return (d.source.x);
      })
      .attr("y1", function (d: any) {
        return (d.source.y);
      })
      .attr("x2", function (d: any) {
        return (d.target.x);
      })
      .attr("y2", function (d: any) {
        return (d.target.y);
      })
      .attr("class", "link")
      .style("stroke", (d: any) => {
        if (d.highlight) return color.highlight; else return "white";
      })
      .attr("opacity", (d: any) => {
        if (d.highlight) {
          return 1;
        } else return normalize(d.value) + 0.3
      })
      .style("stroke-width", 3)
      .lower()


    let as = this.rootElem
      .selectAll("circle")
      .data(data.nodes)
      .join("circle")
      .on("mouseover", (event: any, d: any) => {
        this.flag = false;
        this.tip.show(d);
        dispatch(fetchAsnInfo(d.id, dateFrom, dateTo));
        this.info = {d: d}
      })
      .on("mousemove", (event: any, d: any) => {
        this.tip.setPosition(event.pageY, event.pageX)
      })
      .on("mouseout", () => {
        this.tip.hide();
        this.flag = true;
      })
      .attr("class", "node")
      .attr("r", (d: any) => {
        if(d.group === 0) return 22;
        else return 12
      })
      .attr("cx", function (d: any) {
        return d.x;
      })
      .attr("cy", function (d: any) {
        return d.y;
      })
      .style("fill", (d: any) => {
        if (d.group === 0) return color.origin
        else if (d.tier == "3") return color.tier3
        else if (d.tier == "2") return color.tier2
        else return color.tier1
      })
      .call(d3.drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended));


    const label = this.rootElem
      .selectAll("text.label")
      .data(data.nodes)
      .join("text")
      .attr("class", "label")
      .attr("font-size", 14)
      .attr("fill", "#fff")
      .attr("transform", function (d: any) {
        return "translate(" + (d.x + 12) + "," + (d.y+5) + ")"
      })
      .text((d: any) => d.id);

    // if(!this.first) {
    //   this.zoomFit();
    //   this.first = true;
    // }

    let rootElem = this.rootElem;
    function dragstarted(this: any) {
      d3.select(this).raise();
      rootElem.attr("cursor", "grabbing");
    }

    function dragged(this:any, event: any, d: any) {
      d3.select(this).attr("cx", d.x = event.x).attr("cy", d.y = event.y);

      link.filter(function (l: any) {
        return l.source.id === d.id;
      }).attr("x1", d.x).attr("y1", d.y);
      link.filter(function (l: any) {
        return l.target.id === d.id;
      }).attr("x2", d.x).attr("y2", d.y);
      label.filter(function (l: any) {
        return l.id === d.id;
      }).attr("transform", function (d: any) {
        return "translate(" + (d.x + 12) + "," + (d.y+5) + ")"
      })
      savedPositions.forEach((s: any) => {
        if (s.id == d.id) {
          s.fx = d.x;
          s.fy = d.y;
        }
      })
    }

    function dragended(this: any) {
      rootElem.attr("cursor", "grab");
    }

    function normalize(x: number) {
      return (x - minValue) / (maxValue - minValue)
    }
  }

  updateInfo(info: { asn: any; }) {
    if(info) {
      if(asnInfo.every((d  => d.asn != info.asn))) {
        asnInfo.push(info);
      }
      if (!this.flag) {
        this.tip.show(this.info.d);
      }
    }
  }

  zoomFit() {
    const box = this.rootElem.node().getBBox();
    const scale = 0.95 / Math.max(box.width / this.width, box.height / this.height);
    let midX = box.x + this.width / 2, midY = box.y + this.width / 2;

    this.zoom.scaleTo(this.rootElem, scale)
    this.zoom.translateTo(this.rootElem,  this.width / 2 - scale * midX , this.height/ 2 - scale * midY)

    const wrapper = d3.select(this.svgElement);
    this.zoom.scaleTo(wrapper, scale)
    this.zoom.translateTo(wrapper,  this.width / 2 - scale * midX , this.height/ 2 - scale * midY)
  }
}
