import {SVGChart} from "@pages/BGPDashboard/Vis/SVGChart";
import * as d3 from "d3";
import {sankey, sankeyCenter, sankeyLinkHorizontal} from "d3-sankey";
import {ColorState} from "@pages/BGPDashboard/models/ColorState";
import "../style/sankey.css"
import {Tooltip} from "@pages/BGPDashboard/Vis/Tooltip";

const animationDuration = 750

export class TierPatternChart extends SVGChart {
  private tip!: Tooltip;

  private sankeyData?: { nodes: any[]; links: any[]};
  private colorState?: ColorState;

  // root 'g' element for sankey
  private rootElem: any;

  constructor(node: HTMLElement) {
    super(node, 400, 100);
    this.init();
  }

  init() {
    this.tip = new Tooltip()
      .content((args: any[]) => {
        let [d,type] = args;
        if(type === "node")
          return "Tier: " + d.name + "<br><br>" + "Count: " + d.count
        else return "Tier: " + d.source.name + " → " + d.target.name + "<br><br>" + "Count: " + d.count
      });

    this.rootElem = d3.select(this.svgElement).append('g')
      .attr('transform', `translate(25, 0)`);
  }

  cleanUp() {
    this.tip.destroy();
  }

  resizeChart(): void {
    this.drawSankey();
  }

  updateData(data: { nodes: any[]; links: any[]}, color?: ColorState) {
    data.nodes.forEach(d => d.value = 30)
    this.sankeyData = data;
    this.colorState = color;

    this.drawSankey();
  }

  // draw or redraw sankey
  drawSankey() {
    let data = this.sankeyData;
    let color = this.colorState;

    if (!data) {
      return;
    }

    let sank = sankey()
      .nodeId((d: any) => d.name)
      .nodeWidth(15)
      .nodePadding(10)
      .nodeAlign(sankeyCenter)
      .size([this.width-25, this.height])

    const formatNumber = d3.format(",.0f")// zero decimal places
    const format = (d: any) => {
      return formatNumber(d);
    }

    let graph = sank(data);

    let links = this.rootElem.selectAll("path.link")
      .data(graph.links)
      .join("path")
      .on("mouseover", (event:any, d: any) => {
        this.tip.show(d, "link")
      })
      .on("mousemove", (event: any, d: any) => {
        this.tip.setPosition(event.pageY, event.pageX)
      })
      .on("mouseout", () => this.tip.hide())
      .attr("class", "link")
      .transition()
      .duration(animationDuration)
      // @ts-ignore
      .attr("d", sankeyLinkHorizontal())
      .attr("stroke", (d: any): any => {
        if(d.highlight) return color?.highlight; else if (!d.init) return "white";
      })
      .attr("stroke-opacity", (d: any) =>  {if(d.highlight) return 0.7; else return 0.2})
      .attr("stroke-width", (d: any) => Math.max(1, d.width))
      .attr("fill", "none");

    let nodes = this.rootElem.selectAll("rect.node")
      .data(graph.nodes)
      .join("rect")
      .on("mouseover", (event:any, d: any) => this.tip.show(d, "node"))
      .on("mousemove", (event: any, d: any) => {
        this.tip.setPosition(event.pageY, event.pageX)
      })
      .on("mouseout", () => this.tip.hide())
      .attr("class", "node")
      .transition()
      .duration(animationDuration)
      .attr("id", (d: any) => `node-${d.index}`)
      .attr("x", (d: any) => d.x0)
      .attr("y", (d: any) => d.y0)
      .attr("width", sank.nodeWidth())
      .attr("height", (d: any) => {
        return d.y1 - d.y0;
      });

    nodes
      .transition()
      .duration(animationDuration)
      .attr("fill", (d: any) => {
        if(d.name.slice(-1) === "3") return "#e5e564"
        else if(d.name.slice(-1) === "2") return "#54cbac"
        else return "#efa361"
      })
      .attr("opacity", 0.9)


    this.rootElem.selectAll("text.node")
      .data(graph.nodes)
      .join("text")
      .classed("node", true)
      .attr("x", (d: any) => d.x1)
      .attr("dx", 6)
      .attr("y", (d: any) => (d.y1 + d.y0) / 2)
      .attr("dy", "0.35em")
      .attr("fill", "white")
      .attr("text-anchor", "start")
      .attr("font-size", 10)
      .attr("font-family", "Arial, sans-serif")
      .text((d: any) => d.name)
      .attr("x", (d: any) => d.x0)
      .attr("dx", -6)
      .attr("text-anchor", "end");
  }
}
