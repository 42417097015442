import React, {FC, forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {CollectorBarChart} from "../BGPDashboard/Vis/CollectorBarChart";
import {BGPDataCollection} from "@pages/BGPDashboard/models/BGPDataCollection";
import {filterData} from "../BGPDashboard/InteractionsComponent"
import {FilterStatus} from "@pages/BGPDashboard/models/FilterStatus";
import {CommunityBarChart} from "./Vis/CommunityBarChart";


const CommunityComponent = forwardRef((props, ref) => {

  const refContainer = useRef(null);
  const [chart, setChart] = useState(null);
  const bgpData = useSelector((state: ApplicationState) => state.bgpData.rawData);
  const colorState = useSelector((state: ApplicationState) => state.bgpData.colorState);
  const filterTimeFrom = useSelector((state: ApplicationState) => state.filter.filterTimeFrom);
  const filterTimeTo = useSelector((state: ApplicationState) => state.filter.filterTimeTo);
  const filterState = useSelector((state:ApplicationState) => state.bgpData.filter);
  const filterID = useSelector((state:ApplicationState) => state.bgpData.highlightData);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    updateDimensions() {
      // @ts-ignore
      chart?.updateDimensions();
    }
  }));

  useEffect(() => {
    // runs on destruct/unmount
    return () => {
      // @ts-ignore
      if (chart?.cleanUp) chart?.cleanUp();
    }
  }, [chart])

  const updateData = (chart: CommunityBarChart | null, bgpData: BGPDataCollection | undefined, filterTimeFrom: number, filterTimeTo: number, filter: FilterStatus[] | undefined, highlights: number[] | undefined) => {
    if (bgpData?.collection && chart) {
      let chartData = filterData(bgpData, filterTimeFrom, filterTimeTo, filter);
      if (chartData) {
        let temp: string[] = [];
        let finalData: any[] = [];
        chartData.map(e => {
          if (e.bgpData.communities) {
            e.bgpData.communities.map(piece => {
              if (!temp.includes(piece)) {
                const barElement = {
                  name: piece,
                  value: 1,
                  ids: [e.bgpData.id],
                  highlight: false
                }
                finalData.push(barElement)
                temp.push(piece);
              } else {
                const equal = finalData.find((f: { name: string; }) => f.name === piece);
                if (equal != null) {
                  equal.value = equal.value + 1;
                  equal.ids.push(e.bgpData.id)
                }
              }
            })
          }
        })

          finalData.map((d: any) => {
            d.ids.map((e: any) => {
              if (highlights)
                if (highlights.includes(e)) {
                  d.highlight = true;
                }
            })
          })

        finalData.sort((d: { value: number }, e: { value: number }) => {
          if ((d.value + d.value) > (e.value + e.value)) {
            return -1;
          }
          if ((d.value + d.value) < (e.value + e.value)) {
            return 1;
          }
          return 0;
        })

          let updateData = finalData.slice(0, 15)
          if (highlights) {
            let tmp: number[] = [-1];
            updateData.map((u: any) => {
              tmp = tmp.concat(u.ids)
            })
            highlights.map(h => {
              if (!tmp.includes(h)) {
                updateData = updateData.concat(finalData.filter((f: any) => f.ids.includes(h)))
              }
            })
            updateData.sort((d: { value: number }, e: { value: number }) => {
              if ((d.value + d.value) > (e.value + e.value)) {
                return -1;
              }
              if ((d.value + d.value) < (e.value + e.value)) {
                return 1;
              }
              return 0;
            })
            if (updateData.length > 15) {
              updateData = updateData.slice(0, 16);
            }

          }
        chart.updateData(updateData, dispatch, colorState!);

      }
      }
  }


  useEffect(() => {
    const initializeChart = ({setChart, refContainer}: any) => {
      const chart = new CommunityBarChart(refContainer.current);
      setChart(chart);
      if (bgpData) {
        updateData(chart, bgpData, filterTimeFrom, filterTimeTo, filterState, filterID);
      }
    };
    if (!chart) initializeChart({setChart, refContainer, dispatch});
  }, [chart]);

  useEffect(() => {
    if (chart != null) {
      updateData(chart, bgpData, filterTimeFrom, filterTimeTo, filterState, filterID)
    }
  }, [bgpData, filterState, filterTimeFrom, filterTimeTo, filterID])


  return (
    <>
      <div ref={refContainer} style={{height: '100%', width: '100%'}}>
      </div>
    </>
  );
});

export default CommunityComponent;
