import {HarbourCenterAction, HarbourCenterActionTypes, HarbourCenterState} from "./types";

// Initial state
const initialState: HarbourCenterState = {
  harbourCenterData: undefined,
  harbourCenterDataAS: undefined,
  cableData: undefined,
  fetchError: "",
  fetchingHarbour: false,
  fetchingCable: false,
};

export function harbourCenterReducer(state = initialState, action: HarbourCenterAction): HarbourCenterState {
  switch (action.type) {

    case HarbourCenterActionTypes.FETCH_HARBOURCENTER_REQUEST: {
      return {...state, fetchingHarbour: true};
    }
    case HarbourCenterActionTypes.FETCH_HARBOURCENTER_SUCCESS: {
      return {...state, fetchingHarbour: false, harbourCenterData: action.payload};
    }
    case HarbourCenterActionTypes.FETCH_HARBOURCENTER_AS_REQUEST: {
      return {...state, fetchingHarbour: true};
    }
    case HarbourCenterActionTypes.FETCH_HARBOURCENTER_AS_SUCCESS: {
      return {...state, fetchingHarbour: false, harbourCenterDataAS: action.payload};
    }
    case HarbourCenterActionTypes.FETCH_CABLE_DATA_REQUEST: {
      return {...state, fetchingCable: true};
    }
    case HarbourCenterActionTypes.FETCH_CABLE_DATA_SUCCESS: {
      return {...state, fetchingCable: false, cableData: action.payload};
    }
    case HarbourCenterActionTypes.FETCH_HARBOURCENTER_ERROR: {
      return {...state, fetchingHarbour: false, harbourCenterData: undefined, harbourCenterDataAS: undefined, cableData: undefined, fetchError: action.payload};
    }

    default: {
      return state;
    }
  }
}
