import { combineReducers } from 'redux';

import { History } from 'history'

import { applicationLayoutReducer } from './applicationLayout/reducer';
import { ApplicationLayoutState } from './applicationLayout/types';

import { demoReducer } from './demo/reducer';
import { DemoState } from './demo/types';
import {geoDataReducer} from "./geoData/reducer";
import {GeoDataState} from "./geoData/types";
import {websocketReducer} from "./webSocket/reducer";
import {WebSocketState} from "./webSocket/types";
import {filterReducer} from "./filter/reducer";
import {FilterState} from "./filter/types";
import {AsnInfoState} from "./asnInfo/types";
import {asnInfoReducer} from "./asnInfo/reducer";
import {asRankReducer} from "./asRankData/reducer";
import {peeringDBReducer} from "./peeringDBData/reducer";
import {ASRankState} from "./asRankData/types";
import {bgpDataReducer} from "./bgpData/reducer";
import {BgpDataState} from "./bgpData/types";
import {PeeringDBState} from "./peeringDBData/types";
import {HarbourCenterState} from "./harbourCenterData/types";
import {harbourCenterReducer} from "./harbourCenterData/reducer";
import {ForbiddenZonesState} from "./forbiddenZones/types";
import {forbiddenZonesReducer} from "./forbiddenZones/reducer";
import {DataCenterMapState} from "./dataCenterMapStore/types";
import {dataCenterMapReducer} from "./dataCenterMapStore/reducer";
import {DnsCenterState} from "./dnsCenterData/types";
import {dnsCenterReducer} from "./dnsCenterData/reducer";


// The top-level store state definition
export interface ApplicationState {
  applicationLayout: ApplicationLayoutState;
  demo: DemoState;
  asRank: ASRankState;
  websocket: WebSocketState;
  filter: FilterState;
  geoData: GeoDataState;
  asnInfo: AsnInfoState;
  bgpData: BgpDataState;
  peeringDBData: PeeringDBState;
  harbourCenterData: HarbourCenterState;
  forbiddenZonesData: ForbiddenZonesState;
  dataCenterMapData: DataCenterMapState;
  dnsCenterData: DnsCenterState;
}

// Setup root reducer from split reducer functions
export const rootReducer = (history: History) => combineReducers<ApplicationState>({
  applicationLayout: applicationLayoutReducer,
  demo: demoReducer,
  asRank: asRankReducer,
  geoData: geoDataReducer,
  bgpData: bgpDataReducer,
  asnInfo: asnInfoReducer,
  websocket: websocketReducer,
  filter: filterReducer,
  peeringDBData: peeringDBReducer,
  harbourCenterData: harbourCenterReducer,
  forbiddenZonesData: forbiddenZonesReducer,
  dataCenterMapData: dataCenterMapReducer,
  dnsCenterData: dnsCenterReducer,
});
