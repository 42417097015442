import React, {FC, forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {BGPDataCollection} from "@pages/BGPDashboard/models/BGPDataCollection";
import {NextHopBarChart} from "../BGPDashboard/Vis/NextHopBarChart";
import {filterData} from "../BGPDashboard/InteractionsComponent"
import {FilterStatus} from "@pages/BGPDashboard/models/FilterStatus";


const NextHopComponent = forwardRef((props, ref) => {

  const refContainer = useRef(null);
  const [chart, setChart] = useState(null);
  const bgpData = useSelector((state: ApplicationState) => state.bgpData.rawData);
  const colorState = useSelector((state: ApplicationState) => state.bgpData.colorState);
  const filterTimeFrom = useSelector((state: ApplicationState) => state.filter.filterTimeFrom);
  const filterTimeTo = useSelector((state: ApplicationState) => state.filter.filterTimeTo);
  const filterState = useSelector((state:ApplicationState) => state.bgpData.filter);
  const filterID = useSelector((state:ApplicationState) => state.bgpData.highlightData);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    updateDimensions() {
      // @ts-ignore
      chart?.updateDimensions();
    }
  }));

  useEffect(() => {
    // runs on destruct/unmount
    return () => {
      // @ts-ignore
      if (chart?.cleanUp) chart?.cleanUp();
    }
  }, [chart])

  const updateChart = (chart: NextHopBarChart | null, bgpData: BGPDataCollection | undefined,
                               filterTimeFrom: number, filterTimeTo: number, filter: FilterStatus[] | undefined, highlights: number[] | undefined) => {

    let chartData = filterData(bgpData, filterTimeFrom, filterTimeTo, filter)
    let finalData: any = [];
    if(chartData && chart) {
      let temp: string[] = [];
      chartData = chartData.filter(d => d.bgpData.type == "ANNOUNCEMENT")
      chartData.map(e => {
        if (temp.length == 0 || !temp.includes(e.bgpData.nextHop)) {
          const chartElement = {
            ids: [e.id],
            nextHop: e.bgpData.nextHop,
            count: 1,
            highlight: false
          }
            finalData.push(chartElement);
            temp.push(e.bgpData.nextHop);
          } else {
            const toUpdate = finalData.find((f: { nextHop: string; }) => f.nextHop == e.bgpData.nextHop);
            if (toUpdate != null) {
              toUpdate.count = toUpdate.count + 1;
              let idAttr: number[] = [];
              idAttr = idAttr.concat(toUpdate.ids)
              idAttr.push(e.id)
              toUpdate.ids = idAttr
            }
          }
        })

      finalData.map((d: any) => {
        d.ids.map((e: any) => {
          if (highlights)
            if (highlights.includes(e)) {
              d.highlight = true;
            }
        })
      })

      finalData.sort((d: { count: number }, e: { count: number }) => {
        if ((d.count + d.count) > (e.count + e.count)) {
          return -1;
        }
        if ((d.count + d.count) < (e.count + e.count)) {
          return 1;
        }
        return 0;
      })
      let updateData = finalData.slice(0, 15)
      if(highlights){
        let tmp:number[] = [-1];
        updateData.map((u:any) => {
          tmp = tmp.concat(u.ids)
        })
        highlights.map(h => {
          if(!tmp.includes(h)){
            updateData = updateData.concat(finalData.filter((f:any) => f.ids.includes(h)))
          }
        })
        updateData.sort((d: { count: number }, e: { count: number }) => {
          if ((d.count + d.count) > (e.count + e.count)) {
            return -1;
          }
          if ((d.count + d.count) < (e.count + e.count)) {
            return 1;
          }
          return 0;
        })
      }
      if(updateData.length > 15){
        updateData = updateData.slice(0,16);
      }
      chart.updateData(updateData, dispatch, colorState!);
      }
    }


  useEffect(() => {
    const initializeChart = ({setChart, refContainer}: any) => {
      const chart = new NextHopBarChart(refContainer.current);
      setChart(chart);
      if (bgpData) {
        updateChart(chart, bgpData, filterTimeFrom, filterTimeTo, filterState, filterID);
      }
    };
    if (!chart) initializeChart({setChart, refContainer});
  }, [chart]);


  useEffect(() => {
    if (chart != null) {
      updateChart(chart, bgpData, filterTimeFrom, filterTimeTo, filterState, filterID)
    }
  }, [bgpData, filterTimeFrom, filterTimeTo, filterState, filterID])




  return (
    <>
      <div ref={refContainer} style={{height:'100%'}}>
      </div>
    </>
  );
});

export default NextHopComponent;
