import {BgpDataActions, BgpDataActionTypes, BgpDataState} from './types';
import {Feature, FeatureCollection} from "geojson";
import {BGPDataCollection} from "@pages/BGPDashboard/models/BGPDataCollection";
import {BGPData} from "@pages/BGPDashboard/models/BGPData";
import {CollectorCollection} from "@pages/BGPDashboard/models/CollectorCollection";
import {FilterStatus} from "@pages/BGPDashboard/models/FilterStatus";
import {BGPProgress} from "@pages/BGPDashboard/models/BGPProgress";


// Initial state
const initialState: BgpDataState = {
  prefixDateFrom : -1,
  prefixDateTo : -1,
  prefixCollectors : [],
  prefixData: undefined,
  centerConnectionsData: undefined,
  data_raw: undefined,
  progress: new Map(),
  data_clusters: undefined,
  fetching_raw: false,
  fetching_centers: false,
  fetching_connections: false,
  fetching_prefixPaths: false,
  fetching_rawData: false,
  fetchError: undefined,
  rawData: undefined,
  popUpData: undefined,
  asnInfoMap: undefined,
  asPathData:undefined,
  collectorData: undefined,
  filterData: undefined,
  highlightData: undefined,
  filter: [],
  colorState: {add:"#78d46e", remove:"#bf5453",
    highlight:"#fca426", announce: "#009374", withdraw: "#4461af",
    tier1:"#efa361", tier2: "#54cbac", tier3: "#e5e564",
    origin: "#e200e2", igp: "#af936c", egp: "#36ccad", unknown:"#72b76a"}
};


// The reducer function
export function bgpDataReducer(state = initialState, action: BgpDataActions): BgpDataState {
  switch (action.type) {

    // Manual handling of fetching resources
    case BgpDataActionTypes.SET_PREFIX_DATE_FROM: {
      return { ...state, prefixDateFrom: action.payload };
    }
    case BgpDataActionTypes.SET_PREFIX_DATE_TO: {
      return { ...state, prefixDateTo: action.payload };
    }
    case BgpDataActionTypes.SET_PREFIX_COLLECTORS: {
      return { ...state, prefixCollectors: action.payload };
    }
    case BgpDataActionTypes.SET_FILTER_DATA: {
      return { ...state, filterData: action.payload };
    }
    case BgpDataActionTypes.SET_HIGHLIGHTS: {
      let filter : number[] = [];
      if(state.highlightData == undefined){
        filter = filter.concat(action.payload);
      } else {
        filter = filter.concat(state.highlightData);
        filter = filter.concat(action.payload);
        filter = [...new Set(filter)]
      }
      return { ...state, highlightData: filter};
    }
    case BgpDataActionTypes.DEL_HIGHLIGHTS: {
      let filter : number[] = [];
      if(state.highlightData){
        filter = filter.concat(state.highlightData)
        filter = filter.filter(d => !action.payload.includes(d));
        if(filter.length == 0)  return { ...state, highlightData: undefined};
      }
      return { ...state, highlightData: filter};
    }
    case BgpDataActionTypes.SET_FILTER: {
      let filterStatus : FilterStatus[] = [];
      if(state.filter == undefined){
        filterStatus.push(action.payload as FilterStatus);
        return { ...state, filter: filterStatus};
      }
      else {
        if(state.filter.some((e: { type: string, value:string | number | string[] | number[] | any[], filter:string, color:string, name:string, info:any}) => {


        return action.payload.type == e.type && action.payload.filter == e.filter && action.payload.value.toString() == e.value.toString();
      })) {
          return { ...state, filter: state.filter};
      } else{
          filterStatus = filterStatus.concat(state.filter);
          filterStatus.push(action.payload as FilterStatus);
      }
      return { ...state, filter: filterStatus};
    }}
    case BgpDataActionTypes.DEL_FILTER: {
      let filterStatus : FilterStatus[] = [];
      if(state.filter){
        if(state.filter.some((e: { type: string, value:string | number | string[] | number[] | any[], filter:string, color:string, name:string, info:any}) => {
          return e.type === action.payload.type && action.payload.value == e.value
        })) {
          filterStatus = filterStatus.concat(state.filter);
          const i = filterStatus.findIndex(d => d.type === action.payload.type && d.value === action.payload.value);
          if(i >= 0) filterStatus.splice(i,1);
        }
      }
      return { ...state, filter: filterStatus};
    }
    case BgpDataActionTypes.SET_POPUP_DATA: {
      return {...state, popUpData: action.payload}
    }
    case BgpDataActionTypes.SET_ASN_INFO_MAP: {
      return {...state, asnInfoMap: action.payload}
    }
    //raw data
    case BgpDataActionTypes.FETCH_RAW_DATA_REQUEST: {
      return { ...state, fetching_rawData: true, rawData: undefined };
    }
    case BgpDataActionTypes.FETCH_RAW_DATA_FINISH: {
      return { ...state, fetching_rawData: false };
    }
    case BgpDataActionTypes.FETCH_RAW_DATA_SUCCESS: {
      let dataCollection : BGPDataCollection = {collection: []};
      if(state.rawData){
        dataCollection.collection = dataCollection.collection.concat(state.rawData.collection);
      }
      dataCollection.collection.push(action.payload as BGPData);

      return { ...state, rawData: dataCollection};
    }

    case BgpDataActionTypes.FETCH_RAW_DATA_BATCH_SUCCESS: {
      let dataCollection : BGPDataCollection = {collection: []};
      if(state.rawData) {
        dataCollection.collection = dataCollection.collection.concat(state.rawData.collection);
      }
      dataCollection.collection = dataCollection.collection.concat(action.payload);
      return { ...state, rawData: dataCollection};
    }
    //prefix data
    case BgpDataActionTypes.FETCH_PREFIX_DATA_REQUEST: {
      return { ...state, fetching_prefixPaths: true, prefixData: undefined, progress: new Map() };
    }
    case BgpDataActionTypes.FETCH_PREFIX_DATA_FINISH: {
      return { ...state, fetching_prefixPaths: false };
    }
    case BgpDataActionTypes.FETCH_PREFIX_DATA_SUCCESS: {
      let featureCollection : FeatureCollection = {type: "FeatureCollection", features: []};
      if(state.prefixData) {
        featureCollection.features = featureCollection.features.concat(state.prefixData.features);
      }
      featureCollection.features.push(action.payload as Feature);
      return { ...state, prefixData: featureCollection };
    }
    case BgpDataActionTypes.FETCH_PREFIX_DATA_BATCH_SUCCESS: {
      let featureCollection : FeatureCollection = {type: "FeatureCollection", features: []};

      if(state.prefixData){
        featureCollection.features = featureCollection.features.concat(state.prefixData.features);
      }
      featureCollection.features = featureCollection.features.concat(action.payload);

      return { ...state, prefixData: featureCollection };
    }
    case BgpDataActionTypes.FETCH_CENTERCONNECTIONS_REQUEST: {
      return { ...state, fetching_connections: true };
    }
    case BgpDataActionTypes.FETCH_CENTERCONNECTIONS_SUCCESS: {
      return { ...state, fetching_connections: false, fetchError: undefined, centerConnectionsData: action.payload };
    }
    case BgpDataActionTypes.FETCH_RAW_REQUEST: {
      return { ...state, fetching_raw: true, progress: new Map()};
    }
    case BgpDataActionTypes.FETCH_RAW_SUCCESS: {
      return { ...state, fetching_raw: false, fetchError: undefined, data_raw: action.payload };
    }
    case BgpDataActionTypes.FETCH_PREFIX_DATA_PROGRESS: {
      let progressUpdate : BGPProgress[] = action.payload;
      let progress = new Map(state.progress);

      for (let update of progressUpdate) {
        if (!progress.has(update.collector)){
          progress.set(update.collector, new Map())
        }
        let innerMap: Map<number, BGPProgress> = progress.get(update.collector)!;
        innerMap.set(update.dateFrom, update);
      }

      return { ...state, progress: progress};
    }
    case BgpDataActionTypes.FETCH_CENTERS_REQUEST: {
      return { ...state, fetching_centers: true };
    }
    case BgpDataActionTypes.FETCH_CENTERS_SUCCESS: {
      return { ...state, fetching_centers: false, fetchError: undefined, data_clusters: action.payload };
    }
    case BgpDataActionTypes.FETCH_ERROR: {
      //TODO check which one errored and set fields
      return { ...state, fetching_raw: false, fetching_prefixPaths: false, fetching_connections: false, fetching_centers: false,fetchError: action.payload };
    }
    case BgpDataActionTypes.FETCH_COLLECTOR_DATA_SUCCESS: {
      let data = action.payload;
      let collectors : CollectorCollection = {collection: []};

      for(let i=0; i<data.length; i++){
        collectors.collection.push(data[i]);
      }
      return { ...state, collectorData : collectors};
    }
    default: {
      return state;
    }
  }
}
