import React, {FC, forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl, {GeoJSONSource, MapMouseEvent} from 'mapbox-gl'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Theme,
  Typography, useTheme
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {FeatureCollection} from "geojson";
import {start} from "repl";
import {AsnInfoData} from "../../../store/asnInfo/types";
import {BGPDataCollection} from "@pages/BGPDashboard/models/BGPDataCollection";
import "../BGPDashboard/style/map.css"
import {delFilter, setFilter} from "../../../store/bgpData/actions";
import {filterData} from "@pages/BGPDashboard/InteractionsComponent";
import {FilterStatus} from "@pages/BGPDashboard/models/FilterStatus";

const MiniMapComponent = forwardRef((props, ref) => {

  const type = useSelector((state: ApplicationState) => state.applicationLayout.type);
  let mapStyle = type === "dark" ? 'mapbox://styles/crispy/cils06qnw00c2f7m0hip6ixms' : 'mapbox://styles/crispy/cimeo4mbb00o5adlz3c18g26e';
  const dispatch = useDispatch();
  const [map, setMap] = useState(null);
  const mapContainer = useRef(null);
  const [showPrefixLayer, setShowPrefixLayer] = useState(false);
  const [showCollectorLayer, setCollectorLayer] = useState(false);
  const [asInfoLayerIDs, setAsInfoLayerIDs] = useState([]);
  const filterTimeFrom = useSelector((state: ApplicationState) => state.filter.filterTimeFrom);
  const filterTimeTo = useSelector((state: ApplicationState) => state.filter.filterTimeTo);
  const prefixLayerName = "prefixes";
  const collectorLayerName ="collectors";
  const tooltipContainer = document.createElement('div');
  const bgpData = useSelector((state: ApplicationState) => state.bgpData.rawData);
  const collectors = useSelector((state: ApplicationState) => state.bgpData.collectorData);
  const filterState = useSelector((state:ApplicationState) => state.bgpData.filter);
  const filterID = useSelector((state:ApplicationState) => state.bgpData.highlightData);
  const colorState = useSelector((state: ApplicationState) => state.bgpData.colorState);


  const colorMap = [
    [1, "#fffcaa"],
    [100, "#ffeda0"],
    [1000, "#fed976"],
    [10000, "#feb24c"],
    [100000, "#fd8d3c"],
    [500000, "#fc4e2a"],
    [1000000, "#e31a1c"],
    [5000000, "#bd0026"],
    [10000000, "#800026"]];

  const startColor = "#1fa939";
  const endColor = "#e200e2";
  const collectorColor = "#448aff";
  const theme = useTheme<Theme>();

  useImperativeHandle(ref, () => ({
    updateDimensions() {
      if (map) {
        // @ts-ignore
        map.resize();
      }
    }
  }));

  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiY3Jpc3B5IiwiYSI6ImNpczdncncxYzAwMXgydHA3aXR4NmUwcjYifQ.5CdsMFTwPvC3T_6iqHKQng';
    const initializeMap = ({setMap, mapContainer}: any) => {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: mapStyle, //stylesheet location
        center: [0, 30], // starting position
        zoom: 0, // starting zoom
        minZoom: 0,
        maxZoom: 11,
        renderWorldCopies: true,
        attributionControl: false,
      });
      map.addControl(new mapboxgl.AttributionControl({
        compact: true,
      }));

      map.on("load", () => {
        setMap(map);
        map.resize();

        const tooltip = new mapboxgl.Marker(tooltipContainer, {
          offset: [0, -50]
        }).setLngLat([0,0])
          .addTo(map);

        let highlights = false;
        map.on('mousemove',(e) => {
          if(highlights && !e.originalEvent.cancelBubble){
            //console.log("map mousemove: resetting hover state")
            map.querySourceFeatures(prefixLayerName).forEach((f)=>{
              map.setFeatureState({
                source: prefixLayerName,
                id: f.id,
              }, {
                hoverNotYou: false,
                hover: false,
              });
            });
            highlights = false;
          }
        });

        let fixPopup = false;

        let lineLayers = [prefixLayerName];
        for (let i = 0; i < 12; i++) {
          lineLayers.push(prefixLayerName+i);
        }

        let mouseMoveLineCallback = (e: MapMouseEvent) => {
          if(fixPopup) return;

          let features = map.queryRenderedFeatures(e.point, {layers: lineLayers})
            // since technically the time "filter" only hides object with opacity, they are still rendered,
            // and need to be filtered out
            .filter((feature => {
              let inFilter = map.getFeatureState({
                source: prefixLayerName,
                id: feature.id
              })['inFilter'];

              return inFilter;
            }));


          if(features && features.length > 0) {
            let featureIDs = new Set(features.map(f => {return f.properties?.id}));
            let pointer = false;
            highlights = true;
            if(!fixPopup){
              map.querySourceFeatures(prefixLayerName).forEach((f)=>{
                let contained = featureIDs.has(f.properties?.id);
                if (contained) pointer = true;
                map.setFeatureState({
                  source: prefixLayerName,
                  id: f.id,
                }, {
                  hover: contained,
                  hoverNotYou: !contained,
                });
              });
            }
            map.getCanvas().style.cursor = pointer ? 'pointer' : '';
          }
        };

        const mouseClickLineCallback = (e: MapMouseEvent) => {
          let features = map.queryRenderedFeatures(e.point, {layers: lineLayers})
            // since technically the filter only hides object with opacity, they are still rendered,
            // and need to be filtered out
            .filter((feature => {
              let inFilter = map.getFeatureState({
                source: prefixLayerName,
                id: feature.id
              })['inFilter'];
              return inFilter;
            }));

          if (features && features.length > 0){
            let filtering:any[] = [];
            features.map(d => {
              if (d.properties)
                filtering.push({
                  collector: d.properties.collector,
                  timestamp: d.properties.timestamp,
                  asPath: d.properties.asPath
                })
            })
            dispatch(setFilter({type: "map", value: filtering, filter: "add", color:"#78d46e", name: "BGP Routes", info: null}))

            fixPopup = true;
            e.originalEvent.cancelBubble = true;
          }
        };

        lineLayers.forEach(layerName => {
          map.on('mousemove', layerName, mouseMoveLineCallback);
          map.on("click", layerName, mouseClickLineCallback);
          //map.on("mouseleave", layerName, mouseLeaveLineCallback);
        });

        map.on("click", (e) => {
          if(e.originalEvent.cancelBubble) return;
          fixPopup = false;

          let features = map.queryRenderedFeatures(e.point, {layers: lineLayers})
            // since technically the time "filter" only hides objects with opacity, they are still rendered,
            // and need to be filtered out
            .filter((feature => {
              return map.getFeatureState({
                source: prefixLayerName,
                id: feature.id
              })['inFilter'];
            }));

          let filtering:any[] = [];
          features.map(d => {
            if (d.properties)
              filtering.push({
                collector: d.properties.collector,
                timestamp: d.properties.timestamp,
                asPath: d.properties.asPath
              })
          })
          //console.log("delFilter: ", filtering);
          dispatch(delFilter({type: "map", value: filtering, filter: "add", color:"#78d46e", name: "Map Paths", info: null}))
        });

      });
    };

    if (!map) initializeMap({setMap, mapContainer});
  }, [map]);

  const removeSourceAndLayer = (map: mapboxgl.Map | null, id : string) => {
    if (map) {
      if (map.getSource(id)) {
        if(id == prefixLayerName){
          map.removeLayer(id+"p");
          asInfoLayerIDs.forEach(asInfoLayerID => {
            let id = prefixLayerName+"asinfo"+asInfoLayerID
            if (map.getLayer(prefixLayerName+"asinfo"+asInfoLayerID)){
              map.removeLayer(prefixLayerName+"asinfo"+asInfoLayerID)
            }
          });
          setAsInfoLayerIDs([]);

          for (let i = 0; i < 12; i++) {
            map.removeLayer(prefixLayerName+i);
          }
        }

        map.removeLayer(id);
        map.removeSource(id);
      }
    }
  };

  useEffect(() => {
    resizeMap(map);
  }, [map]);

  const resizeMap = (map: mapboxgl.Map | null) => {
    if(map != null) {
      map.resize();
    }
  };

  const switchCollectorLayer = (map: mapboxgl.Map | null, force? : boolean) => {
    if(force){
      setCollectorLayer(force)
    }else{
      setCollectorLayer(!showCollectorLayer);
    }

    if(map != null){
      if(!showCollectorLayer){
        map.setLayoutProperty(collectorLayerName, 'visibility', 'visible');
      }
      else{
        map.setLayoutProperty(collectorLayerName, 'visibility', 'none');
      }
    }
  }

  const switchPrefixLayer = (map: mapboxgl.Map | null, force? : boolean) => {
    if(force){
      setShowPrefixLayer(force)
    }else{
      setShowPrefixLayer(!showPrefixLayer);
    }

    if(map != null){
      if(!showPrefixLayer){
        map.setLayoutProperty(prefixLayerName, 'visibility', 'visible');
        map.setLayoutProperty(prefixLayerName+"p", 'visibility', 'visible');

        for (let i = 0; i < 12; i++) {
          map.setLayoutProperty(prefixLayerName+i, 'visibility', 'visible');
        }
      }
      else{
        map.setLayoutProperty(prefixLayerName, 'visibility', 'none');
        map.setLayoutProperty(prefixLayerName+"p", 'visibility', 'none');

        for (let i = 0; i < 12; i++) {
          map.setLayoutProperty(prefixLayerName+i, 'visibility', 'none');
        }
      }
    }
  }

  useEffect(() => {
    updateFilters(map, filterState, filterTimeFrom, filterTimeTo);

  }, [bgpData, filterState, filterTimeFrom, filterTimeTo, filterID]);


  // unfortunately setFilter is very slow, so we cannot use it for the hover effect or time filter (see various github issues)
  // instead, we use a layer for each path which we can hide or show  (only for asinfo)
  // The line visibility is controlled by opacity
  const updateFilters = (map : mapboxgl.Map | null, filterState: FilterStatus[] | undefined, filterTimeFrom: number, filterTimeTo: number) => {
    if (!(map && map.getSource(prefixLayerName)) || ! bgpData?.collection) return;

    let mapData: any = {type: "FeatureCollection", features: []}
    let data = filterData(bgpData, filterTimeFrom, filterTimeTo, filterState);

    if(data) {
      data = data.filter(b => b.bgpData.type == "ANNOUNCEMENT") || [];
      data.map(d => {
        if (mapData.features.length == 0) {
          mapData.features = d.geoData
        } else mapData.features = mapData.features.concat(d.geoData)
      })
    }

    let visibleIDs: Set<string> = new Set(mapData.features.map((f: any) => {return f.properties.id}));
    //console.log("visibleIDS: ", visibleIDs);

    let filterEmpty = filterState?.filter(d=> {return d.type === "map"}).length === 0;

    asInfoLayerIDs.forEach((asInfoLayerID) => {
      map.setLayoutProperty(prefixLayerName+"asinfo"+asInfoLayerID,
        'visibility',
        visibleIDs.has(asInfoLayerID) && !filterEmpty ? 'visible' : 'none'
      );
    });

    //console.log("querySourceFeatures for prefixLayerName");
    //console.log(map.getSource(prefixLayerName))
    map.querySourceFeatures(prefixLayerName).forEach((feature) => {
      //console.log("hello inside forEach");
      // @ts-ignore
      let includes = visibleIDs.has(feature.properties.id);

      map.setFeatureState({
          source: prefixLayerName,
          id: feature.id
        },
        {
          //hoverNotYou: !includes && !filterEmpty,
          //hover: includes,
          inFilter: includes,
        });
    });
  };


  /**
   * Add Layers that need to be added dynamically when new paths are created
   * @param map the map
   * @param data the (new) features
   */
  const addPathLayers = (map: mapboxgl.Map, data: FeatureCollection) => {
    // see https://docs.mapbox.com/mapbox-gl-js/example/filter-markers-by-input/
    // we do this to avoid close nodes obstructing each other even when not shown
    let asInfoIds: string[] = asInfoLayerIDs;

    //console.log("addPathLayers: ", data);
    data.features.forEach((feature) => {
      // @ts-ignore
      let id = feature.properties.id;

      if (!map.getLayer(prefixLayerName+"asinfo"+id)) {
        //console.log("adding asinfo layer for id: " + id);
        //console.log(feature);
        map.addLayer({
          "id": prefixLayerName+"asinfo"+id,
          "type": "symbol",
          "source": prefixLayerName,
          'layout': {
            'text-field': ['get', 'asn'],
            'text-variable-anchor': ['top', 'bottom', 'left', 'right', "bottom-left", "bottom-right", "top-left", "top-right"],
            'text-radial-offset': 0.5,
            "text-allow-overlap" : false,
            "text-ignore-placement" : false,
            'text-justify': 'center',
            'visibility': 'none',
          },
          'paint': {
            'text-color': "#ffffff",
            'text-opacity': [
              'case',
              ['boolean', ['feature-state', 'inFilter'], false],
              1,
              0,
            ]
          },
          'filter': ['==', ['get', 'id'], id],
        });

        asInfoIds.push(id);
      }
    });
    // @ts-ignore
    setAsInfoLayerIDs(asInfoIds);
  };

  const addPrefixLayer = (map: mapboxgl.Map | null, data: FeatureCollection) => {
    //console.log("addPrefixLayer");
    if (map) {
      if(map.getSource(prefixLayerName)){
        //console.log("setting data for existing source:", data);
        let source = map.getSource(prefixLayerName) as GeoJSONSource;
        source.setData(data);
        addPathLayers(map, data);
        // Update feature-state with existing filters for newly added features
        updateFilters(map, filterState, filterTimeFrom, filterTimeTo);
        return;
      }
      //console.log("adding source to map with data", data);
      map.addSource(prefixLayerName, {
        type: 'geojson',
        lineMetrics: true,
        data: data,
        'promoteId': "id",
      });

      let tier1Color = colorState!.tier1;
      let tier2Color = colorState!.tier2;
      let tier3Color = colorState!.tier3;
      let tierUnknownColor = colorState!.unknown;

      let lineLayers = 0;

      // mapbox-gl-js does not support accessing features properties to style line-gradients,
      // so for every possible transition, a new layer with a distinct style is needed
      const addLineTransitionLayer = (map: mapboxgl.Map, layerNum: number, fromTier: number, toTier: number, fromColor: string, toColor: string) => {
        //console.log("addLineTransitionLayer: ", layerNum, fromTier, toTier, fromColor, toColor);

        map.addLayer({
          id: prefixLayerName + layerNum,
          "type": "line",
          "source": prefixLayerName,
          'layout': {
            'line-join': 'round',
            'line-cap': 'round'
          },
          filter:  ['all', ['==', ['get', 'fromTier'], fromTier], ['==', ['get', 'toTier'], toTier], ['boolean', ['get', 'tierTransition'], false]],
          "paint": {
            "line-width": [
              "interpolate",
              ["linear"],
              ["zoom"],
              1, 3,
              9, 7,
              13, 15
            ],
            "line-opacity": [
              'case',
              ['boolean', ['feature-state', 'inFilter'], false],
              ['case',
                ['boolean', ['feature-state', 'hover'], false],1,
                ['boolean', ['feature-state', 'hoverNotYou'], false],0,
                0.5],
              0,
            ],
            'line-gradient': [
              'interpolate',
              ['linear'],
              ['line-progress'],
              0,
              fromColor,
              1,
              toColor,
            ]
          },
        });
      };

      addLineTransitionLayer(map, lineLayers++, -1, 1, tierUnknownColor, tier1Color);
      addLineTransitionLayer(map, lineLayers++, -1, 2, tierUnknownColor, tier2Color);
      addLineTransitionLayer(map, lineLayers++, -1, 3, tierUnknownColor, tier3Color);
      addLineTransitionLayer(map, lineLayers++, 1, -1, tier1Color, tierUnknownColor);
      addLineTransitionLayer(map, lineLayers++, 1, 2, tier1Color, tier2Color);
      addLineTransitionLayer(map, lineLayers++, 1, 3, tier1Color, tier3Color);
      addLineTransitionLayer(map, lineLayers++, 2, -1, tier2Color, tierUnknownColor);
      addLineTransitionLayer(map, lineLayers++, 2, 1, tier2Color, tier1Color);
      addLineTransitionLayer(map, lineLayers++, 2, 3, tier2Color, tier3Color);
      addLineTransitionLayer(map, lineLayers++, 3, -1, tier3Color, tierUnknownColor);
      addLineTransitionLayer(map, lineLayers++, 3, 1, tier3Color, tier1Color);
      addLineTransitionLayer(map, lineLayers++, 3, 2, tier3Color, tier2Color);

      map.addLayer({
        "id": prefixLayerName,
        "type": "line",
        "source": prefixLayerName,
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        "filter": ['!', ['boolean', ['get', 'tierTransition'], true]],
        "paint": {
          "line-width": [
            "interpolate",
            ["linear"],
            ["zoom"],
            1, 3,
            9, 7,
            13, 15
          ],
          "line-color":
            ['case',
              ['==', ['get', 'tier'], -1], tierUnknownColor,
              ['==', ['get', 'tier'], 1], tier1Color,
              ['==', ['get', 'tier'], 2], tier2Color,
              ['==', ['get', 'tier'], 3], tier3Color,
              '#ff69b4' // should ideally be unreachable, if you see this color (hot pink) something is wrong
            ],
          "line-opacity": [
            'case',
            ['boolean', ['feature-state', 'inFilter'], false],
            ['case',
              ['boolean', ['feature-state', 'hover'], false],1,
              ['boolean', ['feature-state', 'hoverNotYou'], false],0,
              0.5],
            0,
          ]
        },
      });

      map.addLayer({
          "id": prefixLayerName+"p",
          "type": "circle",
          "source": prefixLayerName,
          "paint": {
            "circle-radius": [
              "interpolate", ["linear"], ["zoom"],
              1, ["case", ['in', ['get', 'prop'], ['literal', ["start", "end"]]],5,1],
              20, ["case", ['in', ['get', 'prop'], ['literal', ["start", "end"]]],35,10],
            ],
            "circle-color": [
              'match',
              ['get', 'prop'],
              'start',
              endColor,
              'end',
              startColor,
              '#FFF'
            ],
            "circle-opacity" : [
              'case',
              ['boolean', ['feature-state', 'inFilter'], false],
              ['case', ['boolean', ['feature-state', 'hoverNotYou'], false], 0, 1],
              0,
            ]
          },
          // 'filter': ['==', '$type', 'Point']
        }
      );

      addPathLayers(map, data);

      setShowPrefixLayer(true);

      data.features.forEach((feature) => {
        map.setFeatureState({
            source: prefixLayerName,
            // @ts-ignore
            id: feature.properties.id
          },
          {
            hoverNotYou: false,
            hover: false,
            inFilter: true,
          });
      });
    }
  };

  useEffect(() => {
    removeSourceAndLayer(map, prefixLayerName);

    let features = {type: "FeatureCollection", features: bgpData?.collection
        .filter(b => {return b.geoData})
        .map(b => {return b.geoData})
        .reduce((acc, val) => acc.concat(val), [])}; // flat()

    if (features.features) addPrefixLayer(map, features as FeatureCollection);
    addCollectorLayer(map, bgpData, filterTimeFrom, filterTimeTo, filterState, filterID);
  }, [bgpData]);

    const addCollectorLayer = (map: mapboxgl.Map | null, data: BGPDataCollection | undefined, filterTimeFrom: number, filterTimeTo: number, filter: FilterStatus[] | undefined, highlights: number[] | undefined) => {

      let collectorData = filterData(bgpData, filterTimeFrom, filterTimeTo, filter);
      let collectorNames: string[] = [];
      if(collectorData) {
        collectorData.map(d => {
          if (collectorNames.includes(d.bgpData.collector)) {
            return
          } else collectorNames.push(d.bgpData.collector)
        })
      }

        let collectorGeo: FeatureCollection = {type: "FeatureCollection", features: []};
        collectors?.collection.map((d, i) => {
          collectorNames.map(e => {
            if (d.name.includes(e)) {
              let coordinates: any = [];
              coordinates.push(d.longitude);
              coordinates.push(d.latitude);
              let info = "<div><b>" + d.name + "</b><br>" +
                "<b>ASN:</b> " + d.asn + "<br>" +
                "<b>IP:</b> " + d.ip + "<br>" +
                "<b>Location:</b> " + d.location + "</div>"
              collectorGeo.features.push({
                type: "Feature",
                geometry: {type: "Point", coordinates: coordinates},
                properties: {id: i, name: d.name, info: info}
              });
            } else return;
          })
        })

        if(map) {
          if (map.getSource(collectorLayerName)) {
            let source = map.getSource(collectorLayerName) as GeoJSONSource;
            source.setData(collectorGeo);
            return;
          }
          map.addSource(collectorLayerName, {
            type: 'geojson',
            data: collectorGeo
          });
          map.addLayer({
              "id": collectorLayerName,
              "type": "circle",
              "source": collectorLayerName,
              "paint": {
                "circle-radius": 5,
                "circle-color": collectorColor,
                "circle-stroke-color": collectorColor,
                'circle-stroke-width': 1,
                "circle-opacity": 1
              }
            }
          );
        }
      }

  let legend = [];
  for (let i = 0; i<colorMap.length; i++){
    let num : number | string = colorMap[i][0];
    legend.push(
      <div key={num.toString()} style={{display:"flex", justifyContent:"flex-end"}}>
        <Typography>{">"+new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(num as number)}</Typography>
        <div style={{
          backgroundColor: ""+colorMap[i][1],
          width: 20,
          height: 20,
          marginBottom: 2,
          marginLeft: 3
        }} />
      </div>
    );
  }
  legend.reverse();


  return (
    <>
      <div className={"layout-no-drag" } ref={(el: any) => (mapContainer.current = el)} style={{
        width: "100%",
        height: "100%",
        position: "relative"
      }} />

      {<div style={{bottom: 60, left: 5, position: "absolute", zIndex: 1, width: 140}}>
        {bgpData &&
          <FormControl component="fieldset" style={{height: 30}}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="end"
                control={<Checkbox color="primary" checked={showPrefixLayer} onChange={(event) => switchPrefixLayer(map)}/>}
                label="Prefix Paths"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>}
        {bgpData &&
          <FormControl component="fieldset" style={{height: 30}}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="end"
                control={<Checkbox color="primary" checked={showCollectorLayer} onChange={(event) => switchCollectorLayer(map)}/>}
                label="Collectors"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>}
      </div>}

      {bgpData &&
        <div style={{display:"inline-flex", right: 15, top:50, position: "absolute", zIndex: 1}}>
          <Typography style={{fontSize: "0.9rem"}}>BGP update path: </Typography>
          <div style={{
            backgroundColor: endColor,
            width: 15,
            height: 15,
            marginBottom: 2,
            marginLeft: 5,
            marginRight: 5,
            marginTop: 2
          }} />
          <Typography style={{fontSize: "0.9rem"}}>Start (Origin)</Typography>
          <div style={{
            backgroundColor: startColor,
            width: 15,
            height: 15,
            marginTop: 2,
            marginBottom: 2,
            marginLeft: 5,
            marginRight: 5,
          }} />
          <Typography style={{fontSize: "0.9rem"}}>End (Next Hop)</Typography>
          <div style={{
            backgroundColor: collectorColor,
            width: 15,
            height: 15,
            marginTop: 2,
            marginBottom: 2,
            marginLeft: 5,
            marginRight: 5,
          }} />
          <Typography style={{fontSize: "0.9rem"}}>Collector</Typography>
        </div>
      }
    </>
  );
});
export default MiniMapComponent;
