import React, { FC } from 'react';

import {Box, Button, Link, Modal, Typography} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { Routes } from "config/Routes";
import LinkButton from "./LinkButton";

// Style definitions
const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {
    color: 'white',
  },
  footer: {
    left: 0,
    bottom: 0,
    height: '32px',
    width: '100%',
    position: 'fixed',
    textAlign: 'right',
    zIndex: theme.zIndex.drawer + 1,
  }
}));

const FOOTER_LINK = `https://www.igd.fraunhofer.de/kompetenzen/technologien/visual-analytics`;


const Footer: FC = () => {
  const classes = useStyles();

  const [openCredits, setOpenCredits] = React.useState(false);
  const handleOpenCreditsModal = () => setOpenCredits(true);
  const handleCloseCreditsModal = () => setOpenCredits(false);

  return (
    <div className={`${classes.footer} MuiAppBar-colorPrimary`}>
      <Button className={classes.button} onClick={handleOpenCreditsModal} disableRipple>What data do we use?</Button>
      <LinkButton className={classes.button} to={Routes.Imprint}>Imprint</LinkButton>
      <LinkButton className={classes.button} to={Routes.Dsgvo}>DSGVO</LinkButton>
      <Button className={classes.button} component='a' disableRipple>
        &copy; 2022 Fraunhofer IGD
      </Button>
      <Modal
        open={openCredits}
        onClose={handleCloseCreditsModal}
      >
        <Box style={{marginTop: '10%', marginLeft: '25%', width: '50%'}}>
          <Box style={{
            border: '2px solid #000',
            backgroundColor: '#4F4F4F',
            padding: 4,
            paddingBottom: 24,
            textAlign: 'center',
          }}>
            <Typography color={'primary'} variant={'h2'}>
              Credits
            </Typography>
            <Typography>
              Submarine Cable Data provided by TeleGeography under the following <Link href={'https://github.com/telegeography/www.submarinecablemap.com/blob/master/LICENSE'}>license</Link> <br/>
              <br/>
              Ripe IPMAP GeoIP data provided under <Link href={'https://ftp.ripe.net/ripe/ipmap/'}>ftp.ripe.net/ripe/ipmap</Link><br/>
              <br/>
              GeoIP Data by MaxMind GeoIP2 (<Link href={'https://www.maxmind.com/en/geoip2-city'}>maxmind.com/en/geoip2-city</Link>) and <br/>
              BigDataCloud API (<Link href={'https://www.bigdatacloud.com/packages/ip-geolocation'}>bigdatacloud.com/packages/ip-geolocation</Link>) <br/>
              <br/>
              Datacenter locations per Autonomous System from PeeringDB (<Link href={'https://www.peeringdb.com/'}>peeringdb.com</Link>) and <br/>
              DataCenterMap (<Link href={'https://www.datacentermap.com/'}>datacentermap.com</Link>) <br/>
              <br/>
              DNS data provided by the CAIDA ITDK (<Link href={'https://www.caida.org/catalog/datasets/internet-topology-data-kit/'}>caida.org/catalog/datasets/internet-topology-data-kit</Link>) <br/>
              <br/>
              DNS Geolocalization Regular Expressions provided by CAIDA (<Link href={'https://publicdata.caida.org/datasets/supplement/2021-conext-hoiho/'}>publicdata.caida.org/datasets/supplement/2021-conext-hoiho/</Link>) <br/>
              Also see the following paper: <br/>
              M. Luckie, B. Huffaker, A. Marder, Z. Bischof, M. Fletcher, and k. claffy. <br/>
              Learning to Extract Geographic Information from Internet Router Hostnames. <br/>
              Proc. ACM Conference on emerging Networking EXperiments and Technologies (CoNEXT). <br/>
            </Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );

}

export default Footer;
