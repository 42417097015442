import * as d3 from "d3";
import {setFilter, setHighlights} from "../../../../store/bgpData/actions";
import {SVGChart} from "@pages/BGPDashboard/Vis/SVGChart";
import {Dispatch} from "redux";
import {ColorState} from "@pages/BGPDashboard/models/ColorState";
import {Axis, ScaleBand, ScaleLinear} from "d3";

import ReactDOMServer from 'react-dom/server';
import React from 'react';
import HighlightIcon from '@material-ui/icons/Highlight';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {Tooltip} from "@pages/BGPDashboard/Vis/Tooltip";

const t = 750
const xAccessor = (d: any) => d.length;
const yAccessor = (d: any) => d.count;

export class PathLengthBarChart extends SVGChart {
  // padding needed because the xAxis is larger
  private padding = {left: 9, right:9, top: 9};

  private rootElem: any;
  private tip!: Tooltip;

  private data?: any[];
  private colorState?: ColorState;
  private dispatch?: Dispatch;

  private xAxis: any;
  private yAxis: any;
  private xAxisGenerator!: Axis<any>;
  private yAxisGenerator!: Axis<any>;

  private xScale!: ScaleBand<any>;
  private yScale!: ScaleLinear<number, number>;

  private barWidth = 15;
  private barPadding = 0.2;

  private readonly highlightIcon: string;
  private readonly addIcon: string;
  private readonly removeIcon: string;

  constructor(node: HTMLElement) {
    super(node, 250, 200);

    this.highlightIcon = ReactDOMServer.renderToString(React.createElement(HighlightIcon));
    this.addIcon = ReactDOMServer.renderToString(React.createElement(AddCircleOutlineIcon));
    this.removeIcon = ReactDOMServer.renderToString(React.createElement(RemoveCircleOutlineIcon));

    this.init();
  }

  init(){
    this.rootElem = d3.select(this.svgElement).append('g');

    this.tip = new Tooltip()
      .content((args: any[]) => {
        let [d] = args;
        return "<strong>"+d.count+"</strong>"+ " Updates have" +"<br><br>"+"an AS Path length of " + "<strong>" +d.length+ "</strong>"
      });

    this.xScale = d3.scaleBand()
      .padding(this.barPadding);
    this.yScale = d3.scaleLinear()
      .nice()

    this.xAxisGenerator = d3.axisBottom(this.xScale)
    this.yAxisGenerator = d3.axisLeft(this.yScale)

    this.xAxis = this.rootElem
      .append('g')
      .attr("class", "x axis")
      .call(this.xAxisGenerator)

    this.yAxis = this.rootElem
      .append('g')
      .attr("class", "y axis")
      .call(this.yAxisGenerator)

    this.xAxis.append('text')
      .attr("y", 30)
      .attr("x", "50%")
      .attr("fill", "white")
      .style("font-size", "12px")
      .text("Length of as path")

    this.resizeChart();
  }

  cleanUp() {
    this.tip.destroy();
  }

  resizeChart(): void {
    let xAxisHeight = this.xAxis.node()?.getBoundingClientRect().height;
    let yAxisWidth = this.yAxis.node()?.getBoundingClientRect().width;

    this.xScale.range([yAxisWidth, this.width-this.padding.right]);
    this.xAxis
      .attr("transform", "translate(" + 0 + ", " + (this.height - xAxisHeight) + ")")
      .call(this.xAxisGenerator);

    this.yScale.range([this.height - xAxisHeight, this.padding.top])
    this.yAxis
      .attr("transform", "translate(" + yAxisWidth + ", 0)")
      .call(this.yAxisGenerator);

    this.drawBarChartBars();
  }

  updateData(data: any[], dispatch: Dispatch<any>, color: ColorState) {
    this.data = data;
    this.dispatch = dispatch;
    this.colorState = color;

    this.calculateMinimumDimensions();
    this.updateDimensions();
    this.drawBarChartBars();
  }

  calculateMinimumDimensions() {
    if (!this.data) {
      return;
    }

    let chartWidth = (this.data.length+1) * Math.ceil(this.barWidth / (1-this.barPadding)) - this.barWidth;
    let yAxisWidth = this.yAxis.node()?.getBoundingClientRect().width;

    this.minWidth = chartWidth + yAxisWidth + this.padding.right;
  }

  drawBarChartBars() {
    if (!this.data || !this.colorState || !this.dispatch) {
      return;
    }
    let data = this.data;
    let color = this.colorState;
    let dispatch = this.dispatch;

    let xDom = this.xScale.domain(data.map(d => d.length))
    let yDom = this.yScale.domain([0, d3.max(data, d => d.count)])
    const yAxisTicks = this.yScale.ticks()
      .filter(tick => Number.isInteger(tick));

    this.xAxisGenerator.scale(xDom)

    this.yAxisGenerator.scale(yDom).tickValues(yAxisTicks)
      .tickFormat(d3.format('d'));

    this.rootElem.select(".x")
      .transition().duration(t)
      .call(this.xAxisGenerator)

    this.rootElem.select(".y")
      .transition().duration(t)
      .call(this.yAxisGenerator)


    const rect = this.rootElem
      .selectAll('rect')
      .data(data)
      .join('rect')
      .on("mouseover", (event: any, d: any) => this.tip.show(d))
      .on("mousemove", (event: any, d: any) => {
        this.tip.setPosition(event.pageY, event.pageX)
      })
      .on("click", (e: any, d: any) => dispatch(setFilter({type: "length", value: d.length, filter: "add", color:color.add, name: "Length "+d.length , info: null})))
      .on("mouseout", () => this.tip.hide())
      .transition()
      .duration(t)
      .attr("class", "lengths")
      .attr('x', (d: any) => this.xScale(xAccessor(d)))
      .attr('y', (d: any) => this.yScale(yAccessor(d)))
      .attr('height', (d: any) => this.yScale(0) - this.yScale(yAccessor(d)))
      // .attr('height', (d: any) => { return this.height - yDom(d.count)})
      .attr('width', this.xScale.bandwidth())
      .attr("fill", (d: any) => {
        if(d.highlight) return color.highlight;
        else return color.announce
      })
      .style("cursor", "pointer")
  }
}
