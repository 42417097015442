import { action } from '@iva/store-utils';
import {
  PeeringDBActions,
  PeeringDBActionTypes,
} from './types';

import { RestServiceUtils } from '@iva/rest-service-utils';
import { Dispatch } from 'redux';
import {ApplicationLayoutActionTypes, SetThemeAction, ThemeTypes} from "../applicationLayout/types";

// Example for fetching a resource via RestServiceUtils
export function fetchPeeringDBData() {
    return (dispatch: Dispatch) => {
        // Dispatch action that fetching data has begun
        dispatch(action(PeeringDBActionTypes.FETCH_PEERINGDB_REQUEST, undefined));

        // Call endpoint, dispatch success- or error-message, depending on server response
        // Submit the GET-request, expecting plain text as response type
        RestServiceUtils.jsonGET('/api/peeringDBdata',
            result => dispatch(action(PeeringDBActionTypes.FETCH_PEERINGDB_SUCCESS, result)),
            error => dispatch(action(PeeringDBActionTypes.FETCH_ERROR, error.message))
        );
    }
}

export function fetchPeeringDBDataAS(asn : number) {
  return (dispatch: Dispatch) => {
    // Dispatch action that fetching data has begun
    dispatch(action(PeeringDBActionTypes.FETCH_PEERINGDB_AS_REQUEST, undefined));

    // Call endpoint, dispatch success- or error-message, depending on server response
    // Submit the GET-request, expecting plain text as response type
    RestServiceUtils.jsonGET('/api/peeringDBdata/'+asn,
      result => dispatch(action(PeeringDBActionTypes.FETCH_PEERINGDB_AS_SUCCESS, result)),
      error => dispatch(action(PeeringDBActionTypes.FETCH_ERROR, error.message))
    );
  }
}

