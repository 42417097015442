import * as d3 from "d3";
import {setFilter} from "../../../../store/bgpData/actions";
import {SVGChart} from "@pages/BGPDashboard/Vis/SVGChart";
import {Dispatch} from "redux";
import {ColorState} from "@pages/BGPDashboard/models/ColorState";
import {Tooltip} from "@pages/BGPDashboard/Vis/Tooltip";


export class DonutChart extends SVGChart {
  private tip!: Tooltip;
  private total!: number;

  private data?: { label: string; value: number }[];
  private colorState?: ColorState;
  private dispatch?: Dispatch;
  private type?: string;
  private labelArray?: string[];
  private rootElem: any;

  constructor(node: HTMLElement) {
    super(node, 100, 100);

    this.total = 0;
    this.init();
  }

  init() {
    this.tip = new Tooltip()
      .content((args: any[]) => {
        let [d] = args;
        return "Type: " + d.data.label + '<br/><br/>' + "Count: " + d.data.value + '<br/><br/>' + "Total: " + Math.round(d.data.value / this.total * 100)+"%"
      });

    this.rootElem = d3.select(this.svgElement).append('g')
      .attr('transform', `translate(${this.width / 2.0}, ${this.height / 2.0})`);
  }

  cleanUp() {
    this.tip.destroy();
  }

  resizeChart(): void {
    this.rootElem
      .attr('transform', `translate(${this.width / 2.0}, ${this.height / 2.0})`);
    this.drawDonut();
  }

  updateData(data: {label: string, value: number}[], type: string, dispatch: Dispatch<any>, colorState?: ColorState) {
    this.data = data;
    this.colorState = colorState;
    this.dispatch = dispatch;
    this.type = type;

    this.labelArray = [];
    this.total = 0;
    this.data?.forEach(d => {
      this.labelArray?.push(d.label);
      this.total = this.total + d.value
    })

    this.drawDonut();
  }

  drawDonut() {
    if (!this.data || !this.colorState || !this.dispatch) {
      return;
    }
    let data = this.data;
    let colorState = this.colorState;
    let dispatch = this.dispatch;

    let pieGen = d3.pie()
      .value((d: any) => d.value)
      .padAngle(.03)

    const color = d3.scaleOrdinal()
      .domain(this.labelArray!)
      .range(this.type == "wa" ? [colorState.announce, "#b5b507", colorState.withdraw]
        : [colorState.igp, colorState.egp, colorState.unknown]);

    const arc = d3.arc()
      .outerRadius(Math.min(this.height, this.width) / 2.0)
      .innerRadius(30)

    let colorLabel: string = "";
    const index = d3.local();
    const thisType = this.type;
    const path = this.rootElem.selectAll("path")
      // @ts-ignore
      .data(pieGen(data))
      .join('path')
      .style('fill', (_: any, i: any): any => {
        return color(String(i))
      })
      .on("mouseover", (event:any, d: any) => this.tip.show(d))
      .on("mousemove", (event: any, d: any,) => {
        this.tip.setPosition(event.pageY, event.pageX)
      })
      .each(function(this: any, d: any, i: any) {
        index.set(this, i); // Store index in local variable for later use.
      })
      .on("click", function(this: any, event: any, d: any) {
        colorLabel = <string>color(String(index.get(this)))
        dispatch(setFilter({type: (thisType == "wa") ? "type" : "origin", value: d.data.label, filter: "add", color:colorLabel, name: d.data.label , info: null}))
      })
      .on("mouseout", () => this.tip.hide())
      // @ts-ignore
      .attr('d', arc)
      .attr("stroke-width", 1)
      .style("cursor", "pointer")


    path.transition()
      .duration(500)
      .attrTween('d', function (d: any) {
        let interpolate = d3.interpolate({startAngle: 0, endAngle: 0}, d);
        return function (t: any) {
          return arc(interpolate(t));
        };
      });

    const text = this.rootElem.selectAll('text')
      // @ts-ignore
      .data(pieGen(data))
      .join("text")
      .attr("transform", function (d: any) {
        return "translate(" + arc.centroid(d) + ")";
      })
      .attr("dy", ".4em")
      .attr("text-anchor", "middle")
      .text(function (d: any) {
        if(d.data.value > 0)
          return d.data.value;
        else return;
      })
      .style("fill", '#fff')
      .style( 'font-size', '.9em')

  }



}
