import {Dispatch} from "redux";
import {HarbourCenterActionTypes} from "./types";
import {action} from "@iva/store-utils";
import {RestServiceUtils} from "@iva/rest-service-utils";


export function fetchHarbourCenterData() {
  return (dispatch: Dispatch) => {
    dispatch(action(HarbourCenterActionTypes.FETCH_HARBOURCENTER_REQUEST, undefined));

    RestServiceUtils.jsonGET('/api/harbourCenterData',
      result => dispatch(action(HarbourCenterActionTypes.FETCH_HARBOURCENTER_SUCCESS, result)),
      error => dispatch(action(HarbourCenterActionTypes.FETCH_HARBOURCENTER_ERROR, error.message))
    );
  }
}

export function fetchHarbourCenterDataAS(asn : number, date : string) {
  return (dispatch: Dispatch) => {
    // Dispatch action that fetching data has begun
    dispatch(action(HarbourCenterActionTypes.FETCH_HARBOURCENTER_AS_REQUEST, undefined));

    // Call endpoint, dispatch success- or error-message, depending on server response
    // Submit the GET-request, expecting plain text as response type
    RestServiceUtils.jsonGET('/api/harbourCenterData/'+asn+"/"+date,
      result => {
        dispatch(action(HarbourCenterActionTypes.FETCH_HARBOURCENTER_AS_SUCCESS, result))
      },
      error => {
        dispatch(action(HarbourCenterActionTypes.FETCH_HARBOURCENTER_ERROR, error.message))
      }
    );
  }
}

export function fetchCableData() {
  return (dispatch: Dispatch) => {
    dispatch(action(HarbourCenterActionTypes.FETCH_CABLE_DATA_REQUEST, undefined));

    RestServiceUtils.jsonGET('/api/cableData',
      result => dispatch(action(HarbourCenterActionTypes.FETCH_CABLE_DATA_SUCCESS, result)),
      error => dispatch(action(HarbourCenterActionTypes.FETCH_HARBOURCENTER_ERROR, error.message))
    );
  }
}
