import React, {FC, forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {CollectorBarChart} from "../BGPDashboard/Vis/CollectorBarChart";
import {BGPDataCollection} from "@pages/BGPDashboard/models/BGPDataCollection";
import {filterData} from "../BGPDashboard/InteractionsComponent"
import {FilterStatus} from "@pages/BGPDashboard/models/FilterStatus";


const CollectorComponent = forwardRef((props, ref) => {

  const refContainer = useRef(null);
  const [chart, setChart] = useState(null);
  const bgpData = useSelector((state: ApplicationState) => state.bgpData.rawData);
  const colorState = useSelector((state: ApplicationState) => state.bgpData.colorState);
  const filterTimeFrom = useSelector((state: ApplicationState) => state.filter.filterTimeFrom);
  const filterTimeTo = useSelector((state: ApplicationState) => state.filter.filterTimeTo);
  const filterState = useSelector((state:ApplicationState) => state.bgpData.filter);
  const filterID = useSelector((state:ApplicationState) => state.bgpData.highlightData);
  const dispatch = useDispatch();
  const [withdrawal, announcement] = ["WITHDRAWAL", "ANNOUNCEMENT"];

  useImperativeHandle(ref, () => ({
    updateDimensions() {
      // @ts-ignore
      chart?.updateDimensions();
    }
  }));

  useEffect(() => {
    // runs on destruct/unmount
    return () => {
      // @ts-ignore
      if (chart?.cleanUp) chart?.cleanUp();
    }
  }, [chart])

  const updateData = (chart: CollectorBarChart | null, bgpData: BGPDataCollection | undefined, filterTimeFrom: number, filterTimeTo: number, filter: FilterStatus[] | undefined, highlights: number[] | undefined) => {
    if (bgpData?.collection && chart) {
      let chartData = filterData(bgpData, filterTimeFrom, filterTimeTo, filter);
      if (chartData) {
        let temp: string[] = [];
        let finalData: any = [];
        chartData.map(e => {
          let w = 0;
          let a = 0;
          if (temp.length == 0 || !temp.includes(e.bgpData.collector)) {
            if (e.bgpData.type == withdrawal) w = w + 1;
            else if (e.bgpData.type == announcement) a = a + 1;
            const chartElement = {
              ids: [e.id],
              collector: e.bgpData.collector,
              w: w,
              a: a,
              count: w + a,
              highlight: false
            }
            finalData.push(chartElement);
            temp.push(e.bgpData.collector);
          } else  {
            const collector = finalData.find((f: { collector: string; }) => f.collector === e.bgpData.collector);
            if (collector != null) {
              if (e.bgpData.type == withdrawal) {
                collector.w = collector.w + 1
                let idAttr: number[] = [];
                idAttr = idAttr.concat(collector.ids)
                idAttr.push(e.id)
                collector.ids = idAttr
              } else if (e.bgpData.type == announcement) {
                collector.a = collector.a + 1;
                let idAttr: number[] = [];
                idAttr = idAttr.concat(collector.ids)
                idAttr.push(e.id)
                collector.ids = idAttr
              }
              collector.count = collector.a + collector.w
            }
          }
        })

        finalData.map((d: any) => {
          d.ids.map((e: any) => {
            if (highlights)
              if (highlights.includes(e)) {
                d.highlight = true;
              }
          })
        })

        finalData.sort((d: { w: number, a: number }, e: { w: number, a: number }) => {
          if ((d.w + d.a) > (e.w + e.a)) {
            return -1;
          }
          if ((d.w + d.a) < (e.w + e.a)) {
            return 1;
          }
          return 0;
        })

        let updateData = finalData.slice(0, 15)
        if(highlights){
          let tmp:number[] = [-1];
          updateData.map((u:any) => {
            tmp = tmp.concat(u.ids)
          })
          highlights.map(h => {
            if(!tmp.includes(h)){
              updateData = updateData.concat(finalData.filter((f:any) => f.ids.includes(h)))
            }
          })
          updateData.sort((d: { w: number, a: number }, e: { w: number, a: number }) => {
            if ((d.w + d.a) > (e.w + e.a)) {
              return -1;
            }
            if ((d.w + d.a) < (e.w + e.a)) {
              return 1;
            }
            return 0;
          })
          if(updateData.length > 15){
            updateData = updateData.slice(0,16);
          }
        }



        chart.updateData(updateData, dispatch, colorState!);
      }
    }
  }


  useEffect(() => {
    const initializeChart = ({setChart, refContainer}: any) => {
      const chart = new CollectorBarChart(refContainer.current);
      setChart(chart);
      if (bgpData) {
        updateData(chart, bgpData, filterTimeFrom, filterTimeTo, filterState, filterID);
      }
    };
    if (!chart) initializeChart({setChart, refContainer, dispatch});
  }, [chart]);

  useEffect(() => {
    if (chart != null) {
      updateData(chart, bgpData, filterTimeFrom, filterTimeTo, filterState, filterID)
    }
  }, [bgpData, filterState, filterTimeFrom, filterTimeTo, filterID])


  return (
    <>
      <div ref={refContainer} style={{height: '100%'}}>
      </div>
    </>
  );
});

export default CollectorComponent;
