import * as d3 from "d3";

export class Tooltip {
  private selection!: d3.Selection<any, any, any, any>;
  private dataCallback?: (...args: any[]) => string | undefined;

  constructor() {
    this.selection = d3.select("body")
      .append("div")
      .attr("class", "chart-tooltip")
      .style("position", "absolute")
      .style("visibility", "hidden")
      .style("pointer-events", "none")
  }

  content(dataCallback: (...args: any[]) => string | undefined) {
    this.dataCallback = dataCallback;
    return this;
  }

  setPosition(top: number, left: number) {
    const div = this.selection.node();
    const scrollTop = document.documentElement.scrollTop;
    const scrollLeft = document.documentElement.scrollLeft;
    const midWidth = document.documentElement.clientWidth/2;
    const midHeight = document.documentElement.clientHeight/2;

    if (top - scrollTop > midHeight) {
      top -= div.clientHeight + 10;
    } else {
      top += 10;
    }
    if (left - scrollLeft > midWidth) {
      left -= div.clientWidth + 10;
    } else {
      left += 10;
    }

    this.selection
      .style("top", top+"px")
      .style("left",left+"px");
  }

  show(...args: any[]) {
    let content;
    if (this.dataCallback) {
      content = this.dataCallback(args);
    }
    if (!content) return;

    this.selection.style("visibility", "visible")
    this.selection.html(content)
  }

  hide() {
    this.selection.style("visibility", "hidden")
  }

  destroy() {
    this.selection.remove();
  }
}
